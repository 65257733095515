import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { notificationApi } from '../../services/notification.api';

interface INotificationTracker {
  unviewedCount: number;
  shouldUpdate: boolean;
}

const initialState: INotificationTracker = {
  unviewedCount: 0,
  shouldUpdate: false,
};

export const asyncActions = {
  GET_UNVIEWED_NOTIFICATIONS_COUNT: createAsyncThunk(
    'GET_UNVIEWED_NOTIFICATIONS_COUNT',
    async () => {
      const unviewedCount = await notificationApi.getUnviewedCount();

      return unviewedCount.data;
    },
  ),
};

export const NotificationTrackerSlice = createSlice({
  name: 'NotificationTrackerSlice',
  initialState,
  reducers: {
    RESET_UNVIEWED_NOTIFICATIONS_COUNT: (state) => {
      state.unviewedCount = initialState.unviewedCount;
    },
    SET_SHOULD_UPDATE: (state, action) => {
      state.shouldUpdate = action.payload;
    },
  },
  extraReducers: (builder) => [
    builder.addCase(
      asyncActions.GET_UNVIEWED_NOTIFICATIONS_COUNT.fulfilled,
      (state, action) => {
        const unviewedCount = action.payload;

        if (state.unviewedCount !== unviewedCount) {
          state.unviewedCount = unviewedCount;
        }
      },
    ),
  ],
});
