import React from 'react';
import { Row, Col, Table, Menu, Tag, Tooltip, Space } from 'antd';
import {
  TableRowSelection,
  TablePaginationConfig,
} from 'antd/lib/table/interface';
import { useSelector } from 'react-redux';
import uniqid from 'uniqid';

import './PagingTable.scss';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Padlock } from '../../assets/popover-icons/padlock.svg';
import { RootState } from '../../store/store';
import { showBlockOrUnblockUserConfirmationModal } from '../../helpers/showBlockOrUnblockUserConfirmationModal/showBlockOrUnblockUserConfirmationModal';
import { showEnableOrDisableNewsConfirmationModal } from '../../helpers/showEnableOrDisableNewsConfirmation/showEnableOrDisableNewsConfirmation';
import { POP_UP_LANGUAGE_MAP_INVERTED } from '../../services/administrative-panel-pop-up';
import {
  DislikeFilled,
  InfoCircleOutlined,
  LikeFilled,
} from '@ant-design/icons';

interface IPagingTable {
  dataSource: any[];
  tableLoading: boolean;
  columns: IColumnCursor[];
  options: ITupleOptions[];
  pageSize: number;
  pageIndex: number;
  numberOfItems: number;
  onChangePage: Function;
  onChangeSort: Function;
  rowClickAction: Function;
  emptyText: string;
  disableOptionsColumn?: boolean;
  disableSelectionColumn?: boolean;
  disableRowCursorPointer?: boolean;
  defaultSortBehaviour?: IDefaultSortBehaviour;
  getIdFromRow?: (rowData: any) => number;
  selectRowsToGivenTab: Function;
  selectIdFromRowsSelected: Function;
}

interface IColumnCursor {
  columnTitle: string;
  columnProperty: string | string[];
  className?: string;
  disableSort?: boolean;
  width?: number;
  onResizeAction?: any;
}

interface ITupleOptions {
  optionId?: string;
  optionName: string;
  optionIcon: JSX.Element;
  optionOnClick: Function;
  optionDisableVerification?: Function;
  optionDisabledMessage?: string;
}

export interface IDefaultSortBehaviour {
  columnProperty: string;
  disableDefaultSortBehaviour: Function;
  order: 'ascend' | 'descend' | null | undefined;
}

type DataType =
  | 'ARTIFACT'
  | 'CLASSIFICATION'
  | 'COMPANY'
  | 'CONFIRMATION'
  | 'DOCUMENT'
  | 'FILE'
  | 'USER'
  | 'RESET_PASSWORD';

export const PagingTable: React.FC<IPagingTable> = ({
  dataSource,
  tableLoading,
  columns,
  options,
  pageSize,
  pageIndex,
  numberOfItems,
  onChangePage,
  onChangeSort,
  rowClickAction,
  emptyText,
  disableOptionsColumn = false,
  disableSelectionColumn = false,
  disableRowCursorPointer = false,
  defaultSortBehaviour,
  getIdFromRow = (rowData: any) => rowData.id,
  selectRowsToGivenTab,
  selectIdFromRowsSelected,
}: IPagingTable) => {
  const applicationLanguage = useSelector(
    (state: RootState) => state.navBar.defaultLanguage,
  );

  const dateColumnsProperties = [
    'updatedAt',
    'createdAt',
    'modifiedAt',
    'latestEventDate',
  ];

  const languageValue = Array.from(POP_UP_LANGUAGE_MAP_INVERTED).find(
    ([value]) => applicationLanguage === value,
  );

  const { t } = useTranslation();

  const generateEnableOrDisableUserOption = (rowItem: any) => {
    if (rowItem.blocked) {
      return (
        <Tooltip
          placement="left"
          title={t(
            'administrative-panel-users-table-column-unblock-user-option',
          )}
        >
          <div>
            <Padlock />
          </div>
        </Tooltip>
      );
    }

    return (
      <Tooltip
        placement="left"
        title={t('administrative-panel-users-table-column-block-user-option')}
      >
        <div>
          <Padlock />
        </div>
      </Tooltip>
    );
  };

  const generateEnableOrDisableNewsOption = (rowItem: any) => {
    if (!rowItem.status) {
      return (
        <Tooltip
          placement="left"
          title={t('administrative-panel-news-table-column-enable-option')}
        >
          <div>
            <Padlock />
          </div>
        </Tooltip>
      );
    }

    return (
      <Tooltip
        placement="left"
        title={t('administrative-panel-news-table-column-disable-option')}
      >
        <div>
          <Padlock />
        </div>
      </Tooltip>
    );
  };

  const generateOptions = (
    menuOptions: ITupleOptions[],
    itemId: number,
    rowItem: any,
  ) => {
    const menuOptionItens = menuOptions
      .filter(
        (x) =>
          x.optionId !== 'unblock-block-user' &&
          x.optionId !== 'enable-disable-news',
      )
      .map((x) => {
        const itemWithIconAndName = (
          <Tooltip placement="left" title={x.optionName}>
            <div>{x.optionIcon}</div>
          </Tooltip>
        );

        const disableItem = x.optionDisableVerification
          ? x.optionDisableVerification(itemId, rowItem)
          : undefined;

        const disabledItemWithTooltip = (
          <Tooltip placement="left" title={x.optionDisabledMessage}>
            <div>{x.optionIcon}</div>
          </Tooltip>
        );

        return (
          <Menu.Item
            key={x.optionName.toLowerCase()}
            onClick={() => {
              x.optionOnClick(itemId, rowItem);
            }}
            disabled={disableItem}
          >
            {disableItem ? disabledItemWithTooltip : itemWithIconAndName}
          </Menu.Item>
        );
      });

    if (menuOptions.find((x) => x.optionId === 'unblock-block-user')) {
      menuOptionItens.push(
        <Menu.Item
          key={uniqid()}
          onClick={() => {
            showBlockOrUnblockUserConfirmationModal(itemId, rowItem);
          }}
        >
          {generateEnableOrDisableUserOption(rowItem)}
        </Menu.Item>,
      );
    }
    if (menuOptions.find((x) => x.optionId === 'enable-disable-news')) {
      menuOptionItens.push(
        <Menu.Item
          key={uniqid()}
          onClick={() => {
            showEnableOrDisableNewsConfirmationModal(itemId, rowItem);
          }}
        >
          {generateEnableOrDisableNewsOption(rowItem)}
        </Menu.Item>,
      );
    }

    return (
      <Menu className="paging-table-options-column">{menuOptionItens}</Menu>
    );
  };

  const formatDateColumn = (unformattedDate: string) => {
    const date = new Date(unformattedDate);

    const dateFormat = new Intl.DateTimeFormat(applicationLanguage, {
      dateStyle: 'long',
      timeStyle: 'long',
    } as Intl.DateTimeFormatOptions);

    return (
      <span title={`${dateFormat.format(date)}`}>
        {' '}
        {dateFormat.format(date)}{' '}
      </span>
    );
  };

  const checkIfColumnHasDefaultSortBehaviour = (columnProperty: string) => {
    if (
      defaultSortBehaviour &&
      defaultSortBehaviour.columnProperty === columnProperty
    ) {
      return defaultSortBehaviour.order;
    }

    return undefined;
  };

  const generateColumns = () => {
    let tableColumns: JSX.Element[] = [];

    tableColumns = columns.map((x) => {
      if (dateColumnsProperties.includes(x.columnProperty as string)) {
        const isPageTable = window.location.href.includes('edit-pages');
        return (
          <Table.Column
            title={x.columnTitle}
            key={x.columnProperty.toString()}
            sorter
            onCell={(record: any) => {
              return {
                onClick: () => rowClickAction(record),
              };
            }}
            className={x.className}
            ellipsis
            defaultSortOrder={checkIfColumnHasDefaultSortBehaviour(
              x.columnProperty as string,
            )}
            showSorterTooltip={false}
            render={
              isPageTable
                ? (y: any) => formatDateColumn(y.page.modifiedAt as string)
                : (y: any) => formatDateColumn(y[x.columnProperty as string])
            }
            width="4.5rem"
          />
        );
      }

      if (x.columnProperty === 'titleLanguage') {
        return (
          <Table.Column
            title={x.columnTitle}
            render={(y: any) => {
              return y.contents.find(
                (e: any) =>
                  e.language ===
                  (languageValue ? languageValue[1] : 'PORTUGUESE'),
              ).title;
            }}
            key={x.columnTitle}
            onCell={(record: any) => {
              return {
                onClick: () => rowClickAction(record),
              };
            }}
            className={x.className}
            sorter={x.disableSort ? !x.disableSort : true}
            ellipsis
            showSorterTooltip={false}
            width={x.width ? `${x.width}rem` : '4.2rem'}
          />
        );
      }

      if (x.columnProperty === 'subtitleLanguage') {
        return (
          <Table.Column
            title={x.columnTitle}
            render={(y: any) =>
              y.contents.find(
                (e: any) =>
                  e.language ===
                  (languageValue ? languageValue[1] : 'PORTUGUESE'),
              ).subtitle
            }
            key={x.columnTitle}
            onCell={(record: any) => {
              return {
                onClick: () => rowClickAction(record),
              };
            }}
            className={x.className}
            sorter={x.disableSort ? !x.disableSort : true}
            ellipsis
            showSorterTooltip={false}
            width={x.width ? `${x.width}rem` : '4.2rem'}
          />
        );
      }

      if (
        x.columnProperty === 'dataType' &&
        window.location.href.includes('administrative-panel?tab=logs')
      ) {
        const dataTypeTranslations: Record<DataType, string> = {
          ARTIFACT: t(
            'administrative-panel-logs-listing-event-data-type-artifact',
          ),
          CLASSIFICATION: t(
            'administrative-panel-logs-listing-event-data-type-documentation-tag',
          ),
          COMPANY: t(
            'administrative-panel-logs-listing-event-data-type-company',
          ),
          CONFIRMATION: t(
            'administrative-panel-logs-listing-event-data-type-confirmation',
          ),
          DOCUMENT: t(
            'administrative-panel-logs-listing-event-data-type-document',
          ),
          FILE: t('administrative-panel-logs-listing-event-data-type-file'),
          USER: t('administrative-panel-logs-listing-event-data-type-user'),
          RESET_PASSWORD: t(
            'administrative-panel-logs-listing-event-data-type-reset-password',
          ),
        };
        return (
          <>
            <Table.Column
              title={x.columnTitle}
              dataIndex={x.columnProperty}
              key={x.columnTitle}
              className={x.className}
              sorter={x.disableSort ? !x.disableSort : true}
              ellipsis
              showSorterTooltip={true}
              width={x.width ? `${x.width}rem` : '4.2rem'}
              render={(record: DataType) => {
                return <>{dataTypeTranslations[record]}</>;
              }}
            />
            <Table.Column
              className="resizeHandle"
              onHeaderCell={() => {
                return {
                  onMouseDown: () => {
                    window.addEventListener('mousemove', x.onResizeAction);
                    window.addEventListener('mouseup', () => {
                      window.removeEventListener('mousemove', x.onResizeAction);
                    });
                  },
                };
              }}
            />
          </>
        );
      }

      if (
        x.columnProperty === 'detail' &&
        window.location.href.includes('administrative-panel?tab=logs')
      ) {
        return (
          <>
            <Table.Column
              title={x.columnTitle}
              dataIndex={x.columnProperty}
              key={x.columnTitle}
              className={x.className}
              sorter={x.disableSort ? !x.disableSort : true}
              ellipsis
              showSorterTooltip={true}
              width={x.width ? `${x.width}rem` : '4.2rem'}
              render={(record: any) => {
                const icons: { [key: string]: JSX.Element } = {
                  liked: <LikeFilled style={{ color: '#41b4d2' }} />,
                  disliked: <DislikeFilled style={{ color: '#D46b08' }} />,
                };
                // Mapping specific texts to their translations
                const textReplacements: { [key: string]: string } = {
                  File: t(
                    'administrative-panel-logs-listing-event-data-type-file',
                  ),
                  'has been marked in artifact': t(
                    'administrative-panel-logs-listing-event-detail-set-default-file',
                  ),
                  'has been unmarked in artifact': t(
                    'administrative-panel-logs-listing-event-detail-unset-default-file',
                  ),
                  'renamed to': t(
                    'administrative-panel-logs-listing-event-detail-rename-folder',
                  ),
                  'updated description': t(
                    'administrative-panel-logs-listing-event-detail-update-folder-description',
                  ),
                  'updated to': t(
                    'administrative-panel-logs-listing-event-detail-update-tag',
                  ),
                  'disassociated with the folder': t(
                    'administrative-panel-logs-listing-event-detail-associated-documentation',
                  ),
                  'associated with the folder': t(
                    'administrative-panel-logs-listing-event-detail-disassociated-documentation',
                  ),
                };
                const descriptionWithIcons =
                  record &&
                  record
                    .split(/(liked|disliked)/)
                    .map((part: string, index: number) => {
                      if (icons[part]) {
                        /* Record related to the evaluation operation in the backend includes the
                        words liked and disliked to be replaced by icons. */
                        return (
                          <React.Fragment key={index}>
                            {icons[part]}
                          </React.Fragment>
                        );
                      } else {
                        /* The record in the backend includes the characters /=/=/=/ to separate the 
                        previous path and the new path when moving and copying items, as well as 
                        the old name and the new name when renaming folders, and showing the path
                        information.*/
                        let replacedText = part.replace('/=/=/=/', ' → ');
                        Object.keys(textReplacements).forEach((key) => {
                          replacedText = replacedText.replace(
                            key,
                            textReplacements[key],
                          );
                        });
                        return (
                          <React.Fragment key={index}>
                            {replacedText}
                          </React.Fragment>
                        );
                      }
                    });
                return (
                  <Tooltip placement="bottomLeft" title={descriptionWithIcons}>
                    <div className="logs-listing-detail-item">
                      {descriptionWithIcons}
                    </div>
                  </Tooltip>
                );
              }}
            />
            <Table.Column
              className="resizeHandle"
              onHeaderCell={() => {
                return {
                  onMouseDown: () => {
                    window.addEventListener('mousemove', x.onResizeAction);
                    window.addEventListener('mouseup', () => {
                      window.removeEventListener('mousemove', x.onResizeAction);
                    });
                  },
                };
              }}
            />
          </>
        );
      }

      if (x.columnProperty === 'tags') {
        return (
          <>
            <Table.Column
              title={
                <Space>
                  <b>{t('page-tags-title')}</b>
                  <Tooltip
                    placement="bottom"
                    title={
                      <Space direction="vertical" size="small">
                        {t('page-tags-info-title')}
                        <Space align="center">
                          <span
                            className="tagColorDot"
                            style={{ backgroundColor: '#ffd591' }}
                          ></span>
                          {`${t('page-tags-info-level')} 1`}
                        </Space>
                        <Space align="center">
                          <span
                            className="tagColorDot"
                            style={{ backgroundColor: '#87e8de' }}
                          ></span>
                          {`${t('page-tags-info-level')} 2`}
                        </Space>
                        <Space align="center">
                          <span
                            className="tagColorDot"
                            style={{ backgroundColor: '#adc6ff' }}
                          ></span>
                          {`${t('page-tags-info-level')} 3`}
                        </Space>
                      </Space>
                    }
                  >
                    <InfoCircleOutlined />
                  </Tooltip>
                </Space>
              }
              render={(y: any) => {
                if (!y.page?.classifications?.length) {
                  return t('pages-created-no-tags-associated');
                }

                const sortedPageTags = [...y.page.classifications].sort(
                  (a: any, b: any) => a.level - b.level,
                );

                const tagNames = sortedPageTags.map((tag) => tag.name);

                return (
                  <Tooltip placement="bottomLeft" title={tagNames.join(', ')}>
                    {sortedPageTags.map((tag) => {
                      return (
                        <Tag
                          key={`tag-${y.title}-${tag.level}-${tag.name}`}
                          color={
                            tag.level === 1
                              ? 'orange'
                              : tag.level === 2
                                ? 'cyan'
                                : 'geekblue'
                          }
                        >
                          {tag.name}
                        </Tag>
                      );
                    })}
                  </Tooltip>
                );
              }}
              key={x.columnTitle}
              onCell={(record: any) => {
                return {
                  onClick: () => rowClickAction(record),
                };
              }}
              className={x.className}
              sorter={x.disableSort ? !x.disableSort : true}
              ellipsis
              showSorterTooltip={false}
              width={x.width ? `${x.width}rem` : '4.2rem'}
            />
            <Table.Column
              className="resizeHandle"
              onHeaderCell={() => {
                return {
                  onMouseDown: () => {
                    window.addEventListener('mousemove', x.onResizeAction);
                    window.addEventListener('mouseup', () => {
                      window.removeEventListener('mousemove', x.onResizeAction);
                    });
                  },
                };
              }}
            />
          </>
        );
      }

      return (
        <>
          <Table.Column
            title={x.columnTitle}
            dataIndex={x.columnProperty}
            key={x.columnTitle}
            onCell={(record: any) => {
              return {
                onClick: () => rowClickAction(record),
              };
            }}
            className={x.className}
            sorter={x.disableSort ? !x.disableSort : true}
            ellipsis
            showSorterTooltip={false}
            width={x.width ? `${x.width}rem` : '4.2rem'}
          />
          <Table.Column
            className="resizeHandle"
            onHeaderCell={() => {
              return {
                onMouseDown: () => {
                  window.addEventListener('mousemove', x.onResizeAction);
                  window.addEventListener('mouseup', () => {
                    window.removeEventListener('mousemove', x.onResizeAction);
                  });
                },
              };
            }}
          />
        </>
      );
    });

    if (window.location.href.includes('administrative-panel?tab=users')) {
      tableColumns.push(
        <Table.Column
          title="Status"
          key="status"
          align="left"
          showSorterTooltip={false}
          render={(record) => {
            return (
              <Tag color={record.blocked ? 'red' : 'green'} key="statusTag">
                {record.blocked
                  ? t('administrative-panel-user-status-column-blocked')
                  : t('administrative-panel-user-status-column-unblocked')}
              </Tag>
            );
          }}
          width="3.125rem"
        />,
      );
    }

    if (window.location.href.includes('administrative-panel?tab=news')) {
      tableColumns.push(
        <Table.Column
          title="Status"
          key="active"
          align="left"
          sorter
          showSorterTooltip={false}
          defaultSortOrder="ascend"
          render={(record) => {
            return (
              <Tag color={record.active ? 'green' : 'red'} key="statusTag">
                {record.active
                  ? t('news-listing-table-status-active')
                  : t('news-listing-table-status-inactive')}
              </Tag>
            );
          }}
          width="3rem"
        />,
      );
    }

    if (window.location.href.includes('administrative-panel?tab=terms')) {
      tableColumns.push(
        <Table.Column
          title="Status"
          key="active"
          align="left"
          sorter
          showSorterTooltip={false}
          defaultSortOrder="descend"
          render={(record) => {
            return (
              <Tag color={record.active ? 'green' : 'red'} key="statusTag">
                {record.active
                  ? t('terms-listing-active-status')
                  : t('terms-listing-inactive-status')}
              </Tag>
            );
          }}
          width="3.125rem"
        />,
      );
    }

    if (window.location.href.includes('administrative-panel?tab=pop-up')) {
      tableColumns.push(
        <Table.Column
          title="Status"
          key="active"
          align="left"
          sorter
          showSorterTooltip={false}
          defaultSortOrder="descend"
          render={(record) => {
            return (
              <Tag color={record.active ? 'green' : 'red'} key="statusTag">
                {record.active ? t('active-status') : t('inactive-status')}
              </Tag>
            );
          }}
          width="3.125rem"
        />,
      );
    }

    if (!disableOptionsColumn) {
      tableColumns.push(
        <Table.Column
          title=""
          key="options"
          className="optionsColumn"
          ellipsis
          align="center"
          render={(x: any) => generateOptions(options, getIdFromRow(x), x)}
          width={`${1.1 * options.length}rem`}
        />,
      );
    }

    return tableColumns;
  };

  const rowSelectionProperties: TableRowSelection<any> = {
    preserveSelectedRowKeys: true,
    type: 'checkbox',
    onChange: (_, selectedRows) => {
      selectRowsToGivenTab(selectedRows);

      selectIdFromRowsSelected(selectedRows.map((y) => getIdFromRow(y)));
    },
    columnWidth: '1.25rem',
  };

  const paginationConfig: TablePaginationConfig = {
    total: numberOfItems,
    showTotal: (total, range) =>
      `${range[0]}-${range[1]} ${t('paging-table-pagination-of')} ${total}`,
    current: pageIndex,
    defaultPageSize: pageSize,
    pageSizeOptions: [],
    position: ['bottomCenter'],
  };

  const sortHandler = (
    field: string | string[],
    order: string | undefined | null,
  ) => {
    onChangeSort(field, order);
  };

  return (
    <Row className="PagingTable">
      <Col span={24}>
        <Table
          onChange={(pagination, filters, sorter: any) => {
            const sorterField = sorter.field ? sorter.field : sorter.columnKey;

            sortHandler(sorterField, sorter.order);
            onChangePage(pagination.current);
          }}
          locale={{ emptyText }}
          loading={tableLoading}
          rowSelection={
            disableSelectionColumn ? undefined : rowSelectionProperties
          }
          dataSource={dataSource}
          pagination={paginationConfig}
          rowKey={(record) => getIdFromRow(record)}
          className={
            disableRowCursorPointer
              ? 'ant-table-row-disabled-cursor'
              : undefined
          }
        >
          {generateColumns()}
        </Table>
      </Col>
    </Row>
  );
};
