import React, { useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';

// This component is used to redirect URLs from the V2 repository
// to the new V3 repository, keeping backward compatibility.
export const RedirectRepoV2ToV3: React.FC = () => {
  const navigate = useNavigate();
  const { releaseIdV2 } = useParams();
  const location = useLocation();

  useEffect(() => {
    if (releaseIdV2) {
      const searchParams = new URLSearchParams(location.search);
      const downloadId = searchParams.get('download');
      if (downloadId) {
        navigate(`/repositories/${releaseIdV2}/download/${downloadId}`);
      } else {
        navigate(`/repositories/${releaseIdV2}`);
      }
    }
  }, [releaseIdV2, navigate, location]);

  return null;
};
