import { IMeResponse } from '../interfaces/services-interfaces/IMeResponse';
import { securedBaseApi } from './api.config';
import { IPageBackendDTO } from './createReadEditPage.api';

export interface IUserEvaluations {
  email: string;
  firstName: string;
  lastName: string;
}

export interface IFolderEvaluations {
  folderId: number;
  userId: number;
  user: IUserEvaluations;
  liked: boolean;
  folderDescription: boolean;
  folderOrganization: boolean;
  locationOfDocuments: boolean;
  description: string;
  updatedAt: string;
}

export interface IFileEvaluations {
  fileId: number;
  userId: number;
  user: IUserEvaluations;
  liked: boolean;
  locate: boolean;
  availabilityOfInformation: boolean;
  informationAccuracy: boolean;
  clarity: boolean;
  contentDepth: boolean;
  preferredLanguage: true;
  description: string;
  updatedAt: string;
}

export interface IPageEvaluations {
  pageId: number;
  userId: number;
  user: IMeResponse;
  liked: boolean;
  availabilityOfInformation: boolean;
  clarity: boolean;
  depthOfInformation: boolean;
  informationAccuracy: boolean;
  locate: boolean;
  pageDescription: boolean;
  preferredLanguage: boolean;
  description: string;
  updatedAt: string;
}

export interface IEvaluationsList {
  content: IPageEvaluations[] | IFolderEvaluations[] | IFileEvaluations[];
  empty: boolean;
  first: boolean;
  last: boolean;
  number: boolean;
  numberOfElements: number;
  totalElements: number;
  pageable: {
    offset: number;
    pageNumber: number;
    pageSize: number;
    paged: boolean;
    sort: { empty: boolean; sorted: boolean; unsorted: boolean };
    unpaged: boolean;
  };
  size: number;
  sort: {
    empty: boolean;
    sorted: boolean;
    unsorted: boolean;
  };
  totalPages: number;
  sortBy: string;
}

export const evaluationApi = {
  getFolderEvaluations: (artifactId: number, sortBy: string) => {
    return securedBaseApi.get<IEvaluationsList>(
      `/artifact-review/${artifactId}?&sort=${sortBy}`,
    );
  },

  getFileEvaluations: (fileId: number, sortBy: string) => {
    return securedBaseApi.get<IEvaluationsList>(
      `/file-review/${fileId}?&sort=${sortBy}`,
    );
  },

  getPageEvaluations: (pageId: number, sortBy: string) => {
    return securedBaseApi.get<IEvaluationsList>(
      `/page-review/${pageId}?&sort=${sortBy}`,
    );
  },

  getFolderEvaluationsByUser: (artifactId: number, userId: number) => {
    return securedBaseApi.get<IEvaluationsList>(
      `/artifact-review/${artifactId}/${userId}`,
    );
  },

  getFileEvaluationsByUser: (fileId: number, userId: number) => {
    return securedBaseApi.get<IEvaluationsList>(
      `/file-review/${fileId}/${userId}`,
    );
  },

  getPageEvaluationsByUser: (pageId: number, userId: number) => {
    return securedBaseApi.get<IEvaluationsList>(
      `/page-review/${pageId}/${userId}`,
    );
  },

  getPageBySlugAuthenticated: (slug: string) => {
    return securedBaseApi.get<IPageBackendDTO>(`/pages/slug/${slug}`);
  },
};
