import React from 'react';
import { Col, Form, Input, Row, Skeleton, Tag } from 'antd';
import uniqid from 'uniqid';
import { useTranslation } from 'react-i18next';
import { ReactComponent as RedBar } from '../../../../assets/red_bar_icon.svg';
import { PageLanguage, PageMode } from '../CreateReadEditPage.Slice';

interface IPageForm {
  pageMode: PageMode | undefined;
  loading: boolean;
  currentLanguage: PageLanguage | undefined;
}

export const PageForm: React.FC<IPageForm> = ({
  pageMode,
  loading,
  currentLanguage,
}: IPageForm) => {
  const { t } = useTranslation();

  const createEditOrReadModeTitle = () => {
    if (pageMode === PageMode.Read) {
      return 'title-input-read-only';
    }
    return 'title-input';
  };

  const createEditOrReadModeDescription = () => {
    if (pageMode === PageMode.Read) {
      return 'description-input-read-only';
    }
    return 'description-input';
  };

  const showTitleOrDescriptionBorder = () => {
    if (pageMode === PageMode.Read) {
      return false;
    }
    return true;
  };

  const getFormTitleInputOrLoading = () => {
    if (loading) {
      return <Skeleton active />;
    }

    return (
      <Input
        id="page-form-title"
        key={`${uniqid()}${pageMode}`}
        className={createEditOrReadModeTitle()}
        bordered={showTitleOrDescriptionBorder()}
        placeholder={t('page-title-input-placeholder')}
        maxLength={50}
        readOnly={pageMode === PageMode.Read}
      />
    );
  };

  const getFormDescriptionInputOrLoading = () => {
    if (loading) {
      return <Skeleton active />;
    }

    return (
      <Input
        id="page-form-description"
        key={`${uniqid()}${pageMode}`}
        className={createEditOrReadModeDescription()}
        bordered={showTitleOrDescriptionBorder()}
        placeholder={t('page-description-input-placeholder')}
        readOnly={pageMode === PageMode.Read}
        maxLength={150}
      />
    );
  };

  return (
    <>
      <Row>
        <Col>
          <RedBar />
        </Col>
      </Row>
      {currentLanguage && pageMode !== PageMode.Read && (
        <Row style={{ marginBottom: '5px' }}>
          <Col>
            <Tag color="geekblue" style={{ fontSize: '13px' }}>
              <b>{t('create-page-current-editing-language')}</b>{' '}
              {t(`create-page-language-tag-${currentLanguage.toLowerCase()}`)}
            </Tag>
          </Col>
        </Row>
      )}
      <Row>
        <Col span={24}>
          <Form.Item name="title">{getFormTitleInputOrLoading()}</Form.Item>
          <Form.Item name="description">
            {getFormDescriptionInputOrLoading()}
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};
