import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
  createReadEditPageApi,
  IPageBackendDTO,
  IListPagesBackendDTO,
  IListPagesBackendResponse,
  IPageResponseContents,
} from '../../../services/createReadEditPage.api';
import {
  IClassification,
  classificationApi,
} from '../../../services/classification.api';
import { messageNotification } from '../../../helpers/messageNotification/messageNotification';
import { unhookedTranslation } from '../../../helpers/unhookedTranslation/unhookedTranslation';
import { IPageEvaluations } from '../../../services/evaluations.api';

const mapTreeData = (
  classificationNodes: IClassification[],
): ITagTreeNode[] => {
  return classificationNodes?.map((node) => {
    return {
      title: node.name ? node.name : '',
      value: node.id.toString(),
      children: node.subClassifications
        ? mapTreeData(node.subClassifications)
        : undefined,
    };
  });
};

export enum PageMode {
  Create = 'create',
  Edit = 'edit',
  Read = 'read',
}

// All available page languages.
// If more are added to the backend, they should be added here too.
export enum PageLanguage {
  English = 'ENGLISH',
  Portuguese = 'PORTUGUESE',
  Spanish = 'SPANISH',
}

interface IEditorOutput {
  language: PageLanguage;
  contents: string | undefined;
}

interface ILanguages {
  openAddLanguageModal: boolean;
  selectedLanguageToAdd: PageLanguage | undefined;
  pendingDefaultPageLanguageSelection: boolean;
  openSelectDefaultPageLanguageModal: boolean;
  defaultPageLanguage: PageLanguage | undefined;
}

export interface ICreateReadEditPageSlice {
  pageMode: PageMode | undefined;
  pageId: number | undefined;
  languages: ILanguages;
  pageSlug: string;
  pages: IPages;
  pageLoaded: boolean;
  loadedToUpdate: boolean;
  idFromLoadedPageToUpdate: number | undefined;
  pageObjectResponse: IPageBackendDTO | undefined;
  pageResponseLoading: boolean;
  pageRejected: boolean;
  pageListResponse: IListPagesBackendDTO | undefined;
  resultListResponseFilter: any;
  searchPageTitle: string;
  showAllPages: boolean;
  loadingSearchedPage: boolean;
  pageObjectResponseSearch: IListPagesBackendResponse | undefined;
  editorOutputOnEditing: string | undefined;
  editorOutput: IEditorOutput[];
  loadedPages: boolean;
  tags: {
    classifications: IClassification[];
    loadedPageTags: boolean;
    treeData: ITagTreeNode[] | undefined;
    isLoadingTreeData: boolean;
    tags: ITag[];
    shouldUpdateTags: boolean;
    selectedTreeNodes: string[];
    openTagModal: boolean;
  };
  pageToEvaluate: IPageResponseContents | undefined;
  openEvaluationModal: boolean;
  evaluationToEdit: IPageEvaluations[] | undefined;
  openEditEvaluationModal: boolean;
  isEvaluatingPage: boolean;
  isDeletingEvaluation: boolean;
  pageEvaluationsList?: IPageEvaluations[] | undefined;
  shouldUpdate: boolean;
}

export interface ITagTreeNode {
  title: string;
  value: string;
  children?: ITagTreeNode[];
}

export interface ITagValueMap {
  parent: ITagValueMap | null;
  title: string;
  level: number;
  value: string;
  children: ITagValueMap[];
}

export interface ITag {
  title: string;
  value: string;
  level: number;
}

interface IPageAndLanguage {
  language: PageLanguage;
  title: string;
  description: string;
  contents: string | undefined;
  isValid: boolean;
}

interface IPages {
  currentLanguage: PageLanguage | undefined;
  finishedCreatingPage: boolean;
  idFromRecentlyCreatedPage: number | undefined;
  startPageValidation: boolean;
  pageTitleInitialValue: string;
  pageDescriptionInitialValue: string;
  pageEditorInitialValue: string;
  pages: IPageAndLanguage[];
}

const initialState: ICreateReadEditPageSlice = {
  pageMode: undefined,
  pageId: undefined,
  languages: {
    selectedLanguageToAdd: undefined,
    openAddLanguageModal: false,
    pendingDefaultPageLanguageSelection: false,
    openSelectDefaultPageLanguageModal: false,
    defaultPageLanguage: undefined,
  },
  pageSlug: '',
  pages: {
    currentLanguage: undefined,
    finishedCreatingPage: false,
    idFromRecentlyCreatedPage: undefined,
    startPageValidation: false,
    pageTitleInitialValue: '',
    pageDescriptionInitialValue: '',
    pageEditorInitialValue: '',
    pages: [],
  },
  pageLoaded: false,
  loadedToUpdate: false,
  idFromLoadedPageToUpdate: undefined,
  pageObjectResponse: undefined,
  pageResponseLoading: false,
  pageRejected: false,
  pageListResponse: undefined,
  resultListResponseFilter: undefined,
  pageObjectResponseSearch: undefined,
  loadingSearchedPage: false,
  searchPageTitle: '',
  showAllPages: true,
  editorOutputOnEditing: undefined,
  editorOutput: [],
  loadedPages: false,
  tags: {
    classifications: [],
    loadedPageTags: false,
    isLoadingTreeData: false,
    treeData: undefined,
    tags: [],
    shouldUpdateTags: false,
    selectedTreeNodes: [],
    openTagModal: false,
  },
  pageToEvaluate: undefined,
  openEvaluationModal: false,
  evaluationToEdit: undefined,
  openEditEvaluationModal: false,
  isEvaluatingPage: false,
  isDeletingEvaluation: false,
  pageEvaluationsList: undefined,
  shouldUpdate: false,
};

export const asyncPageActions = {
  GET_PAGE_BY_SLUG: createAsyncThunk(
    'GET_PAGE_BY_SLUG',
    async (payload: any) => {
      const pageResponse = await createReadEditPageApi.getPageBySlug(
        payload.slug,
      );

      return {
        pageObjectResponse: pageResponse.data,
      };
    },
  ),
  GET_PAGE_BY_SLUG_AUTHENTICATED: createAsyncThunk(
    'GET_PAGE_BY_SLUG_AUTHENTICATED',
    async (payload: any) => {
      const pageResponse =
        await createReadEditPageApi.getPageBySlugAuthenticated(payload.slug);

      return {
        pageObjectResponse: pageResponse.data,
        pageEvaluationsList: pageResponse.data.listPageReviews,
      };
    },
  ),
  GET_ALL_PAGES: createAsyncThunk('GET_ALL_PAGES', async (language: string) => {
    const pageListResponse = await createReadEditPageApi.getAllPages(language);

    return {
      pageListResponse: pageListResponse.data,
    };
  }),
  FIND_PAGE_BY_TITLE: createAsyncThunk(
    'FIND_PAGE_BY_TITLE',
    async (arg: any) => {
      const pageResponse = await createReadEditPageApi.findPageByTitle(
        arg.language,
        arg.title,
      );

      return {
        title: arg.title,
        pageObjectResponse: pageResponse.data,
      };
    },
  ),
  CREATE_A_NEW_PAGE: createAsyncThunk(
    'CREATE_A_NEW_PAGE',
    async (payload: any) => {
      const newPageResponse =
        await createReadEditPageApi.createNewPage(payload);

      return {
        createdPage: newPageResponse.data,
      };
    },
  ),
  GET_PAGE_TO_UPDATE: createAsyncThunk(
    'GET_PAGE_TO_UPDATE',
    async (payload: any) => {
      const updatedPageResponse =
        await createReadEditPageApi.getPageToUpdate(payload);

      return {
        updatedDocumentation: updatedPageResponse.data,
      };
    },
  ),
  KEEP_ALIVE: createAsyncThunk('KEEP_ALIVE', async (arg: any) => {
    await createReadEditPageApi.keepAlive(arg.id, arg.isUpdating);
  }),
  UPDATE_A_PAGE: createAsyncThunk('UPDATE_A_PAGE', async (arg: any) => {
    await createReadEditPageApi.updatePage(arg.id, arg.payload);
  }),
  GET_ALL_CLASSIFICATIONS: createAsyncThunk(
    'GET_ALL_CLASSIFICATIONS',
    async () => {
      const getAllClassificationsResponse =
        await classificationApi.getAllClassifications();

      return {
        classifications: getAllClassificationsResponse.data,
      };
    },
  ),
  GET_PAGE_BY_CLASSIFICATIONS_ID: createAsyncThunk(
    'GET_PAGE_BY_CLASSIFICATIONS_ID',
    async (payload: any) => {
      const pageResponse =
        await createReadEditPageApi.getPageByClassificationsId(payload);
      return {
        pageListResponse: pageResponse.data,
        resultListResponseFilter: pageResponse.data,
      };
    },
  ),
  NEW_PAGE_EVALUATION: createAsyncThunk(
    'NEW_PAGE_EVALUATION',
    async (payload: any) => {
      const newPageEvaluationResponse =
        await createReadEditPageApi.newPageEvaluation(payload);

      return {
        pageEvaluationResponse: newPageEvaluationResponse.data,
      };
    },
  ),
  UPDATE_PAGE_EVALUATION: createAsyncThunk(
    'UPDATE_PAGE_EVALUATION',
    async (payload: any) => {
      const updatePageEvaluationResponse =
        await createReadEditPageApi.updatePageEvaluation(
          payload.evaluationValues,
          payload.userId,
          payload.pageId,
        );

      return {
        pageEvaluationResponse: updatePageEvaluationResponse.data,
      };
    },
  ),
  DELETE_PAGE_EVALUATION: createAsyncThunk(
    'DELETE_PAGE_EVALUATION',
    async (payload: any) => {
      const deletePageEvaluationResponse =
        await createReadEditPageApi.deletePageEvaluation(
          payload.userId,
          payload.pageId,
        );

      return {
        pageEvaluationResponse: deletePageEvaluationResponse.data,
      };
    },
  ),
};

export const CreateReadEditPageSlice = createSlice({
  name: 'CreateReadEditPageSlice',
  initialState,
  reducers: {
    PAGE_SET_PAGE_MODE: (state, action) => {
      state.pageMode = action.payload;
    },
    PAGE_SET_SLUG: (state, action) => {
      state.pageSlug = action.payload;
    },
    BACK_TO_ORIGINAL_STATE: () => {
      return initialState;
    },
    BACK_TO_ORIGINAL_STATE_PERSISTING_ID_FROM_UPDATED_PAGE: (state) => {
      return {
        ...initialState,
        idFromLoadedPageToUpdate: state.idFromLoadedPageToUpdate,
      };
    },
    SET_CURRENT_LANGUAGE: (state, action) => {
      state.pages.currentLanguage = action.payload;

      assignLanguageFromPropertiesToInitialValueProperties(
        state,
        action.payload,
      );
    },
    SAVE_PAGE_CONTENT: (state, action) => {
      state.pageSlug = action.payload.pageSlug;

      if (state.editorOutputOnEditing !== undefined) {
        state.editorOutput = state.editorOutput.map((editorOutput) => {
          return editorOutput.language === state.pages.currentLanguage
            ? { ...editorOutput, contents: state.editorOutputOnEditing }
            : editorOutput;
        });
      }

      state.pages.pages = state.pages.pages.map((page) => {
        if (page.language !== state.pages.currentLanguage) {
          return page;
        }

        const newPageContent = { ...page };
        const editorContent = state.editorOutput.find(
          (elem) => elem.language === state.pages.currentLanguage,
        );
        if (editorContent) {
          newPageContent.contents = state.editorOutputOnEditing;
        }

        if (action.payload.pageTitle) {
          newPageContent.title = action.payload.pageTitle;
        }

        if (action.payload.pageDescription) {
          newPageContent.description = action.payload.pageDescription;
        }

        return newPageContent;
      });

      checkIfDocumentationIsValid(state);
      assignLanguageFromPropertiesToInitialValueProperties(
        state,
        action.payload.language,
      );
    },
    SET_NEW_LANGUAGE: (state, action) => {
      state.pages.currentLanguage = action.payload;
      assignLanguageFromPropertiesToInitialValueProperties(
        state,
        action.payload,
      );
    },
    UPDATE_PAGE_STATE_AND_START_VALIDATION: (state, action) => {
      state.pageResponseLoading = true;
      state.pageSlug = action.payload.pageSlug;

      let editorOutputOnEditing = '';

      if (state.editorOutputOnEditing !== undefined) {
        // Check if we are not receiving an empty line from the editor
        const regex = /<p>\s*<\/p>/;
        if (!regex.test(editorOutputOnEditing)) {
          editorOutputOnEditing = state.editorOutputOnEditing;
        }

        state.editorOutput = state.editorOutput.map((editorOutput) => {
          return editorOutput.language === state.pages.currentLanguage
            ? { ...editorOutput, contents: editorOutputOnEditing }
            : editorOutput;
        });
      }

      state.pages.pages = state.pages.pages.map((page) => {
        if (page.language !== state.pages.currentLanguage) {
          return page;
        }

        const newPageContents = { ...page };

        const editorContent = state.editorOutput.find(
          (element) => element.language === state.pages.currentLanguage,
        );
        if (editorContent) {
          newPageContents.contents = editorContent.contents;
        }

        newPageContents.title = action.payload.pageTitle
          ? action.payload.pageTitle
          : '';

        newPageContents.description = action.payload.pageDescription
          ? action.payload.pageDescription
          : '';

        return newPageContents;
      });

      checkIfDocumentationIsValid(state);

      state.pages.startPageValidation = true;
    },
    STOP_PAGE_VALIDATING: (state) => {
      state.pages.startPageValidation = false;
      state.pageResponseLoading = false;
      state.languages.pendingDefaultPageLanguageSelection = false;

      assignLanguageFromPropertiesToInitialValueProperties(
        state,
        state.pages.currentLanguage,
      );
    },
    FINISH_CREATING_PAGE: (state) => {
      state.pageObjectResponse = initialState.pageObjectResponse;
      state.pages = initialState.pages;
      state.pageResponseLoading = false;
      state.pageMode = initialState.pageMode;
    },
    BACK_TO_ALL_PAGES_LISTING: (state) => {
      state.showAllPages = true;
    },
    EDITOR_ON_CHANGE: (state, action) => {
      state.editorOutputOnEditing = action.payload;
    },
    SET_TAGS_SELECTED_TREE_NODES: (state, action) => {
      state.tags.selectedTreeNodes = action.payload;
    },
    SET_TAGS: (state, action) => {
      state.tags.tags = action.payload;
    },
    SET_SHOULD_UPDATE_TAGS: (state, action) => {
      state.tags.shouldUpdateTags = action.payload;
    },
    OPEN_TAG_MODAL: (state) => {
      state.tags.openTagModal = true;
    },
    CLOSE_TAG_MODAL: (state) => {
      state.tags.openTagModal = false;
    },
    SET_LOADED_PAGE_TAGS: (state, action) => {
      state.tags.loadedPageTags = action.payload;
    },
    MAP_CLASSIFICATIONS_TAGS: (state) => {
      const mapData = (
        classificationNodes: IClassification[],
        pageClassifications: IClassification[] | undefined,
      ): ITagTreeNode[] => {
        return classificationNodes
          ?.filter((node) => {
            return pageClassifications?.some((elem) => {
              return elem.id === node.id;
            })
              ? true
              : node.enabled;
          })
          .map((node) => {
            return {
              title: node.name ? node.name : '',
              value: node.id.toString(),
              children: node.subClassifications
                ? mapData(node.subClassifications, pageClassifications)
                : undefined,
            };
          });
      };

      state.tags.treeData = mapData(
        state.tags.classifications,
        state.pageObjectResponse?.classifications,
      );
    },
    CLEAR_TREE_FILTER: (state) => {
      state.resultListResponseFilter = undefined;
    },
    OPEN_ADD_LANGUAGE_MODAL: (state) => {
      state.languages.openAddLanguageModal = true;
    },
    CLOSE_ADD_LANGUAGE_MODAL: (state) => {
      state.languages.selectedLanguageToAdd = undefined;
      state.languages.openAddLanguageModal = false;
    },
    SET_SELECTED_LANGUAGE_TO_ADD: (state, action) => {
      state.languages.selectedLanguageToAdd = action.payload;
    },
    ADD_SELECTED_LANGUAGE: (state) => {
      const languageToAdd = state.languages.selectedLanguageToAdd;
      if (!languageToAdd) {
        return;
      }

      if (!state.pages.pages.length) {
        state.pages.currentLanguage = languageToAdd;
      }

      state.pages.pages.push({
        title: '',
        description: '',
        contents: '',
        language: languageToAdd,
        isValid: true,
      });

      state.editorOutput.push({ language: languageToAdd, contents: undefined });
    },
    DELETE_LANGUAGE: (state, action) => {
      const languageToDelete = action.payload;
      if (!languageToDelete) {
        return;
      }

      state.pages.pages = state.pages.pages.filter(
        (page) => page.language !== languageToDelete,
      );
      state.editorOutput = state.editorOutput.filter(
        (editorOutput) => editorOutput.language !== languageToDelete,
      );

      // Set the new current language if there is a page version remaining
      const page = state.pages.pages.at(0);
      if (!page) {
        state.pages.currentLanguage = undefined;
        return;
      }

      state.pages.currentLanguage = page.language;
      assignLanguageFromPropertiesToInitialValueProperties(
        state,
        page.language,
      );
    },
    SET_PENDING_DEFAULT_PAGE_LANGUAGE_SELECTION: (state, action) => {
      state.languages.pendingDefaultPageLanguageSelection = action.payload;
    },
    SET_DEFAULT_PAGE_LANGUAGE: (state, action) => {
      state.languages.defaultPageLanguage = action.payload;
    },
    OPEN_SELECT_DEFAULT_PAGE_LANGUAGE_MODAL: (state) => {
      state.languages.openSelectDefaultPageLanguageModal = true;
    },
    CLOSE_SELECT_DEFAULT_PAGE_LANGUAGE_MODAL: (state) => {
      state.languages.openSelectDefaultPageLanguageModal = false;
    },
    SET_PAGE_TO_EVALUATION: (state, action) => {
      state.pageToEvaluate = action.payload;
    },
    OPEN_EVALUATION_MODAL: (state, action) => {
      state.pageToEvaluate = action.payload;
      state.openEvaluationModal = true;
    },
    CLOSE_EVALUATION_MODAL: (state) => {
      state.openEvaluationModal = false;
    },
    OPEN_EDIT_EVALUATION_MODAL: (state, action) => {
      state.evaluationToEdit = action.payload.page;
      state.openEditEvaluationModal = true;
    },
    CLOSE_EDIT_EVALUATION_MODAL: (state) => {
      state.evaluationToEdit = undefined;
      state.openEditEvaluationModal = false;
    },
    RESET_SHOULD_UPDATE: (state) => {
      state.shouldUpdate = false;
    },
  },
  extraReducers: (builder) => [
    builder.addCase(asyncPageActions.GET_PAGE_BY_SLUG.pending, (state) => {
      state.pageResponseLoading = true;
      state.pageLoaded = false;
    }),
    builder.addCase(
      asyncPageActions.GET_PAGE_BY_SLUG.fulfilled,
      (state, action) => {
        state.pageObjectResponse = action.payload.pageObjectResponse;
        state.pageResponseLoading = false;
        state.pageLoaded = true;

        formatPageFromBackendResponse(state);
      },
    ),
    builder.addCase(asyncPageActions.GET_PAGE_BY_SLUG.rejected, (state) => {
      state.pageRejected = true;
      state.pageResponseLoading = false;
      state.pageLoaded = false;
    }),
    builder.addCase(
      asyncPageActions.GET_PAGE_BY_SLUG_AUTHENTICATED.pending,
      (state) => {
        state.pageResponseLoading = true;
        state.pageLoaded = false;
      },
    ),
    builder.addCase(
      asyncPageActions.GET_PAGE_BY_SLUG_AUTHENTICATED.fulfilled,
      (state, action) => {
        state.pageObjectResponse = action.payload.pageObjectResponse;
        state.pageResponseLoading = false;
        state.pageLoaded = true;
        if (Array.isArray(action.payload.pageEvaluationsList)) {
          state.pageEvaluationsList = action.payload.pageEvaluationsList;
        } else {
          state.pageEvaluationsList = [action.payload.pageEvaluationsList];
        }

        formatPageFromBackendResponse(state);
      },
    ),
    builder.addCase(
      asyncPageActions.GET_PAGE_BY_SLUG_AUTHENTICATED.rejected,
      (state) => {
        state.pageRejected = true;
        state.pageResponseLoading = false;
        state.pageLoaded = false;
      },
    ),
    builder.addCase(
      asyncPageActions.GET_ALL_PAGES.fulfilled,
      (state, action) => {
        state.pageListResponse = action.payload.pageListResponse;

        state.loadedPages = true;
      },
    ),
    builder.addCase(
      asyncPageActions.FIND_PAGE_BY_TITLE.fulfilled,
      (state, action) => {
        state.searchPageTitle = action.payload.title;
        state.showAllPages = false;
        state.loadingSearchedPage = false;
        state.pageObjectResponseSearch = action.payload.pageObjectResponse;
      },
    ),
    builder.addCase(asyncPageActions.GET_PAGE_TO_UPDATE.pending, (state) => {
      state.pageResponseLoading = true;
    }),
    builder.addCase(
      asyncPageActions.GET_PAGE_TO_UPDATE.fulfilled,
      (state, action) => {
        state.pageObjectResponse = action.payload.updatedDocumentation;
        state.pageResponseLoading = false;

        formatPageFromBackendResponse(state);

        state.loadedToUpdate = true;
        state.idFromLoadedPageToUpdate = action.payload.updatedDocumentation.id;
      },
    ),
    builder.addCase(asyncPageActions.GET_PAGE_TO_UPDATE.rejected, (state) => {
      state.pageRejected = true;
      state.pageResponseLoading = false;
    }),
    builder.addCase(asyncPageActions.CREATE_A_NEW_PAGE.pending, (state) => {
      state.pages.startPageValidation = false;
    }),
    builder.addCase(asyncPageActions.CREATE_A_NEW_PAGE.rejected, (state) => {
      state.pageResponseLoading = false;

      assignLanguageFromPropertiesToInitialValueProperties(
        state,
        state.pages.currentLanguage,
      );
    }),
    builder.addCase(
      asyncPageActions.CREATE_A_NEW_PAGE.fulfilled,
      (state, action) => {
        state.pages.finishedCreatingPage = true;
        state.pages.idFromRecentlyCreatedPage = action.payload.createdPage.id;

        state.editorOutputOnEditing = initialState.editorOutputOnEditing;
      },
    ),
    builder.addCase(asyncPageActions.UPDATE_A_PAGE.pending, (state) => {
      state.pages.startPageValidation = false;
    }),
    builder.addCase(asyncPageActions.UPDATE_A_PAGE.rejected, (state) => {
      state.pageResponseLoading = false;

      assignLanguageFromPropertiesToInitialValueProperties(
        state,
        state.pages.currentLanguage,
      );
    }),
    builder.addCase(asyncPageActions.UPDATE_A_PAGE.fulfilled, (state) => {
      state.pages.finishedCreatingPage = true;
      state.pages.idFromRecentlyCreatedPage = state.pageObjectResponse?.id;

      state.editorOutputOnEditing = initialState.editorOutputOnEditing;
    }),
    builder.addCase(
      asyncPageActions.GET_ALL_CLASSIFICATIONS.pending,
      (state) => {
        state.pageResponseLoading = true;
      },
    ),
    builder.addCase(
      asyncPageActions.GET_ALL_CLASSIFICATIONS.rejected,
      (state) => {
        state.pageResponseLoading = false;
      },
    ),
    builder.addCase(
      asyncPageActions.GET_ALL_CLASSIFICATIONS.fulfilled,
      (state, action) => {
        state.pageResponseLoading = false;
        if (action.payload.classifications) {
          state.tags.classifications = action.payload.classifications;
          state.tags.treeData = mapTreeData(state.tags.classifications);
        }
      },
    ),
    builder.addCase(
      asyncPageActions.GET_PAGE_BY_CLASSIFICATIONS_ID.pending,
      (state) => {
        state.pageResponseLoading = true;
      },
    ),
    builder.addCase(
      asyncPageActions.GET_PAGE_BY_CLASSIFICATIONS_ID.rejected,
      (state) => {
        state.pageRejected = true;
      },
    ),
    builder.addCase(
      asyncPageActions.GET_PAGE_BY_CLASSIFICATIONS_ID.fulfilled,
      (state, action) => {
        state.resultListResponseFilter = action.payload.pageListResponse;
      },
    ),
    builder.addCase(asyncPageActions.NEW_PAGE_EVALUATION.pending, (state) => {
      state.isEvaluatingPage = true;
    }),
    builder.addCase(asyncPageActions.NEW_PAGE_EVALUATION.rejected, (state) => {
      state.isEvaluatingPage = false;
    }),
    builder.addCase(asyncPageActions.NEW_PAGE_EVALUATION.fulfilled, (state) => {
      state.isEvaluatingPage = false;
      state.openEvaluationModal = false;
      state.shouldUpdate = true;

      messageNotification.successMessage(
        unhookedTranslation('page-evaluation-page-sucessfuly-created-title'),
        unhookedTranslation('page-evaluation-page-sucessfuly-created-content'),
      );
    }),
    builder.addCase(
      asyncPageActions.UPDATE_PAGE_EVALUATION.pending,
      (state) => {
        state.isEvaluatingPage = true;
      },
    ),
    builder.addCase(
      asyncPageActions.UPDATE_PAGE_EVALUATION.rejected,
      (state) => {
        state.isEvaluatingPage = false;
      },
    ),
    builder.addCase(
      asyncPageActions.UPDATE_PAGE_EVALUATION.fulfilled,
      (state) => {
        state.isEvaluatingPage = false;
        state.openEvaluationModal = false;
        state.shouldUpdate = true;

        messageNotification.successMessage(
          unhookedTranslation('page-evaluation-page-sucessfuly-updated-title'),
          unhookedTranslation(
            'page-evaluation-page-sucessfuly-updated-content',
          ),
        );
      },
    ),
    builder.addCase(
      asyncPageActions.DELETE_PAGE_EVALUATION.pending,
      (state) => {
        state.isDeletingEvaluation = true;
      },
    ),
    builder.addCase(
      asyncPageActions.DELETE_PAGE_EVALUATION.rejected,
      (state) => {
        state.isDeletingEvaluation = false;
      },
    ),
    builder.addCase(
      asyncPageActions.DELETE_PAGE_EVALUATION.fulfilled,
      (state) => {
        state.isDeletingEvaluation = false;
        state.shouldUpdate = true;

        messageNotification.successMessage(
          unhookedTranslation('page-evaluation-page-sucessfuly-deleted-title'),
          unhookedTranslation(
            'page-evaluation-page-sucessfuly-deleted-content',
          ),
        );
      },
    ),
  ],
});

const assignLanguageFromPropertiesToInitialValueProperties = (
  state: ICreateReadEditPageSlice,
  language: PageLanguage | undefined,
) => {
  if (!language) {
    return;
  }

  state.pages.pages = state.pages.pages.map((page) => {
    if (page.language !== language) {
      return page;
    }

    state.pages.pageTitleInitialValue = page.title;
    state.pages.pageDescriptionInitialValue = page.description;

    const newPage = { ...page };

    const editorContents = state.editorOutput.find(
      (editorOutput) => editorOutput.language === language,
    );
    if (editorContents?.contents) {
      newPage.contents = editorContents.contents;
    }

    state.pages.pageEditorInitialValue = newPage.contents
      ? newPage.contents
      : '';

    state.editorOutputOnEditing = state.pages.pageEditorInitialValue;

    return newPage;
  });
};

const checkIfDocumentationIsValid = (state: ICreateReadEditPageSlice) => {
  state.pages.pages = state.pages.pages.map((page) => {
    let isValid;
    if (
      (page.title && page.description && page.contents) ||
      (!page.title && !page.description && !page.contents)
    ) {
      isValid = true;
    } else {
      isValid = false;
    }

    return { ...page, isValid: isValid };
  });
};

const formatPageFromBackendResponse = (state: ICreateReadEditPageSlice) => {
  state.pages.pages = [];

  state.pageObjectResponse?.pageContents.forEach((el) => {
    const page: IPageAndLanguage = {
      title: el.title,
      description: el.subtitle,
      contents: el.mainContent,
      language: el.language as PageLanguage,
      isValid: true,
    };
    state.pages.pages.push(page);

    state.editorOutput.push({
      language: el.language as PageLanguage,
      contents: undefined,
    });
  });

  if (state.pages.currentLanguage) {
    assignLanguageFromPropertiesToInitialValueProperties(
      state,
      state.pages.currentLanguage,
    );

    state.pages.pages.map((page) => {
      if (page.language === state.pages.currentLanguage) {
        state.editorOutputOnEditing = page.contents;
      }
    });
  }
};
