import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  evaluationApi,
  IFileEvaluations,
  IFolderEvaluations,
  IPageEvaluations,
} from '../../services/evaluations.api';
import { IPageBackendDTO } from '../../services/createReadEditPage.api';

export interface IEvaluationSlice {
  evaluationsList:
    | IPageEvaluations[]
    | IFolderEvaluations[]
    | IFileEvaluations[]
    | undefined;
  isLoading: boolean;
  openEvaluationCommentsModal: boolean;
  openEvaluationModal: boolean;
  evaluatedItem: any | undefined;
  evaluationFirstQueryBehaviour: boolean;
  sortBy: string | undefined;
  shouldRefresh: boolean;
  pageIndex: number;
  pageSize: number;
  numberOfEvaluations: number;
  commentsList: any[] | undefined;
  pageObjectResponse: IPageBackendDTO | undefined;
}

const initialState: IEvaluationSlice = {
  evaluationsList: undefined,
  isLoading: false,
  openEvaluationCommentsModal: false,
  openEvaluationModal: false,
  evaluatedItem: undefined,
  evaluationFirstQueryBehaviour: true,
  sortBy: undefined,
  shouldRefresh: true,
  pageIndex: 1,
  pageSize: 10,
  numberOfEvaluations: 0,
  commentsList: undefined,
  pageObjectResponse: undefined,
};

export const asyncEvaluationActions = {
  GET_FOLDER_EVALUATIONS: createAsyncThunk(
    'GET_FOLDER_EVALUATIONS',
    async (payload: { itemId: number; sortBy: string }) => {
      const folderEvaluationsListResponse =
        await evaluationApi.getFolderEvaluations(
          payload.itemId,
          payload.sortBy,
        );
      const data = Array.isArray(folderEvaluationsListResponse.data.content)
        ? folderEvaluationsListResponse.data.content
        : [folderEvaluationsListResponse.data.content];

      const comments = data.map((evaluation) => {
        return {
          userId: evaluation.userId,
          user: evaluation.user,
          liked: evaluation.liked,
          comment: evaluation.description,
        };
      });
      return {
        evaluationsListResponse: data,
        numberOfEvaluations:
          folderEvaluationsListResponse.data.numberOfElements,
        commentsList: comments,
      };
    },
  ),
  GET_FILE_EVALUATIONS: createAsyncThunk(
    'GET_FILE_EVALUATIONS',
    async (payload: { itemId: number; sortBy: string }) => {
      const fileEvaluationsListResponse =
        await evaluationApi.getFileEvaluations(payload.itemId, payload.sortBy);
      const data = Array.isArray(fileEvaluationsListResponse.data.content)
        ? fileEvaluationsListResponse.data.content
        : [fileEvaluationsListResponse.data.content];

      const comments = data.map((evaluation) => {
        return {
          userId: evaluation.userId,
          user: evaluation.user,
          liked: evaluation.liked,
          comment: evaluation.description,
        };
      });
      return {
        evaluationsListResponse: data,
        numberOfEvaluations: fileEvaluationsListResponse.data.numberOfElements,
        commentsList: comments,
      };
    },
  ),
  GET_PAGE_EVALUATIONS: createAsyncThunk(
    'GET_PAGE_EVALUATIONS',
    async (payload: { itemId: number; sortBy: string }) => {
      const pageEvaluationsListResponse =
        await evaluationApi.getPageEvaluations(payload.itemId, payload.sortBy);
      const data = Array.isArray(pageEvaluationsListResponse.data.content)
        ? pageEvaluationsListResponse.data.content
        : [pageEvaluationsListResponse.data.content];

      const comments = data.map((evaluation: any) => {
        return {
          userId: evaluation.userId,
          user: evaluation.user,
          liked: evaluation.liked,
          comment: evaluation.description,
        };
      });
      return {
        evaluationsListResponse: data,
        numberOfEvaluations: pageEvaluationsListResponse.data.numberOfElements,
        commentsList: comments,
      };
    },
  ),
  GET_PAGE_BY_SLUG_AUTHENTICATED: createAsyncThunk(
    'GET_PAGE_BY_SLUG_AUTHENTICATED',
    async (payload: any) => {
      const pageResponse = await evaluationApi.getPageBySlugAuthenticated(
        payload.slug,
      );

      return {
        pageObjectResponse: pageResponse.data,
      };
    },
  ),
};

export const EvaluationSlice = createSlice({
  name: 'EvaluationSlice',
  initialState,
  reducers: {
    OPEN_EVALUATION_COMMENTS_MODAL: (state) => {
      state.openEvaluationCommentsModal = true;
    },
    CLOSE_EVALUATION_COMMENTS_MODAL: (state) => {
      state.openEvaluationCommentsModal = false;
    },
    OPEN_EVALUATION_MODAL: (state, action) => {
      state.openEvaluationModal = true;
      state.evaluatedItem = action.payload;
    },
    CLOSE_EVALUATION_MODAL: (state) => {
      state.openEvaluationModal = false;
    },
    EVALUATION_SORT_BY: (state, action) => {
      state.shouldRefresh = true;
      state.sortBy = action.payload;
    },
    DISABLE_DEFAULT_SORT_BEHAVIOUR: (state) => {
      state.evaluationFirstQueryBehaviour = false;
    },
    EVALUATION_CHANGE_PAGE: (state, action) => {
      state.pageIndex = action.payload;
    },
  },
  extraReducers: (builder) => [
    builder.addCase(
      asyncEvaluationActions.GET_FOLDER_EVALUATIONS.pending,
      (state) => {
        state.isLoading = true;
      },
    ),
    builder.addCase(
      asyncEvaluationActions.GET_FOLDER_EVALUATIONS.rejected,
      (state) => {
        state.isLoading = false;
        state.evaluationsList = undefined;
      },
    ),
    builder.addCase(
      asyncEvaluationActions.GET_FOLDER_EVALUATIONS.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.evaluationsList = action.payload
          .evaluationsListResponse as IFolderEvaluations[];
        state.numberOfEvaluations = action.payload.numberOfEvaluations;
        state.commentsList = action.payload.commentsList;
      },
    ),
    builder.addCase(
      asyncEvaluationActions.GET_FILE_EVALUATIONS.pending,
      (state) => {
        state.isLoading = true;
      },
    ),
    builder.addCase(
      asyncEvaluationActions.GET_FILE_EVALUATIONS.rejected,
      (state) => {
        state.isLoading = false;
        state.evaluationsList = undefined;
      },
    ),
    builder.addCase(
      asyncEvaluationActions.GET_FILE_EVALUATIONS.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.evaluationsList = action.payload
          .evaluationsListResponse as IFileEvaluations[];
        state.numberOfEvaluations = action.payload.numberOfEvaluations;
        state.commentsList = action.payload.commentsList;
      },
    ),
    builder.addCase(
      asyncEvaluationActions.GET_PAGE_EVALUATIONS.pending,
      (state) => {
        state.isLoading = true;
      },
    ),
    builder.addCase(
      asyncEvaluationActions.GET_PAGE_EVALUATIONS.rejected,
      (state) => {
        state.isLoading = false;
        state.evaluationsList = undefined;
      },
    ),
    builder.addCase(
      asyncEvaluationActions.GET_PAGE_EVALUATIONS.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.evaluationsList = action.payload
          .evaluationsListResponse as IPageEvaluations[];
        state.numberOfEvaluations = action.payload.numberOfEvaluations;
        state.commentsList = action.payload.commentsList;
      },
    ),
    builder.addCase(
      asyncEvaluationActions.GET_PAGE_BY_SLUG_AUTHENTICATED.fulfilled,
      (state, action) => {
        state.pageObjectResponse = action.payload.pageObjectResponse;
      },
    ),
  ],
});
