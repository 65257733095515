import React, { useEffect, useRef, useState } from 'react';
import { Button, Checkbox, Col, Row, Form, Modal, Radio } from 'antd';
import { t } from 'i18next';
import { DislikeTwoTone, LikeTwoTone } from '@ant-design/icons';
import ReactQuill from 'react-quill-new';
import { PageReactQuillWrapper } from '../../pages/PagesList/CreateReadEditPages/PageReactQuillWrapper/PageReactQuillWrapper';
import { PageMode } from '../../pages/PagesList/CreateReadEditPages/CreateReadEditPage.Slice';
import uniqid from 'uniqid';
import { showModal } from '../../helpers/showModal/showModal';

export interface IEvaluationModal {
  isOpen: boolean;
  isEditing: boolean;
  title: string;
  okText: string;
  cancelText: string;
  deleteText: string;
  requiredText: string | undefined;
  itemType: string;
  itemId: number;
  defaultEvaluationRadio: boolean | null;
  onCreateEvaluation: (value: any, itemType: string, itemId: number) => void;
  onEditEvaluation: (value: any, itemType: string, itemId: number) => void;
  onCancel: () => void;
  onDeleteEvaluation: (itemType: string, itemId: number) => void;
  isUserAdmin: boolean;
  evaluationValues: any | undefined;
}

export const EvaluationModal: React.FC<IEvaluationModal> = ({
  isOpen,
  isEditing,
  title,
  okText,
  cancelText,
  deleteText,
  requiredText,
  itemType,
  itemId,
  defaultEvaluationRadio,
  onCreateEvaluation,
  onEditEvaluation,
  onCancel,
  onDeleteEvaluation,
  isUserAdmin,
  evaluationValues,
}) => {
  const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(true);
  const [isLikeRadioSelected, setIsLikeRadioSelected] = useState<boolean>(true);
  const [showRequiredMessage, setShowRequiredMessage] =
    useState<boolean>(false);

  const [form] = Form.useForm();
  const editorRef = useRef(
    isUserAdmin || isEditing ? evaluationValues?.description : '',
  );
  const folderCheckboxOptions = [
    {
      label: t('evaluation-modal-folder-description-checkbox-label'),
      value: 'folderDescription',
    },
    {
      label: t('evaluation-modal-folder-documentation-location-checkbox-label'),
      value: 'locationOfDocuments',
    },
    {
      label: t('evaluation-modal-folder-organization-checkbox-label'),
      value: 'folderOrganization',
    },
  ];

  const fileCheckboxOptions = [
    {
      label: t('evaluation-modal-file-information-accuracy-checkbox-label'),
      value: 'informationAccuracy',
    },
    {
      label: t('evaluation-modal-file-clarity-checkbox-label'),
      value: 'clarity',
    },
    {
      label: t('evaluation-modal-file-information-availability-checkbox-label'),
      value: 'availabilityOfInformation',
    },
    {
      label: t('evaluation-modal-file-preferred-language-checkbox-label'),
      value: 'preferredLanguage',
    },
    {
      label: t('evaluation-modal-file-location-checkbox-label'),
      value: 'locate',
    },
    {
      label: t('evaluation-modal-file-content-depth-checkbox-label'),
      value: 'contentDepth',
    },
  ];

  const documentationCheckboxOptions = [
    {
      label: t(
        'evaluation-modal-documentation-information-accuracy-checkbox-label',
      ),
      value: 'informationAccuracy',
    },
    {
      label: t('evaluation-modal-documentation-clarity-checkbox-label'),
      value: 'clarity',
    },
    {
      label: t('evaluation-modal-documentation-description-checkbox-label'),
      value: 'pageDescription',
    },
    {
      label: t(
        'evaluation-modal-documentation-information-availability-checkbox-label',
      ),
      value: 'availabilityOfInformation',
    },
    {
      label: t(
        'evaluation-modal-documentation-preferred-language-checkbox-label',
      ),
      value: 'preferredLanguage',
    },
    {
      label: t('evaluation-modal-documentation-location-checkbox-label'),
      value: 'locate',
    },
    {
      label: t('evaluation-modal-documentation-content-depth-checkbox-label'),
      value: 'depthOfInformation',
    },
  ];

  const sortedCheckBoxOptions = (options: any) => {
    options.sort((a: any, b: any) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });

    return options;
  };

  let radioLabel;
  let checkboxOptionsList;
  let evaluationBody: { [key: string]: boolean | string };

  if (itemType === 'ARTIFACT') {
    checkboxOptionsList = sortedCheckBoxOptions(folderCheckboxOptions);
    radioLabel = t('evaluation-modal-evaluate-artifact-label');
    evaluationBody = {
      folderDescription: false,
      folderOrganization: false,
      locationOfDocuments: false,
    };
  } else if (itemType === 'FILE') {
    checkboxOptionsList = sortedCheckBoxOptions(fileCheckboxOptions);
    radioLabel = t('evaluation-modal-evaluate-file-label');
    evaluationBody = {
      availabilityOfInformation: false,
      clarity: false,
      contentDepth: false,
      informationAccuracy: false,
      locate: false,
      preferredLanguage: false,
    };
  } else {
    checkboxOptionsList = sortedCheckBoxOptions(documentationCheckboxOptions);
    radioLabel = t('evaluation-modal-evaluate-documentation-label');
    evaluationBody = {
      availabilityOfInformation: false,
      clarity: false,
      depthOfInformation: false,
      informationAccuracy: false,
      locate: false,
      pageDescription: false,
      preferredLanguage: false,
    };
  }

  const checkboxLabel = isLikeRadioSelected
    ? t('evaluation-modal-evaluate-liked-checkbox-label')
    : t('evaluation-modal-evaluate-disliked-checkbox-label');

  const textEditorLabel = isLikeRadioSelected
    ? t('evaluation-modal-evaluate-liked-editor-label')
    : t('evaluation-modal-evaluate-disliked-editor-label');

  const modules = {
    toolbar: [['bold', 'italic', 'underline'], ['image']],
  };

  const formats = ['bold', 'italic', 'underline', 'image'];

  // Function to remove HTML tags
  const stripHtmlTags = (html: any) => {
    const tmp = document.createElement('div');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
  };

  // Function to check if the content contains an <img> tag
  const containsImgTag = (html: any) => {
    const tmp = document.createElement('div');
    tmp.innerHTML = html;
    return tmp.querySelector('img') !== null;
  };

  const handleFormChange = () => {
    const evaluation = form.getFieldValue('evaluation');
    setIsLikeRadioSelected(evaluation);
    const hasCheckedValues = isEditing
      ? formCheckboxes.some((obj: any) => obj.checked)
      : form.getFieldValue('evaluationCheckbox')?.length > 0;

    // contentEvaluation includes html tags
    const contentEvaluation = form.getFieldValue('evalutionEditor');
    const plainText = contentEvaluation ? stripHtmlTags(contentEvaluation) : '';
    const hasText = plainText.length > 0;
    const isPlainTextLengthValid =
      plainText.length >= 1 && plainText.length <= 500;
    const editorHasOnlyAnImage = containsImgTag(contentEvaluation);

    const isValidEditorContent =
      editorHasOnlyAnImage || (hasText && isPlainTextLengthValid);

    const isValid =
      (hasCheckedValues && !hasText && !editorHasOnlyAnImage) ||
      (hasCheckedValues && isValidEditorContent) ||
      (!hasCheckedValues && isValidEditorContent);

    if (isValid) {
      setIsSaveDisabled(false);
      setShowRequiredMessage(false);
    } else {
      setIsSaveDisabled(true);
      setShowRequiredMessage(true);
    }
  };

  const onFinish = () => {
    form.validateFields().then((values) => {
      if (!isEditing) {
        for (const key in evaluationBody) {
          if (values.evaluationCheckbox.includes(key)) {
            evaluationBody[key] = true;
          }
        }
      } else {
        const newObjectArray = formCheckboxes.map((obj: any) => ({
          [obj.value]: obj.checked,
        }));
        const expandedObject = newObjectArray.reduce((acc: any, obj: any) => {
          return { ...acc, ...obj };
        }, {});
        evaluationBody = { ...evaluationBody, ...expandedObject };
      }

      const evaluation = {
        liked: values.evaluation,
        description: values.evalutionEditor,
        ...evaluationBody,
      };

      if (isEditing) {
        onEditEvaluation(evaluation, itemType, itemId);
        form.resetFields();
        return;
      }

      onCreateEvaluation(evaluation, itemType, itemId);
      form.resetFields();
    });
  };

  const resultObject = Object.keys(evaluationBody).reduce(
    (acc: { [key: string]: boolean }, key) => {
      if (evaluationValues && key in evaluationValues) {
        acc[key] = evaluationValues[key];
      }
      return acc;
    },
    {},
  );

  const checkbox = checkboxOptionsList.map((option: any) => {
    return {
      label: option.label,
      value: option.value,
      checked: resultObject[option.value],
    };
  });

  const [formCheckboxes, setFormCheckboxes] = useState<any>(
    checkbox === undefined ? [] : checkbox,
  );

  useEffect(() => {
    if (form && evaluationValues) {
      const existingValues = {
        evaluation: evaluationValues.liked,
        evaluationCheckbox: checkbox,
        evalutionEditor: editorRef.current,
      };
      setIsLikeRadioSelected(evaluationValues.liked);
      setFormCheckboxes(checkbox);
      form.setFieldsValue(existingValues);
    }
  }, [form, evaluationValues]);

  useEffect(() => {
    handleFormChange();
  }, [formCheckboxes]);

  return (
    <Modal
      title={title}
      visible={isOpen}
      /* This key format is necessary to render the updated checkbox values for the admin,
      and for regular users, it generates multiple renderings when the form is altered*/
      key={`${itemId}-${isUserAdmin ? uniqid() : ''}`}
      width="80vw"
      bodyStyle={{ height: '70vh', overflowY: 'auto' }}
      centered
      onCancel={onCancel}
      footer={
        !isUserAdmin ? (
          [
            isEditing && (
              <Button
                key="delete"
                onClick={() => {
                  showModal(
                    'warning',
                    t('evaluation-modal-delete-modal-title'),
                    t('evaluation-modal-delete-modal-subtitle'),
                    () => {
                      onDeleteEvaluation(itemType, itemId);
                    },
                    () => {
                      onCancel();
                    },
                    `${t('evaluation-modal-delete-button')}`,
                    `${t('evaluation-modal-cancel-button')}`,
                  );
                }}
              >
                {deleteText}
              </Button>
            ),
            <Button key="cancel" onClick={onCancel}>
              {cancelText}
            </Button>,
            <Button
              key="save"
              type="primary"
              disabled={isSaveDisabled}
              onClick={() => form.submit()}
            >
              {okText}
            </Button>,
          ]
        ) : (
          <Button key="admin-cancel" onClick={onCancel}>
            {cancelText}
          </Button>
        )
      }
    >
      <Form
        form={form}
        name="evaluation-form"
        initialValues={{
          evaluation: defaultEvaluationRadio,
          evaluationCheckbox: evaluationValues ? checkbox : [],
          evalutionEditor: evaluationValues ? evaluationValues.description : '',
        }}
        onFinish={onFinish}
        onFieldsChange={handleFormChange}
      >
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="evaluation"
              label={radioLabel}
              labelCol={{ span: 24 }}
            >
              <Radio.Group
                defaultValue={defaultEvaluationRadio}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '1rem',
                  marginBottom: '2rem',
                  gap: '2rem',
                }}
                buttonStyle="solid"
                disabled={isUserAdmin}
              >
                <Radio.Button value={true}>
                  <LikeTwoTone /> {t('repository-evaluation-liked-button')}
                </Radio.Button>
                <Radio.Button value={false}>
                  <DislikeTwoTone />{' '}
                  {t('repository-evaluation-disliked-button')}
                </Radio.Button>
              </Radio.Group>
            </Form.Item>
            {!isUserAdmin ? (
              <Form.Item
                name="evaluationCheckbox"
                label={checkboxLabel}
                labelCol={{ span: 24 }}
              >
                {!isEditing ? (
                  <Checkbox.Group>
                    {checkboxOptionsList.map((option: any, index: number) => {
                      return (
                        <Row key={index}>
                          <Checkbox value={option.value}>
                            {option.label}
                          </Checkbox>
                        </Row>
                      );
                    })}
                  </Checkbox.Group>
                ) : (
                  checkbox.map((option: any, index: number) => {
                    return (
                      <Row key={`edit-${index}`}>
                        <Checkbox
                          value={option.value}
                          defaultChecked={option.checked}
                          onChange={(e) => {
                            let checkboxesValues = undefined;
                            if (!formCheckboxes.length) {
                              setFormCheckboxes(
                                form.getFieldValue('evaluationCheckbox') || [],
                              );
                              checkboxesValues =
                                form.getFieldValue('evaluationCheckbox') || [];
                            } else {
                              checkboxesValues = formCheckboxes;
                            }

                            const isChecked = e.target.checked;
                            const newCheckboxesValues = checkboxesValues.map(
                              (element: any) => {
                                if (element.value !== e.target.value) {
                                  return element;
                                }

                                return {
                                  ...element,
                                  checked: isChecked,
                                };
                              },
                            );
                            form.setFieldValue(
                              'evaluationCheckbox',
                              newCheckboxesValues,
                            );
                            setFormCheckboxes(newCheckboxesValues);
                          }}
                        >
                          {option.label}
                        </Checkbox>
                      </Row>
                    );
                  })
                )}
              </Form.Item>
            ) : (
              <Form.Item
                name="evaluationCheckboxAdmin"
                label={checkboxLabel}
                labelCol={{ span: 24 }}
              >
                {checkbox.map((option: any, index: number) => {
                  return (
                    <Row key={index}>
                      <Checkbox
                        value={option.value}
                        defaultChecked={option.checked}
                        disabled={true}
                      >
                        {option.label}
                      </Checkbox>
                    </Row>
                  );
                })}
              </Form.Item>
            )}
          </Col>
          <Col span={16}>
            {!isUserAdmin ? (
              <Form.Item
                name="evalutionEditor"
                label={`${textEditorLabel} ${t('evaluation-modal-evaluate-liked-editor-max-length')}`}
                labelCol={{ span: 24 }}
              >
                <ReactQuill
                  theme="snow"
                  modules={modules}
                  formats={formats}
                  style={{ height: '40vh' }}
                  onChange={(content) => {
                    editorRef.current = content;
                  }}
                />
              </Form.Item>
            ) : (
              <Form.Item
                name="evalutionEditor"
                label={textEditorLabel}
                labelCol={{ span: 24 }}
                style={{
                  marginBottom: '1.5rem',
                }}
              >
                <PageReactQuillWrapper
                  pageMode={PageMode.Read}
                  editorInitialValue={
                    evaluationValues && evaluationValues.description
                      ? evaluationValues.description
                      : t('evaluation-modal-any-comment-label')
                  }
                  loading={false}
                />
              </Form.Item>
            )}
          </Col>
        </Row>
      </Form>
      {!isUserAdmin && showRequiredMessage && (
        <Row
          style={{
            color: 'red',
            marginTop: '2%',
            textAlign: 'center',
            fontSize: '0.8rem',
          }}
        >
          {requiredText}
        </Row>
      )}
    </Modal>
  );
};
