import React from 'react';
import { Button, Divider, Modal, Row } from 'antd';
import { DislikeTwoTone, LikeTwoTone } from '@ant-design/icons';
import { PageReactQuillWrapper } from '../../PagesList/CreateReadEditPages/PageReactQuillWrapper/PageReactQuillWrapper';
import { PageMode } from '../../PagesList/CreateReadEditPages/CreateReadEditPage.Slice';
import { useTranslation } from 'react-i18next';

export interface IEvaluationCommentsModal {
  isOpen: boolean;
  title: string;
  closeText: string;
  commentsList: any[] | undefined;
  onOk: () => void;
  onCancel: () => void;
}

export const EvaluationsCommentsModal: React.FC<IEvaluationCommentsModal> = ({
  isOpen,
  title,
  closeText,
  commentsList,
  onOk,
  onCancel,
}) => {
  const { t } = useTranslation();
  const hasComments = commentsList?.some((item) => item.comment) || false;
  return (
    <Modal
      title={title}
      visible={isOpen}
      width="80vw"
      bodyStyle={{ height: '70vh', overflowY: 'auto' }}
      centered
      onOk={onOk}
      onCancel={onCancel}
      footer={
        <Button key="close" type="primary" onClick={onOk}>
          {closeText}
        </Button>
      }
    >
      {hasComments ? (
        commentsList?.map((item: any) => {
          const likedIcon = item.liked ? (
            <LikeTwoTone twoToneColor="#41b4d2" />
          ) : (
            <DislikeTwoTone twoToneColor="#D46b08" />
          );
          if (item.comment) {
            return (
              <div key={`comment-${item.userId}-${item.user.firstName}`}>
                <Row style={{ fontWeight: 'bold' }}>
                  <span>
                    {item.user.firstName} {item.user.lastName} &nbsp;
                  </span>{' '}
                  {likedIcon}
                </Row>
                <Row>
                  <PageReactQuillWrapper
                    pageMode={PageMode.Read}
                    editorInitialValue={item.comment}
                    loading={false}
                  />
                </Row>
                <Divider type="horizontal" />
              </div>
            );
          }
        })
      ) : (
        <Row>{t('evaluation-comments-modal-any-comments-label')}</Row>
      )}
    </Modal>
  );
};
