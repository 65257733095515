import React, { Fragment, useRef } from 'react';
import { Form, Select, Col, Row, Input, Button } from 'antd';
import { useTranslation } from 'react-i18next';

import uniqid from 'uniqid';

import './NewRegister.scss';
import { IMaskInput } from 'react-imask';
import { FormInstance } from 'antd/lib/form';
import { InfoCircleOutlined } from '@ant-design/icons';

interface INewRegister {
  dataRegisterLeftColumn: IDataRegisterField[];
  dataRegisterRightColumn: IDataRegisterField[];
  newRegister: Function;
  requestAccessMode?: boolean;
  pushTo: string;
  checkChangesAndGoBack?: Function;
  requestAccessModeToggleModal?: () => void;
  initialValues?: object;
  showEditMode?: boolean;
  saveValuesOnChange?: Function;
}

interface IDataRegisterField {
  name: string | string[];
  type: string | string[];
  size: number | number[];
  onChangeEvent?: Function | Function[];
  selectId?: string | undefined;
  currentValue?: any;
  options?: any[];
  id: string;
  validationCheck?: boolean | undefined;
  disabled?: boolean;
  placeholder?: string;
  mustBeEqualToFieldWithId?: string;
  hidden?: boolean;
}

export const NewRegister: React.FC<INewRegister> = ({
  dataRegisterLeftColumn,
  dataRegisterRightColumn,
  newRegister,
  requestAccessMode = false,
  checkChangesAndGoBack,
  initialValues,
  showEditMode,
  requestAccessModeToggleModal = () => {},
  saveValuesOnChange,
}: INewRegister) => {
  const { t } = useTranslation();
  const formRef = useRef(null);
  const [form] = Form.useForm();

  const generateSelectOptions = (options: any[]) => {
    if (!options) {
      return;
    }

    return options.map((x) => {
      return (
        <Select.Option key={x.id} id={x.name} value={x.id}>
          {x.name}
        </Select.Option>
      );
    });
  };

  const generateFieldByType = (specificType: string | undefined, item: any) => {
    if (item.hidden && item.hidden === true) {
      return;
    }

    switch (specificType || item.type) {
      case 'identifierInput':
      case 'input':
        return (
          <Form.Item
            name={item.id}
            label={item.name}
            className="register-text-identifier"
            rules={[
              {
                required: true,
                message: t('administrative-panel-new-register-required-field'),
              },
            ]}
          >
            <Input
              maxLength={64}
              placeholder={item.placeholder || item.name}
              onChange={(event) => {
                let inputValue = event.target.value;

                if (item.type === 'identifierInput') {
                  // Remove any whitespaces from the string
                  inputValue = inputValue.replace(/\s/g, '');

                  const formInstance =
                    formRef.current as unknown as FormInstance;

                  if (formInstance) {
                    formInstance.setFieldsValue({
                      [item.id]: inputValue,
                    });
                  }
                }

                item.onChangeEvent(inputValue);
              }}
              disabled={item.disabled || false}
            />
          </Form.Item>
        );

      case 'email':
        return (
          <Form.Item
            name={item.id}
            label={item.name}
            className="register-text-identifier"
            rules={[
              {
                required: true,
                message: t('administrative-panel-new-register-required-field'),
              },
              {
                type: 'email',
                message: t('administrative-panel-new-company-email-validation'),
              },
              () => ({
                validator(rule, value) {
                  if (!value || value.split('@').shift().length <= 64) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    t('administrative-panel-new-company-email-validation'),
                  );
                },
              }),
            ]}
          >
            <Input
              maxLength={254}
              placeholder={item.placeholder || item.name}
              onChange={(event) => {
                item.onChangeEvent(event.target.value);
              }}
              disabled={item.disabled || false}
            />
          </Form.Item>
        );

      case 'confirm-email':
        return (
          <Form.Item
            name={item.id}
            label={item.name}
            className="register-text-identifier"
            rules={[
              {
                required: true,
                message: t('administrative-panel-new-register-required-field'),
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  const fieldValue = value;

                  if (!fieldValue) {
                    return Promise.resolve();
                  }

                  if (
                    getFieldValue(item.mustBeEqualToFieldWithId as string) ===
                    fieldValue
                  ) {
                    return Promise.resolve();
                  }

                  return Promise.reject(
                    t(
                      'administrative-panel-users-tab-user-alert-email-not-equal',
                    ),
                  );
                },
              }),
            ]}
          >
            <Input
              maxLength={254}
              placeholder={item.placeholder || item.name}
              onChange={(event) => {
                item.onChangeEvent(event.target.value);
              }}
              disabled={item.disabled || false}
            />
          </Form.Item>
        );

      case 'companyEmail':
        return (
          <>
            <Form.Item
              name={item.id}
              label={item.name}
              className="register-text-identifier"
              rules={[
                {
                  required: true,
                  message: t(
                    'administrative-panel-new-register-required-field',
                  ),
                },
                {
                  type: 'email',
                  message: t(
                    'administrative-panel-new-company-email-validation',
                  ),
                },
                () => ({
                  validator(rule, value) {
                    if (!value || value.split('@').shift().length <= 64) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      t('administrative-panel-new-company-email-validation'),
                    );
                  },
                }),
              ]}
            >
              <Input
                maxLength={254}
                placeholder={item.placeholder || item.name}
                onChange={(event) => {
                  item.onChangeEvent(event.target.value);
                }}
                disabled={item.disabled || false}
              />
            </Form.Item>
            <Row className="observation-text-row">
              <Col className="observation-text">
                <InfoCircleOutlined className="observation-icon" />
                {t('request-access-company-email-observation-text')}
              </Col>
            </Row>
          </>
        );

      case 'CNPJmaskedInput':
        return (
          <Form.Item
            name={item.id}
            label={item.name}
            className="register-text-identifier"
            rules={[
              {
                required: true,
                message: t('administrative-panel-new-register-required-field'),
              },
              () => ({
                validator(rule, value) {
                  const fieldValue = value;

                  if (!value) {
                    return Promise.resolve();
                  }

                  if (fieldValue.includes('_')) {
                    return Promise.reject(
                      t('administrative-panel-new-company-cnpj-validation'),
                    );
                  }

                  return Promise.resolve();
                },
              }),
            ]}
            normalize={(value) => value || ''}
          >
            <span className="ant-input-affix-wrapper">
              <IMaskInput
                className="ant-input"
                value={item.currentValue?.replace(/\D/g, '')}
                key={item.id}
                mask={'00.000.000/0000-00'}
                unmask={true}
                lazy={false}
                onAccept={(value, maskValue) => {
                  const formInstance =
                    formRef.current as unknown as FormInstance;
                  if (formInstance) {
                    formInstance.setFieldsValue({
                      [item.id]: maskValue._value,
                    });
                  }
                }}
                name={item.id}
              />
            </span>
          </Form.Item>
        );

      case 'select':
        return (
          <Form.Item
            name={item.id}
            label={item.name}
            className="register-text-identifier"
            rules={[
              {
                required: true,
                message: t('administrative-panel-new-register-required-field'),
              },
            ]}
          >
            <Select
              showSearch
              optionFilterProp="children"
              placeholder={item.selectId}
              onChange={(event: string) => {
                const formInstance = formRef.current as unknown as FormInstance;
                if (formInstance && saveValuesOnChange) {
                  saveValuesOnChange(formInstance.getFieldsValue());
                }
                item.onChangeEvent(event);
              }}
            >
              {generateSelectOptions(item.options as any[])}
            </Select>
          </Form.Item>
        );
      default:
        break;
    }
  };

  const generateColumnItem = (item: any, disableMargin: boolean = false) => {
    if (!item) {
      return;
    }
    if (
      [
        'identifierInput',
        'input',
        'CNPJmaskedInput',
        'email',
        'confirm-email',
        'companyEmail',
      ].includes(item.type)
    ) {
      return (
        <Col span={item.size} offset={disableMargin ? 0 : 1}>
          {generateFieldByType(undefined, item)}
        </Col>
      );
    }
    if (Array.isArray(item.type)) {
      return item.type.map((x: any, i: number) => {
        const itemInArray = {
          name: item.name[i],
          id: item.id,
          onChangeEvent: item.onChangeEvent[i],
          selectId: item.selectId,
          currentValue: item.currentValue[i],
          options: item.options ? item.options[i] : undefined,
        };

        return (
          <Col key={item.id} span={item.size[i]} offset={disableMargin ? 0 : 1}>
            {generateFieldByType(x, itemInArray)}
          </Col>
        );
      });
    }
  };

  const generateContent = () => {
    const generatedContent = [];
    for (;;) {
      const leftColumnItem = dataRegisterLeftColumn.shift();
      const rightColumnItem = dataRegisterRightColumn.shift();

      if (!leftColumnItem && !rightColumnItem) {
        return generatedContent;
      }

      generatedContent.push(
        <Row className="register-text-label">
          {generateColumnItem(leftColumnItem, requestAccessMode)}
          {generateColumnItem(rightColumnItem)}
        </Row>,
      );
    }
  };

  const showRegisterOrSuggestLabel = () => {
    if (requestAccessMode) {
      return t('request-access-company-suggest-modal-button');
    }

    if (showEditMode) {
      return t('administrative-panel-edit-button');
    }

    return t(`administrative-panel-register-button`);
  };

  const generateFormKey = () => {
    if (initialValues) {
      return `${initialValues.toString()}${showEditMode}${uniqid()}`;
    }
  };

  const makeEmailsLowerCase = (changedValues: any) => {
    if (!form) {
      return;
    }

    if (changedValues.userEmail) {
      form.setFieldValue('userEmail', changedValues.userEmail.toLowerCase());
    }

    if (changedValues.confirmEmail) {
      form.setFieldValue(
        'confirmEmail',
        changedValues.confirmEmail.toLowerCase(),
      );
    }

    if (changedValues.responsibleEmail) {
      form.setFieldValue(
        'responsibleEmail',
        changedValues.responsibleEmail.toLowerCase(),
      );
    }
  };

  if (form) {
    form.setFieldsValue(initialValues);
  }

  return (
    <Row
      className="New-Register"
      style={requestAccessMode ? { margin: 0, padding: 0 } : undefined}
    >
      <Col span={requestAccessMode ? 24 : 18}>
        <Form
          ref={formRef}
          form={form}
          key={generateFormKey()}
          layout="vertical"
          className="register-input"
          onFinish={(values) => {
            newRegister(values);
          }}
          onValuesChange={makeEmailsLowerCase}
        >
          {generateContent()}
          <Form.Item>
            <Row className="register-button">
              <Button
                className="button-cancel"
                type="link"
                onClick={() => {
                  if (requestAccessMode) {
                    requestAccessModeToggleModal();
                  } else if (checkChangesAndGoBack) {
                    checkChangesAndGoBack();
                  }
                }}
              >
                {t(`administrative-panel-cancel-button`)}
              </Button>
              <Button className="button-register" htmlType="submit">
                {showRegisterOrSuggestLabel()}
              </Button>
            </Row>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};
