import {
  AppstoreAddOutlined,
  CloseOutlined,
  DeleteOutlined,
  DislikeOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  LikeOutlined,
} from '@ant-design/icons';
import {
  Alert,
  Col,
  Divider,
  Empty,
  List,
  Modal,
  Row,
  Space,
  Spin,
  Tooltip,
} from 'antd';
import React, { useEffect } from 'react';

import './NotificationsList.scss';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/store';
import {
  asyncActions,
  NotificationDeleteStatus,
  NotificationsListSlice,
} from './NotificationsList.Slice';
import {
  NotificationItemType,
  NotificationType,
} from '../../services/notification.api';
import { t } from 'i18next';
import Link from 'antd/lib/typography/Link';
import { NotificationTrackerSlice } from '../../pages/NotificationTracker/NotificationTracker.Slice';
import VirtualList from 'rc-virtual-list';
import { useNavigate } from 'react-router-dom';
import { NavbarSlice } from '../../pages/.Container/Navbar/Navbar.Slice';

// This conversion is necessary because the pages backend expects the full name of the language in uppercase,
// while the navbar stores the current language as an abbreviation.
const languageMap: { [key: string]: string } = {
  en: 'ENGLISH',
  es: 'SPANISH',
  pt: 'PORTUGUESE',
};

// Height of the item list. Must be adjusted if the content or the CSS is changed.
const listItemHeight = 78;
const visibleItemCount = 5;

interface INotificationsList {
  maxItemCount?: number;
  isModal?: boolean;
}

export const NotificationsList: React.FC<INotificationsList> = ({
  maxItemCount = 6,
  isModal = false,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const applicationLanguage = useSelector(
    (state: RootState) => languageMap[state.navBar.defaultLanguage],
  );

  const unviewedNotificationCount = useSelector(
    (state: RootState) => state.notificationTracker.unviewedCount,
  );

  const isOpen = useSelector(
    (state: RootState) => state.notificationsList.isOpen,
  );

  const isLoading = useSelector(
    (state: RootState) => state.notificationsList.isLoading,
  );

  const shouldUpdate = useSelector(
    (state: RootState) => state.notificationsList.shouldUpdate,
  );

  const shouldUpdateTracker = useSelector(
    (state: RootState) => state.notificationsList.shouldUpdateTracker,
  );

  const notifications = useSelector(
    (state: RootState) => state.notificationsList.notifications,
  );

  const notificationCountWhenOpened = useSelector(
    (state: RootState) => state.notificationsList.notificationCountWhenOpened,
  );

  const askToUpdate = useSelector(
    (state: RootState) => state.notificationsList.askToUpdate,
  );

  const currentNotificationPage = useSelector(
    (state: RootState) => state.notificationsList.currentPage,
  );

  const hasMoreNotifications = useSelector(
    (state: RootState) => state.notificationsList.hasMoreNotifications,
  );

  useEffect(() => {
    if (!isOpen || isLoading) {
      return;
    }

    if (unviewedNotificationCount > notificationCountWhenOpened) {
      dispatch(NotificationsListSlice.actions.SET_ASK_TO_UPDATE(true));
    }
  }, [unviewedNotificationCount]);

  useEffect(() => {
    if (!isOpen) {
      dispatch(NotificationsListSlice.actions.SET_ASK_TO_UPDATE(false));
      return;
    }

    dispatch(
      NotificationsListSlice.actions.SET_NOTIFICATION_COUNT_WHEN_OPENED(
        unviewedNotificationCount,
      ),
    );
    dispatch(NotificationsListSlice.actions.SET_SHOULD_UPDATE(true));
  }, [isOpen]);

  useEffect(() => {
    if (isLoading || !shouldUpdate || !isOpen) {
      return;
    }

    dispatch(NotificationsListSlice.actions.SET_SHOULD_UPDATE(false));
    dispatch(
      asyncActions.GET_NOTIFICATIONS({
        language: applicationLanguage,
        size: maxItemCount,
      }),
    );
  }, [shouldUpdate, isOpen]);

  useEffect(() => {
    if (!shouldUpdateTracker) {
      return;
    }

    dispatch(NotificationTrackerSlice.actions.SET_SHOULD_UPDATE(true));
    dispatch(NotificationsListSlice.actions.SET_SHOULD_UPDATE_TRACKER(false));
  }, [shouldUpdateTracker]);

  const deleteNotification = (notificationId: number) => {
    dispatch(asyncActions.DELETE_NOTIFICATION(notificationId));
  };

  const deleteAllNotifications = () => {
    Modal.confirm({
      zIndex: 10000,
      centered: true,
      closable: true,
      title: t('notification-list-delete-all-modal-title'),
      okText: t('notification-list-delete-all-modal-ok'),
      cancelText: t('notification-list-delete-all-modal-cancel'),
      onOk: () => {
        dispatch(asyncActions.DELETE_ALL_NOTIFICATIONS());
      },
      content: (
        <>
          <p>{t('notification-list-delete-all-modal-first-paragraph')}</p>
          <p>{t('notification-list-delete-all-modal-second-paragraph')}</p>
        </>
      ),
    });
  };

  const markAllNotificationsAsRead = () => {
    Modal.confirm({
      zIndex: 10000,
      centered: true,
      closable: true,
      title: t('notification-list-mark-all-read-modal-title'),
      okText: t('notification-list-mark-all-read-modal-ok'),
      cancelText: t('notification-list-mark-all-read-modal-cancel'),
      onOk: () => {
        dispatch(asyncActions.MARK_ALL_NOTIFICATIONS_AS_VIEWED());
        dispatch(NotificationTrackerSlice.actions.SET_SHOULD_UPDATE(true));
      },
      content: (
        <>
          <p>{t('notification-list-mark-all-read-modal-first-paragraph')}</p>
          <p>{t('notification-list-mark-all-read-modal-second-paragraph')}</p>
        </>
      ),
    });
  };

  const onNotificationClick = (
    notificationId: number,
    notificationType: NotificationType,
    viewed: boolean,
    itemId: string,
    itemType: NotificationItemType,
    itemName: string,
  ) => {
    if (!viewed) {
      dispatch(asyncActions.MARK_NOTIFICATION_AS_VIEWED(notificationId));
      dispatch(NotificationTrackerSlice.actions.SET_SHOULD_UPDATE(true));
    }

    if (notificationType === NotificationType.Deleted) {
      return;
    }

    if (isModal) {
      dispatch(NavbarSlice.actions.SET_IS_NOTIFICATION_MODAL_OPEN(false));
      dispatch(NotificationsListSlice.actions.SET_IS_OPEN(false));
    }

    navigate('/evaluations', {
      state: {
        itemId: itemId,
        itemTitle: itemName,
        itemType: itemType,
      },
    });
  };

  const maxListHeight = listItemHeight * visibleItemCount;
  const notificationsCount = notifications ? notifications.length : 0;
  const desiredListHeight = notificationsCount * listItemHeight;
  const listHeight =
    desiredListHeight > maxListHeight ? maxListHeight : desiredListHeight;

  const onListScroll: React.UIEventHandler<HTMLElement> = (
    e: React.UIEvent<HTMLElement, UIEvent>,
  ) => {
    if (
      e.currentTarget.scrollHeight - e.currentTarget.scrollTop !==
      listHeight
    ) {
      return;
    }

    if (hasMoreNotifications) {
      dispatch(
        asyncActions.GET_NOTIFICATIONS({
          language: applicationLanguage,
          page: currentNotificationPage + 1,
          size: maxItemCount,
        }),
      );
    }
  };

  const isNotificationsEmpty = !notifications || !notifications.length;
  const unreadNotifications =
    notifications && notifications.some((notification) => !notification.viewed);

  return (
    <Space direction={'vertical'}>
      {askToUpdate && (
        <Alert
          style={{ marginTop: 14 }}
          type="warning"
          showIcon
          message={
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>{t('notification-list-new-notifications')}</span>
              <Link
                onClick={() => {
                  dispatch(
                    NotificationsListSlice.actions.SET_ASK_TO_UPDATE(false),
                  );
                  dispatch(
                    NotificationsListSlice.actions.SET_SHOULD_UPDATE(true),
                  );
                }}
              >
                {t('notification-list-new-notifications-update-button')}
              </Link>
            </div>
          }
        />
      )}

      {isNotificationsEmpty && (
        <Empty
          className="notifications-no-data"
          description={t('notification-list-no-content-description')}
        />
      )}

      {!isNotificationsEmpty && (
        <>
          <div
            className="notifications-delete-all-button"
            style={{ marginTop: isModal ? '0' : '20px' }}
          >
            <Space direction="horizontal" size="small">
              {unreadNotifications && (
                <>
                  <Link
                    onClick={() => {
                      markAllNotificationsAsRead();
                    }}
                  >
                    {t('notification-list-mark-all-read-button')}
                  </Link>
                  <Divider type="vertical" />
                </>
              )}
              <Link
                onClick={() => {
                  deleteAllNotifications();
                }}
              >
                {t('notification-list-delete-all-button')}
              </Link>
            </Space>
          </div>
          <Spin
            tip={t('notification-list-loading-description')}
            spinning={isLoading}
          >
            <ul style={{ padding: 0 }}>
              <List className="notifications-list">
                <VirtualList
                  data={notifications}
                  itemKey="id"
                  onScroll={onListScroll}
                  height={listHeight}
                >
                  {(item: any) => (
                    <List.Item key={item.id}>
                      <Row className="notifications-list-item" align="middle">
                        <Col
                          span={3}
                          className="notifications-list-item-icon"
                          onClick={() => {
                            onNotificationClick(
                              item.id,
                              item.notificationType,
                              item.viewed,
                              item.item.identifier,
                              item.item.type,
                              item.item.name,
                            );
                          }}
                        >
                          <span
                            style={{
                              fontWeight: item.viewed ? 'normal' : 'bold',
                            }}
                          >
                            {item.notificationType ===
                            NotificationType.Created ? (
                              <AppstoreAddOutlined />
                            ) : item.notificationType ===
                              NotificationType.Edited ? (
                              <EditOutlined />
                            ) : (
                              <DeleteOutlined />
                            )}
                          </span>
                        </Col>
                        <Col
                          span={19}
                          onClick={() => {
                            onNotificationClick(
                              item.id,
                              item.notificationType,
                              item.viewed,
                              item.item.identifier,
                              item.item.type,
                              item.item.name,
                            );
                          }}
                        >
                          <Row justify="start">
                            <Col
                              span={24}
                              className="notifications-list-item-content"
                            >
                              <span
                                style={{
                                  fontWeight: item.viewed ? 'normal' : 'bold',
                                }}
                              >
                                {item.notificationType ===
                                NotificationType.Created
                                  ? t('notification-list-new-evaluation')
                                  : item.notificationType ===
                                      NotificationType.Edited
                                    ? t('notification-list-edited-evaluation')
                                    : t(
                                        'notification-list-deleted-evaluation',
                                      )}{' '}
                                {item.item.name}
                              </span>
                            </Col>
                            <Col
                              span={24}
                              className="notifications-list-item-content"
                            >
                              <span
                                style={{
                                  fontWeight: item.viewed ? 'normal' : 'bold',
                                }}
                              >
                                {(item.notificationType ===
                                  NotificationType.Created ||
                                  item.notificationType ===
                                    NotificationType.Edited) &&
                                  (item.liked ? (
                                    <LikeOutlined />
                                  ) : (
                                    <DislikeOutlined />
                                  ))}
                                {(item.notificationType ===
                                  NotificationType.Created ||
                                  item.notificationType ===
                                    NotificationType.Edited) &&
                                  ' '}
                                {t('notification-list-by')} {item.user.name}
                              </span>
                            </Col>
                          </Row>
                        </Col>
                        <Col
                          span={2}
                          className="notifications-list-item-icon-close"
                        >
                          {item.deleteStatus ===
                            NotificationDeleteStatus.Pending && (
                            <Spin size={'small'} />
                          )}
                          {item.deleteStatus ===
                            NotificationDeleteStatus.Error && (
                            <Tooltip
                              placement="rightBottom"
                              title={t(
                                'notification-list-error-deleting-notification',
                              )}
                            >
                              <ExclamationCircleOutlined />
                            </Tooltip>
                          )}
                          {item.deleteStatus !==
                            NotificationDeleteStatus.Pending &&
                            item.deleteStatus !==
                              NotificationDeleteStatus.Error && (
                              <CloseOutlined
                                onClick={() => {
                                  deleteNotification(item.id);
                                }}
                              />
                            )}
                        </Col>
                      </Row>
                    </List.Item>
                  )}
                </VirtualList>
              </List>
            </ul>
          </Spin>
        </>
      )}
    </Space>
  );
};
