import {
  Button,
  Col,
  Empty,
  Form,
  Input,
  Modal,
  Result,
  Row,
  Select,
  Space,
  Spin,
  Tag,
  Tooltip,
  Tree,
  TreeSelect,
} from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FormInstance } from 'antd/lib/form';
import uniqid from 'uniqid';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as RedBar } from '../../../assets/red_bar_icon.svg';
import { ReactComponent as VerticalRedBar } from '../../../assets/vertical_red_bar.svg';
import { ReactComponent as ReturnSymbol } from '../../../assets/return_symbol.svg';
import { ReactComponent as EditIcon } from '../../../assets/edit_symbol_white.svg';
import { AppDispatch, RootState, store } from '../../../store/store';

import {
  asyncPageActions,
  CreateReadEditPageSlice,
  ITag,
  ITagTreeNode,
  ITagValueMap,
  PageLanguage,
  PageMode,
} from './CreateReadEditPage.Slice';

import { messageNotification } from '../../../helpers/messageNotification/messageNotification';
import { showModal } from '../../../helpers/showModal/showModal';

import './CreateReadEditPage.scss';
import {
  IPageBackendDTO,
  IPageResponseContents,
} from '../../../services/createReadEditPage.api';
import { PageForm } from './PageForm/PageForm';
import { PageReactQuillWrapper } from './PageReactQuillWrapper/PageReactQuillWrapper';
import {
  CloseOutlined,
  DislikeTwoTone,
  ExclamationCircleOutlined,
  InfoCircleOutlined,
  LikeTwoTone,
  MenuOutlined,
  PlusOutlined,
  StarFilled,
} from '@ant-design/icons';
import { mergeArrays } from '../../../helpers/mergeArrays/mergeArrays';
import Link from 'antd/lib/typography/Link';
import { EvaluationModal } from '../../../components/EvaluationModal/EvaluationModal';

// This conversion is necessary because the backend expects the full name of the language in uppercase, while the navbar stores the current language as an abbreviation.
const languageMap: { [key: string]: string } = {
  en: PageLanguage.English,
  es: PageLanguage.Spanish,
  pt: PageLanguage.Portuguese,
};

export const CreateReadEditPagesCreatePages: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { slug } = useParams();

  const location = useLocation();
  const pageMode = location.state?.mode ? location.state.mode : PageMode.Read;
  const pageId = location.state?.id;

  const languagesState = useSelector(
    (state: RootState) => state.createReadEditPageSlice.languages,
  );
  const pageModeState = useSelector(
    (state: RootState) => state.createReadEditPageSlice.pageMode,
  );
  const pageSlug = useSelector(
    (state: RootState) => state.createReadEditPageSlice.pageSlug,
  );
  const pagesState = useSelector(
    (state: RootState) => state.createReadEditPageSlice.pages,
  );
  const pageResponseState = useSelector(
    (state: RootState) => state.createReadEditPageSlice.pageObjectResponse,
  );
  const pageResponseLoadingState = useSelector(
    (state: RootState) => state.createReadEditPageSlice.pageResponseLoading,
  );
  const pageRejectedState = useSelector(
    (state: RootState) => state.createReadEditPageSlice.pageRejected,
  );
  const pageTags = useSelector(
    (state: RootState) => state.createReadEditPageSlice.tags,
  );
  const pageObjectResponse = useSelector(
    (state: RootState) => state.createReadEditPageSlice.pageObjectResponse,
  );
  const loadedToUpdate = useSelector(
    (state: RootState) => state.createReadEditPageSlice.loadedToUpdate,
  );
  const pageLoaded = useSelector(
    (state: RootState) => state.createReadEditPageSlice.pageLoaded,
  );
  const pageToEvaluate = useSelector(
    (state: RootState) => state.createReadEditPageSlice.pageToEvaluate,
  );
  const openEvaluationModal = useSelector(
    (state: RootState) => state.createReadEditPageSlice.openEvaluationModal,
  );
  const openEditEvaluationModal = useSelector(
    (state: RootState) => state.createReadEditPageSlice.openEditEvaluationModal,
  );
  const shouldRefresh = useSelector(
    (state: RootState) => state.createReadEditPageSlice.shouldUpdate,
  );
  const currentEvaluation = useSelector(
    (state: RootState) =>
      state.createReadEditPageSlice.pageObjectResponse?.pageReview,
  );
  const pageEvaluationsList = useSelector(
    (state: RootState) => state.createReadEditPageSlice.pageEvaluationsList,
  );

  const [evaluationRadioSelected, setEvaluationRadioSelected] = useState<
    boolean | null
  >(currentEvaluation ? currentEvaluation.liked : null);

  const loginState = useSelector((state: RootState) => state.login);
  const navbarState = useSelector((state: RootState) => state.navBar);

  const isUserAdmin = loginState.user?.role === 'A';
  const userId = loginState.user?.id;
  const pageIdToEvaluate = pageObjectResponse?.id;

  const [tagValueMap, setTagValueMap] = useState<{
    [key: string]: ITagValueMap;
  }>({});

  const slugInputRef = useRef(null);
  const formRef = useRef(null);

  useEffect(() => {
    return () => {
      dispatch(
        CreateReadEditPageSlice.actions.BACK_TO_ORIGINAL_STATE_PERSISTING_ID_FROM_UPDATED_PAGE(),
      );
    };
  }, [dispatch]);

  // Get a list of classifications once
  useEffect(() => {
    dispatch(asyncPageActions.GET_ALL_CLASSIFICATIONS());
  }, []);

  // Set tags on existing page load
  useEffect(() => {
    if (
      (!loadedToUpdate && !pageLoaded) ||
      pageTags.tags.length ||
      pageObjectResponse === undefined ||
      pageTags.loadedPageTags ||
      !pageTags.classifications ||
      !pageTags.classifications.length ||
      Object.keys(tagValueMap).length === 0
    ) {
      return;
    }

    dispatch(CreateReadEditPageSlice.actions.MAP_CLASSIFICATIONS_TAGS());

    setTags(
      sortTagsByLevel(
        pageObjectResponse.classifications.map((classification) => {
          return classification.id.toString();
        }),
      ),
    );

    dispatch(CreateReadEditPageSlice.actions.SET_LOADED_PAGE_TAGS(true));
  });

  // Update tag value map when data is changed
  useEffect(() => {
    if (pageTags.treeData) {
      populateTagValueMap(pageTags.treeData);
    }
  }, [pageTags.treeData]);

  const populateTagValueMap = (
    list: ITagTreeNode[],
    parent: ITagValueMap | null = null,
    level: number = 0,
  ): ITagValueMap[] => {
    return (list || []).map(({ children, value, title }) => {
      const node: ITagValueMap = {
        parent,
        title,
        level,
        value,
        children: [],
      };
      setTagValueMap((previousState) => {
        const data = { ...previousState };
        data[value] = { ...node };
        return data;
      });
      node.children = populateTagValueMap(children || [], node, level + 1);
      return node;
    });
  };

  const getPath = (value: string): string[] => {
    const path: string[] = [];
    let current: ITagValueMap | null = tagValueMap[value];
    while (current) {
      path.unshift(current.value);
      current = current.parent;
    }
    return path;
  };

  const sortTagsByLevel = (items: string[]): string[] => {
    const itemsToSort: string[] = [...items];
    itemsToSort.sort((a, b) => {
      return tagValueMap[a].level - tagValueMap[b].level;
    });

    return itemsToSort;
  };

  const setTags = (tags: string[], shouldUpdate: boolean = true) => {
    const data: ITag[] | undefined = tags.map((node) => {
      return {
        title: tagValueMap[node].title,
        level: tagValueMap[node].level,
        value: tagValueMap[node].value,
      };
    });
    dispatch(CreateReadEditPageSlice.actions.SET_TAGS(data));
    dispatch(
      CreateReadEditPageSlice.actions.SET_SHOULD_UPDATE_TAGS(shouldUpdate),
    );
  };

  useEffect(() => {
    if (!pageTags.shouldUpdateTags) {
      return;
    }

    const newValues: string[] = [];
    pageTags.tags.map((item) => {
      const path = getPath(item.value);
      if (
        path.every((i) =>
          pageTags.tags.some((tag) => {
            return i === tag.value;
          }),
        )
      ) {
        newValues.push(item.value);
      }
    });

    dispatch(
      CreateReadEditPageSlice.actions.SET_TAGS_SELECTED_TREE_NODES(
        sortTagsByLevel(newValues),
      ),
    );
    setTags(newValues, false);
  }, [pageTags.tags]);

  // Hook to check if the page mode is valid
  useEffect(() => {
    if (
      pageMode !== PageMode.Create &&
      pageMode !== PageMode.Edit &&
      pageMode !== PageMode.Read
    ) {
      navigate('created-pages');
    }

    dispatch(CreateReadEditPageSlice.actions.PAGE_SET_PAGE_MODE(pageMode));
  }, [dispatch, history, pageMode]);

  useEffect(() => {
    if (!pageModeState) {
      return;
    }

    if (pageModeState !== PageMode.Create) {
      if (!slug) {
        return;
      }

      dispatch(CreateReadEditPageSlice.actions.PAGE_SET_SLUG(slug));

      if (pageModeState === PageMode.Edit) {
        if (!pageId) {
          return;
        }

        dispatch(asyncPageActions.GET_PAGE_TO_UPDATE(pageId));
        return;
      }

      if (loginState.loggedIn) {
        dispatch(
          asyncPageActions.GET_PAGE_BY_SLUG_AUTHENTICATED({
            slug,
          }),
        );
      } else {
        dispatch(
          asyncPageActions.GET_PAGE_BY_SLUG({
            slug,
          }),
        );
      }
    }
  }, [pageModeState, dispatch, slug, history, loginState.loggedIn]);

  useEffect(() => {
    if (!pageRejectedState) {
      return;
    }

    navigate('/created-pages');
  }, [pageRejectedState, history]);

  // Hook to define the page content based on the language selected in the navbar
  useEffect(() => {
    if (
      !navbarState.loadedDefaultLanguage ||
      !pageResponseState ||
      (pageMode !== PageMode.Read && pagesState.currentLanguage)
    ) {
      return;
    }

    const userLanguage = languageMap[navbarState.defaultLanguage];

    // Define the page content according to the user's default language, if it exists
    const isContentAvailableInUserLanguage =
      pageResponseState.pageContents.find((x) => x.language === userLanguage);

    if (isContentAvailableInUserLanguage) {
      dispatch(
        CreateReadEditPageSlice.actions.SET_CURRENT_LANGUAGE(userLanguage),
      );
      dispatch(
        CreateReadEditPageSlice.actions.SET_PAGE_TO_EVALUATION(
          isContentAvailableInUserLanguage,
        ),
      );
      return;
    }

    // If the page content does not exist in the user's language, select the default page content
    const defaultPageLanguage = pageResponseState.defaultLanguage;
    if (defaultPageLanguage) {
      const isContentAvailableInDefaultLanguage =
        pageResponseState.pageContents.find(
          (x) => x.language === defaultPageLanguage,
        );

      if (isContentAvailableInDefaultLanguage) {
        dispatch(
          CreateReadEditPageSlice.actions.SET_CURRENT_LANGUAGE(
            defaultPageLanguage,
          ),
        );
        dispatch(
          CreateReadEditPageSlice.actions.SET_PAGE_TO_EVALUATION(
            isContentAvailableInDefaultLanguage,
          ),
        );
        return;
      }
    }

    // If there's no default language selected (i.e., only one language available), select the remaining content as fallback
    const fallbackContent = pageResponseState.pageContents[0].language;
    dispatch(
      CreateReadEditPageSlice.actions.SET_CURRENT_LANGUAGE(fallbackContent),
    );
    dispatch(
      CreateReadEditPageSlice.actions.SET_PAGE_TO_EVALUATION(
        pageResponseState.pageContents[0],
      ),
    );
  }, [
    navbarState.defaultLanguage,
    navbarState.loadedDefaultLanguage,
    pagesState.currentLanguage,
    pageResponseState,
    pageModeState,
    dispatch,
  ]);

  useEffect(() => {
    if (!pagesState.finishedCreatingPage || pageMode === PageMode.Read) {
      return;
    }

    if (pageMode === PageMode.Edit) {
      messageNotification.successMessage(
        t('page-edit-success-notification-title'),
        t('page-edit-success-notification-content'),
      );
    } else if (pageMode === PageMode.Create) {
      messageNotification.successMessage(
        t('page-create-success-notification-title'),
        t('page-create-success-notification-content'),
      );
    }

    dispatch(CreateReadEditPageSlice.actions.FINISH_CREATING_PAGE());

    navigate(`/pages/${pageSlug}`);
  });

  const pageHasContent = useCallback(() => {
    return pagesState.pages.some(
      (page) => page.title || page.description || page.contents,
    );
  }, [pageSlug, pagesState.pages]);

  const askToUserIfThePageHasBeenUpdatedInEveryLanguage = useCallback(() => {
    const changesInLanguages: Array<boolean> = [];

    pagesState.pages.map((page) => {
      if (!page.title) {
        return;
      }

      const pageInResponse = pageResponseState?.pageContents.find(
        (x) => x.language === page.language,
      );
      if (!pageInResponse) {
        return;
      }

      changesInLanguages.push(
        pageInResponse.title === page.title &&
          pageInResponse.subtitle === page.description &&
          pageInResponse.mainContent === page.contents,
      );
    });

    const everyPageWasChanged = changesInLanguages.every((x) => x);
    const everyPageWasNotChanged = changesInLanguages.every((x) => !x);

    return everyPageWasChanged || everyPageWasNotChanged;
  }, [pageResponseState, pagesState]);

  useEffect(() => {
    if (
      !pagesState.startPageValidation ||
      languagesState.pendingDefaultPageLanguageSelection
    ) {
      return;
    }

    if (!pageHasContent()) {
      dispatch(CreateReadEditPageSlice.actions.STOP_PAGE_VALIDATING());
      return messageNotification.warningMessage(
        t('page-create-error-notification-title'),
        t('page-create-error-notification-content'),
      );
    }

    const pagesValidationStatus = pagesState.pages.map((page) => {
      if (page.isValid) {
        return true;
      }

      messageNotification.warningMessage(
        t(
          `page-create-error-invalid-${page.language.toLowerCase()}-content-notification-title`,
        ),
        t('page-create-error-invalid-page-content-notification-content'),
      );
      return false;
    });

    if (pagesValidationStatus.some((validationStatus) => !validationStatus)) {
      dispatch(CreateReadEditPageSlice.actions.STOP_PAGE_VALIDATING());
      return;
    }

    if (!pageSlug) {
      dispatch(CreateReadEditPageSlice.actions.STOP_PAGE_VALIDATING());
      return messageNotification.warningMessage(
        t('page-create-error-invalid-slug-notification-title'),
        t('page-create-error-invalid-slug-notification-content'),
      );
    }

    const pageContents: Array<IPageResponseContents> = [];

    pagesState.pages.map((page) => {
      if (!page.title && !page.description && !page.contents) {
        return;
      }

      pageContents.push({
        title: page.title,
        subtitle: page.description,
        mainContent: page.contents,
        language: page.language,
      } as IPageResponseContents);
    });

    if (
      pageContents.length > 1 &&
      pageContents.length < Object.keys(PageLanguage).length &&
      !languagesState.defaultPageLanguage
    ) {
      dispatch(
        CreateReadEditPageSlice.actions.SET_PENDING_DEFAULT_PAGE_LANGUAGE_SELECTION(
          true,
        ),
      );
      dispatch(
        CreateReadEditPageSlice.actions.OPEN_SELECT_DEFAULT_PAGE_LANGUAGE_MODAL(),
      );
      return;
    }

    const newPageOrNewUpdatedPage = {
      ...pageObjectResponse,
      slug: pageSlug,
      active: true,
      pageContents: pageContents,
      classifications: pageTags.tags.map((tag) => {
        return { id: +tag.value };
      }),
    } as IPageBackendDTO;

    if (languagesState.defaultPageLanguage) {
      newPageOrNewUpdatedPage.defaultLanguage =
        languagesState.defaultPageLanguage;
    }

    if (pageModeState === PageMode.Edit) {
      const pageWasCorrectlyUpdated =
        askToUserIfThePageHasBeenUpdatedInEveryLanguage();

      if (pageWasCorrectlyUpdated) {
        dispatch(
          asyncPageActions.UPDATE_A_PAGE({
            id: pageResponseState?.id,
            payload: newPageOrNewUpdatedPage,
          }),
        );
        return;
      }

      showModal(
        'warning',
        t('page-edit-not-made-in-all-languages-title'),
        t('page-edit-not-made-in-all-languages-content'),
        () => {
          dispatch(
            asyncPageActions.UPDATE_A_PAGE({
              id: pageResponseState?.id,
              payload: newPageOrNewUpdatedPage,
            }),
          );
        },
        () => {
          dispatch(CreateReadEditPageSlice.actions.STOP_PAGE_VALIDATING());
        },
        `${t('page-edit-okText')}`,
        `${t('page-edit-cancelText')}`,
      );
      return;
    }

    dispatch(asyncPageActions.CREATE_A_NEW_PAGE(newPageOrNewUpdatedPage));
  }, [
    pageSlug,
    pagesState,
    pageModeState,
    pageResponseState,
    t,
    dispatch,
    pageHasContent,
    askToUserIfThePageHasBeenUpdatedInEveryLanguage,
    languagesState.pendingDefaultPageLanguageSelection,
  ]);

  useEffect(() => {
    if (shouldRefresh) {
      dispatch(
        asyncPageActions.GET_PAGE_BY_SLUG_AUTHENTICATED({
          slug,
        }),
      );
    }
    dispatch(CreateReadEditPageSlice.actions.RESET_SHOULD_UPDATE());
  }, [shouldRefresh]);

  const setHeaderTitle = () => {
    if (pageModeState === PageMode.Read) {
      return <Row className="empty-header-row-style" />;
    }

    if (pageModeState === PageMode.Edit) {
      return (
        <Row className="header-row-style">
          <Col span={24}>
            <RedBar />
            <h1 className="header-title">
              <strong> {t('page-edit-button-label')}</strong>
            </h1>
          </Col>
        </Row>
      );
    }

    if (pageModeState === PageMode.Create) {
      return (
        <Row className="header-row-style">
          <Col span={24}>
            <RedBar />
            <h1 className="header-title">
              <strong>{t('create-page-header-documentation-title')}</strong>
            </h1>
          </Col>
        </Row>
      );
    }
  };

  const showPublishCancelButtons = () => {
    if (pageModeState === PageMode.Read || !pagesState.pages.length) {
      return;
    }

    return (
      <Row className="publish-edit-cancel-buttons">
        <Col span={24}>
          <Row className="publish-buttons-row-style">
            <Col span={24}>
              <Form.Item>
                <Button
                  id="add-language-button"
                  type="primary"
                  shape="round"
                  className="button-style"
                  ghost
                  disabled={pagesState.pages.length >= 3}
                  onClick={() => {
                    if (pagesState.currentLanguage) {
                      saveCurrentPageContent(pagesState.currentLanguage);
                    }
                    dispatch(
                      CreateReadEditPageSlice.actions.OPEN_ADD_LANGUAGE_MODAL(),
                    );
                  }}
                >
                  {t('create-page-add-language-button')}
                </Button>
              </Form.Item>
            </Col>
          </Row>
          <Row className="publish-buttons-row-style">
            <Col span={24}>
              <Form.Item>
                <Button
                  id="publish-button"
                  type="primary"
                  shape="round"
                  className="button-style"
                  htmlType="submit"
                >
                  {t('page-send-button')}
                </Button>
              </Form.Item>
            </Col>
          </Row>
          <Row className="button-row-style">
            <Col span={24}>
              <Form.Item>
                <Button
                  id="cancel-button"
                  type="link"
                  shape="round"
                  className="button-style"
                  onClick={() => {
                    returnOrShowModalOfDiscardChanges();
                  }}
                >
                  {pageModeState === PageMode.Create
                    ? t('create-page-cancel-creation-button')
                    : t('create-page-cancel-edit-button')}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  const showEditButton = () => {
    if (loginState.loggedIn && isUserAdmin && pageModeState === PageMode.Read) {
      return (
        <Row className="publish-edit-cancel-buttons">
          <Col span={24}>
            <Row className="publish-edit-buttons-row-style">
              <Col span={24}>
                <Form.Item>
                  <Button
                    id="edit-button"
                    type="primary"
                    shape="round"
                    className="button-style"
                    onClick={() => {
                      navigate(`/pages/${pageResponseState?.slug}`, {
                        state: {
                          mode: PageMode.Edit,
                          id: pageResponseState?.id,
                        },
                      });
                    }}
                  >
                    <EditIcon className="edit-icon" />
                    {t('page-edit-button-label')}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      );
    }
  };

  const goToEvaluations = (evaluatedItem: any) => {
    navigate('/evaluations', {
      state: {
        itemId: pageIdToEvaluate,
        itemTitle: evaluatedItem.title,
        itemType: 'PAGE',
      },
    });
  };

  const showGoToEvaluationsButton = () => {
    if (isUserAdmin) {
      const hasEvaluations =
        pageEvaluationsList && pageEvaluationsList?.length > 0;

      if (pageMode !== PageMode.Read) {
        return;
      }

      if (pageToEvaluate && hasEvaluations) {
        return (
          <Row style={{ marginTop: '2rem' }} justify="center" align="middle">
            <Button
              type="primary"
              shape="round"
              ghost
              onClick={() => goToEvaluations(pageToEvaluate)}
              className="button-style"
            >
              {t('page-evaluation-button-label')}
            </Button>
          </Row>
        );
      }

      return (
        <Col style={{ marginTop: '2rem' }}>
          <Row style={{ fontWeight: 'bold' }}>
            {t('page-evaluation-evaluations-label')}
          </Row>
          <Row justify="center" align="middle">
            {t('page-evaluation-not-evaluated-yet-label')}
          </Row>
        </Col>
      );
    }
  };

  const submitNewPage = () => {
    const formInstance = formRef.current as unknown as FormInstance;

    if (formInstance) {
      const values = formInstance.getFieldsValue();

      // Remove spaces and accents from slug
      const formattedSlug = (slugInputRef.current as any).input.value
        .trim()
        .replaceAll(' ', '-')
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');

      dispatch(
        CreateReadEditPageSlice.actions.UPDATE_PAGE_STATE_AND_START_VALIDATION({
          pageTitle: values.title.trim(),
          pageDescription: values.description.trim(),
          pageSlug: formattedSlug,
        }),
      );
    }
  };

  const verifyLanguageContentExists = (language: PageLanguage) => {
    const formInstance = formRef.current as unknown as FormInstance;
    if (!formInstance) {
      return;
    }

    const formValues = formInstance.getFieldsValue();
    const editorContent =
      store.getState().createReadEditPageSlice.editorOutputOnEditing;

    if (
      pagesState.currentLanguage === language &&
      (formValues.title.trim() ||
        formValues.description.trim() ||
        editorContent)
    ) {
      return true;
    }

    const page = pagesState.pages.find(
      (element) => element.language === language,
    );
    return page && (page.title || page.description || page.contents);
  };

  const returnOrShowModalOfDiscardChanges = () => {
    if (!pagesState.pages.length) {
      return navigate(store.getState().pathTracker.pagesPreviousPath);
    }

    const pageContents = pagesState;

    const formInstance = formRef.current as unknown as FormInstance;
    if (!formInstance) {
      return;
    }

    const formValues = formInstance.getFieldsValue();
    const editorContent =
      store.getState().createReadEditPageSlice.editorOutputOnEditing;

    if (pageModeState === PageMode.Read) {
      return navigate(store.getState().pathTracker.pagesPreviousPath);
    }

    if (pageModeState === PageMode.Create) {
      if (
        formValues.title.trim() ||
        formValues.description.trim() ||
        editorContent ||
        pageContents.pages.some(
          (page) => page.title || page.description || page.contents,
        )
      ) {
        return showModal(
          'warning',
          t('page-confirmation-cancel-editing-title'),
          t('page-confirmation-cancel-editing-content'),
          () => navigate(store.getState().pathTracker.pagesPreviousPath),
        );
      }

      return navigate(store.getState().pathTracker.pagesPreviousPath);
    }

    const allPages = pagesState.pages.map((page) => {
      if (page.language !== pagesState.currentLanguage) {
        return page;
      }

      const newPage = {
        ...page,
        title: formValues.title.trim(),
        description: formValues.description.trim(),
      };

      if (editorContent) {
        newPage.contents = editorContent;
      }

      return newPage;
    });

    let showModalValidation = false;

    pageResponseState?.pageContents.map((pageResponse) => {
      const page = allPages.find(
        (element) => element.language === pageResponse.language,
      );
      if (!page) {
        return;
      }

      if (
        pageResponse.title !== page.title ||
        pageResponse.subtitle !== page.description ||
        pageResponse.mainContent !== page.contents
      ) {
        showModalValidation = true;
      }
    });

    allPages.map((page) => {
      const pageResponse = pageResponseState?.pageContents.find(
        (element) => element.language === page.language,
      );
      if (pageResponse) {
        return;
      }

      if (page.title || page.description || page.contents) {
        showModalValidation = true;
      }
    });

    if (showModalValidation) {
      return showModal(
        'warning',
        t('page-confirmation-cancel-editing-title'),
        t('page-confirmation-cancel-editing-content'),
        () => {
          dispatch(
            CreateReadEditPageSlice.actions.BACK_TO_ORIGINAL_STATE_PERSISTING_ID_FROM_UPDATED_PAGE(),
          );
          navigate(store.getState().pathTracker.pagesPreviousPath);
        },
      );
    }

    dispatch(
      CreateReadEditPageSlice.actions.BACK_TO_ORIGINAL_STATE_PERSISTING_ID_FROM_UPDATED_PAGE(),
    );
    navigate(store.getState().pathTracker.pagesPreviousPath);
  };

  const saveCurrentPageContent = (
    language: PageLanguage | undefined = undefined,
  ) => {
    const formInstance = formRef.current as unknown as FormInstance;
    let existingValuesInForm = undefined;

    if (formInstance) {
      const values = formInstance.getFieldsValue();
      existingValuesInForm = {
        language: language,
        pageSlug: (slugInputRef.current as any).input.value,
        pageTitle: values.title,
        pageDescription: values.description,
      };
    }

    dispatch(
      CreateReadEditPageSlice.actions.SAVE_PAGE_CONTENT(existingValuesInForm),
    );

    return formInstance;
  };

  const changeLanguage = (newLanguage: string) => {
    const previousLanguage = pagesState.currentLanguage;
    if (!previousLanguage || previousLanguage === newLanguage) {
      return;
    }

    const formInstance = saveCurrentPageContent(previousLanguage);
    if (!formInstance) {
      return;
    }

    dispatch(CreateReadEditPageSlice.actions.SET_NEW_LANGUAGE(newLanguage));

    if (previousLanguage !== newLanguage) {
      const pageContents = pagesState.pages.find(
        (page) => page.language === newLanguage,
      );
      if (!pageContents) {
        return;
      }

      formInstance.setFieldsValue({
        title: pageContents.title,
        description: pageContents.description,
      });
    }
  };

  const deleteLanguage = (language: PageLanguage) => {
    // Don't allow to remove all the languages when editing an already created page.
    if (pageModeState === PageMode.Edit && pagesState.pages.length <= 1) {
      Modal.warning({
        title: t(
          'create-page-delete-last-language-existing-page-warning-title',
        ),
        content: (
          <>
            <p>
              {t(
                'create-page-delete-last-language-existing-page-warning-description-first-paragraph',
              )}
            </p>
            <p>
              {t(
                'create-page-delete-last-language-existing-page-warning-description-second-paragraph',
              )}
            </p>
          </>
        ),
        centered: true,
      });
      return;
    }

    if (!verifyLanguageContentExists(language)) {
      dispatch(CreateReadEditPageSlice.actions.DELETE_LANGUAGE(language));
    } else {
      Modal.confirm({
        title: t('create-page-delete-language-modal-title'),
        icon: <ExclamationCircleOutlined />,
        content: (
          <>
            <p>{t('create-page-delete-language-modal-first-paragraph')}</p>
            <p>{t('create-page-delete-language-modal-second-paragraph')}</p>
          </>
        ),
        okText: t('create-page-delete-language-ok-button'),
        cancelText: t('create-page-delete-language-cancel-button'),
        centered: true,
        onOk: () => {
          dispatch(CreateReadEditPageSlice.actions.DELETE_LANGUAGE(language));
        },
      });
    }
  };

  const showSlug = () => {
    const readonly = pageModeState === PageMode.Read;

    return (
      <Row className="language-row-style">
        <Col span={24}>
          <Row align="bottom">
            <Space>
              <b>{t('page-slug-input-label')}</b>
              <small>{t('page-slug-input-label-description')}</small>
            </Space>
          </Row>
          <Row align="bottom" className="dropdown-language">
            <Col className="vertical-red-bar">
              <VerticalRedBar />
            </Col>
            <Col span={21} offset={1}>
              <Input
                id="page-slug-input"
                ref={slugInputRef}
                maxLength={50}
                defaultValue={pageSlug}
                className={
                  readonly ? 'description-input-read-only' : 'description-input'
                }
                readOnly={readonly}
                bordered={!readonly}
                placeholder={t('page-slug-input-placeholder')}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  const showLanguagesPanel = () => {
    if (pageModeState === PageMode.Read || !pagesState.pages.length) {
      return;
    }

    return (
      <>
        <Row className="language-row-style">
          <Col span={24}>
            <Row align="bottom">
              <Col>
                <b>{t('page-languages-label')}</b>
              </Col>
            </Row>
            <Row align="bottom" className="dropdown-language">
              <Col className="vertical-red-bar">
                <VerticalRedBar />
              </Col>
              <Col span={21} offset={1}>
                <Space size={1}>
                  {pagesState.pages.map((page) => {
                    const isCurrentLanguage =
                      pagesState.currentLanguage === page.language;
                    return (
                      <Tag
                        key={`lang-tag-${page.language}`}
                        color={isCurrentLanguage ? 'geekblue' : undefined}
                      >
                        <Space>
                          {pageResponseState?.defaultLanguage ===
                            page.language && (
                            <Tooltip
                              placement="bottomLeft"
                              title={t(
                                'create-page-default-langauge-icon-tooltip',
                              )}
                            >
                              <StarFilled />
                            </Tooltip>
                          )}
                          <Link
                            onClick={() => {
                              changeLanguage(page.language);
                            }}
                            style={{
                              fontWeight: isCurrentLanguage ? 'bold' : '',
                              color: isCurrentLanguage ? '#1d39c4' : '',
                            }}
                          >
                            {t(
                              `create-page-language-tag-${page.language.toLowerCase()}`,
                            )}
                          </Link>
                          <CloseOutlined
                            className="language-tag-close-button"
                            onClick={() => {
                              deleteLanguage(page.language);
                            }}
                          />
                        </Space>
                      </Tag>
                    );
                  })}
                </Space>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  };

  const buildTagViewTree = () => {
    const filterTagTreeNodes = (nodes: ITagTreeNode[], idArray: ITag[]) => {
      return nodes.reduce((acc: any, node: ITagTreeNode) => {
        const hasMatchingID = idArray.some(
          (idObj: ITag) => idObj.value === node.value,
        );
        const filteredChildren = node.children
          ? filterTagTreeNodes(node.children, idArray)
          : [];

        if (hasMatchingID || filteredChildren.length > 0) {
          const { value, ...rest } = node;
          acc.push({ ...rest, key: value, children: filteredChildren });
        }

        return acc;
      }, []);
    };

    if (!pageTags.treeData || !pageTags.tags.length) {
      return (
        <Result
          status="error"
          title={t('page-tags-tree-view-modal-error-title')}
          subTitle={t('page-tags-tree-view-modal-error-description')}
        />
      );
    }

    return (
      <Tree
        selectable={false}
        height={500}
        defaultExpandAll
        showLine
        treeData={filterTagTreeNodes(pageTags.treeData, pageTags.tags)}
      />
    );
  };

  const showTags = () => {
    const readonly = pageModeState === PageMode.Read;

    if ((!pageTags.tags || !pageTags.tags.length) && readonly) {
      return;
    }

    const addRemoveTagButton = !pageTags.tags.length ? (
      <>
        <PlusOutlined /> {t('page-tags-add-tag-button')}
      </>
    ) : (
      <>
        <MenuOutlined /> {t('page-tags-add-remove-tag-button')}
      </>
    );

    return (
      <>
        <Row>
          <Col>
            <Space>
              <b>{t('page-tags-title')}</b>
              <Tooltip
                placement="bottom"
                title={
                  <>
                    <Space direction="vertical" size="small">
                      {t('page-tags-info-title')}
                      <Space align="center">
                        <span
                          className="tagColorDot"
                          style={{ backgroundColor: '#ffd591' }}
                        ></span>
                        {`${t('page-tags-info-level')} 1`}
                      </Space>
                      <Space align="center">
                        <span
                          className="tagColorDot"
                          style={{ backgroundColor: '#87e8de' }}
                        ></span>
                        {`${t('page-tags-info-level')} 2`}
                      </Space>
                      <Space align="center">
                        <span
                          className="tagColorDot"
                          style={{ backgroundColor: '#adc6ff' }}
                        ></span>
                        {`${t('page-tags-info-level')} 3`}
                      </Space>
                    </Space>
                  </>
                }
              >
                <InfoCircleOutlined />
              </Tooltip>
              {readonly && (
                <Button
                  shape="round"
                  className="tree-view-button"
                  type="primary"
                  ghost
                  onClick={() => {
                    Modal.info({
                      title: t('page-tags-tree-view-modal-title'),
                      content: buildTagViewTree(),
                      centered: true,
                    });
                  }}
                >
                  {t('page-tags-tree-view-button')}
                </Button>
              )}
            </Space>
          </Col>
        </Row>
        <Row className="tag-row-style">
          <Col span={24}>
            {pageTags.tags?.map((tag) => {
              return (
                <Tag
                  key={`tag-${tag.value}`}
                  color={
                    tag.level === 0
                      ? 'orange'
                      : tag.level === 1
                        ? 'cyan'
                        : 'geekblue'
                  }
                  closable={!readonly}
                  onClose={() => {
                    const tags = pageTags.tags.filter((element) => {
                      return element.value != tag.value;
                    });
                    dispatch(CreateReadEditPageSlice.actions.SET_TAGS(tags));
                    dispatch(
                      CreateReadEditPageSlice.actions.SET_SHOULD_UPDATE_TAGS(
                        true,
                      ),
                    );
                  }}
                >
                  {tag.title}
                </Tag>
              );
            })}
            {!readonly && (
              <Tag
                className="tag-plus"
                onClick={() => {
                  saveCurrentPageContent(pagesState.currentLanguage);
                  dispatch(CreateReadEditPageSlice.actions.OPEN_TAG_MODAL());
                }}
              >
                {addRemoveTagButton}
              </Tag>
            )}
          </Col>
        </Row>
      </>
    );
  };

  const showTagsModal = () => {
    return (
      <Modal
        title={
          pageTags.tags?.length
            ? t('page-tags-modal-update-tags-title')
            : t('page-tags-modal-add-tags-title')
        }
        okText={
          pageTags.tags?.length
            ? t('page-tags-modal-modify-tags-ok-button')
            : t('page-tags-modal-add-tags-ok-button')
        }
        cancelText={t('page-tags-modal-cancel-button')}
        okButtonProps={{ disabled: pageTags.isLoadingTreeData }}
        onOk={() => {
          setTags(pageTags.selectedTreeNodes);
          dispatch(CreateReadEditPageSlice.actions.CLOSE_TAG_MODAL());
        }}
        onCancel={() => {
          dispatch(CreateReadEditPageSlice.actions.CLOSE_TAG_MODAL());
        }}
        open={pageTags.openTagModal}
      >
        <p>{t('page-tags-modal-description')}</p>

        <TreeSelect
          multiple
          style={{ width: '100%' }}
          treeDefaultExpandAll
          treeData={pageTags.treeData}
          value={pageTags.selectedTreeNodes}
          showCheckedStrategy="SHOW_ALL"
          notFoundContent={<Empty description={t('page-tags-no-tags-found')} />}
          onChange={(value) => {
            const newValues: string[] = [];
            value.map((item) => {
              const path = getPath(item);
              if (path.every((i) => value.includes(i))) {
                newValues.push(item);
              }
            });
            dispatch(
              CreateReadEditPageSlice.actions.SET_TAGS_SELECTED_TREE_NODES(
                sortTagsByLevel(newValues),
              ),
            );
          }}
          onSelect={(value) => {
            const items = getPath(value);
            dispatch(
              CreateReadEditPageSlice.actions.SET_TAGS_SELECTED_TREE_NODES(
                sortTagsByLevel(mergeArrays(pageTags.selectedTreeNodes, items)),
              ),
            );
          }}
          disabled={pageTags.isLoadingTreeData}
          loading={pageTags.isLoadingTreeData}
        />
      </Modal>
    );
  };

  const showAddLanguageModal = () => {
    const selectOptions = Object.values(PageLanguage).map((language) => {
      return {
        value: language,
        label: t(`create-page-language-tag-${language.toLowerCase()}`),
      };
    });

    return (
      <Modal
        title={t('create-page-language-modal-title')}
        open={languagesState.openAddLanguageModal}
        okText={t('create-page-language-dropdown-ok-button')}
        cancelText={t('create-page-language-dropdown-cancel-button')}
        okButtonProps={{ disabled: !languagesState.selectedLanguageToAdd }}
        centered
        onOk={() => {
          dispatch(CreateReadEditPageSlice.actions.ADD_SELECTED_LANGUAGE());
          dispatch(CreateReadEditPageSlice.actions.CLOSE_ADD_LANGUAGE_MODAL());
        }}
        onCancel={() => {
          dispatch(CreateReadEditPageSlice.actions.CLOSE_ADD_LANGUAGE_MODAL());
        }}
      >
        <p>{t('create-page-language-modal-description')}</p>
        <Select
          style={{ width: 250 }}
          placeholder={t('create-page-language-dropdown-placeholder')}
          value={languagesState.selectedLanguageToAdd}
          options={selectOptions.filter((element: any) => {
            return !pagesState.pages.some(
              (page) => page.language === element.value,
            );
          })}
          onChange={(value) => {
            dispatch(
              CreateReadEditPageSlice.actions.SET_SELECTED_LANGUAGE_TO_ADD(
                value,
              ),
            );
          }}
        />
      </Modal>
    );
  };

  const showSelectDefaultPageLanguageModal = () => {
    const selectOptions = pagesState.pages.map((page) => {
      return {
        value: page.language,
        label: t(`create-page-language-tag-${page.language.toLowerCase()}`),
      };
    });

    return (
      <Modal
        title={t('create-page-default-language-modal-title')}
        open={languagesState.openSelectDefaultPageLanguageModal}
        okText={t('create-page-default-language-modal-ok-button')}
        cancelText={t('create-page-default-language-modal-cancel-button')}
        okButtonProps={{ disabled: !languagesState.defaultPageLanguage }}
        centered
        onOk={() => {
          dispatch(
            CreateReadEditPageSlice.actions.SET_PENDING_DEFAULT_PAGE_LANGUAGE_SELECTION(
              false,
            ),
          );
          dispatch(
            CreateReadEditPageSlice.actions.CLOSE_SELECT_DEFAULT_PAGE_LANGUAGE_MODAL(),
          );
        }}
        onCancel={() => {
          dispatch(
            CreateReadEditPageSlice.actions.SET_DEFAULT_PAGE_LANGUAGE(
              undefined,
            ),
          );
          dispatch(CreateReadEditPageSlice.actions.STOP_PAGE_VALIDATING());
          dispatch(
            CreateReadEditPageSlice.actions.CLOSE_SELECT_DEFAULT_PAGE_LANGUAGE_MODAL(),
          );
        }}
      >
        <p>{t('create-page-default-language-modal-first-paragraph')}</p>
        <p>{t('create-page-default-language-modal-second-paragraph')}</p>
        <Select
          style={{ width: 250 }}
          placeholder={t('create-page-language-dropdown-placeholder')}
          value={languagesState.defaultPageLanguage}
          options={selectOptions}
          onChange={(value) => {
            dispatch(
              CreateReadEditPageSlice.actions.SET_DEFAULT_PAGE_LANGUAGE(value),
            );
          }}
        />
      </Modal>
    );
  };

  const onEvaluation = () => {
    if (currentEvaluation) {
      dispatch(
        CreateReadEditPageSlice.actions.OPEN_EDIT_EVALUATION_MODAL({
          page: pageToEvaluate,
        }),
      );
      return;
    }
    dispatch(
      CreateReadEditPageSlice.actions.OPEN_EVALUATION_MODAL(pageToEvaluate),
    );
  };

  const onCreateEvaluation = (evaluationValues: any) => {
    dispatch(CreateReadEditPageSlice.actions.CLOSE_EVALUATION_MODAL());

    dispatch(
      asyncPageActions.NEW_PAGE_EVALUATION({
        liked: evaluationValues.liked,
        availabilityOfInformation: evaluationValues.availabilityOfInformation,
        clarity: evaluationValues.clarity,
        depthOfInformation: evaluationValues.depthOfInformation,
        description: evaluationValues.description,
        informationAccuracy: evaluationValues.informationAccuracy,
        locate: evaluationValues.locate,
        pageDescription: evaluationValues.pageDescription,
        preferredLanguage: evaluationValues.preferredLanguage,
        userId,
        pageId: pageIdToEvaluate,
      }),
    );
  };

  const onEditEvaluation = (evaluationValues: any) => {
    dispatch(CreateReadEditPageSlice.actions.CLOSE_EDIT_EVALUATION_MODAL());

    dispatch(
      asyncPageActions.UPDATE_PAGE_EVALUATION({
        evaluationValues,
        userId,
        pageId: pageIdToEvaluate,
      }),
    );
  };

  const onDeleteEvaluation = () => {
    dispatch(
      asyncPageActions.DELETE_PAGE_EVALUATION({
        userId,
        pageId: pageIdToEvaluate,
      }),
    );

    // If currentEvaluation indicates that the modal is being edited
    if (currentEvaluation) {
      dispatch(CreateReadEditPageSlice.actions.CLOSE_EDIT_EVALUATION_MODAL());
      return;
    }
    dispatch(CreateReadEditPageSlice.actions.CLOSE_EVALUATION_MODAL());
  };

  const isEvaluationPageTrue = currentEvaluation
    ? currentEvaluation.liked
    : null;

  const showEvaluationModal = () => {
    if (!pageToEvaluate || !loginState.loggedIn) {
      return;
    }

    const isEditing = currentEvaluation ? true : false;

    return (
      <EvaluationModal
        isOpen={isEditing ? openEditEvaluationModal : openEvaluationModal}
        isEditing={isEditing}
        title={`${t('evaluation-modal-title')} ${pageToEvaluate.title}`}
        okText={t('evaluation-modal-save-button')}
        cancelText={t('evaluation-modal-cancel-button')}
        deleteText={t('evaluation-modal-delete-button')}
        requiredText={t('evaluation-modal-required-label')}
        onCancel={() => {
          if (isEditing) {
            dispatch(
              CreateReadEditPageSlice.actions.CLOSE_EDIT_EVALUATION_MODAL(),
            );
            return;
          }
          dispatch(CreateReadEditPageSlice.actions.CLOSE_EVALUATION_MODAL());
        }}
        itemType="PAGE"
        itemId={pageToEvaluate.id}
        defaultEvaluationRadio={evaluationRadioSelected}
        onCreateEvaluation={(values: any) => onCreateEvaluation(values)}
        onEditEvaluation={(values: any) => onEditEvaluation(values)}
        onDeleteEvaluation={() => onDeleteEvaluation()}
        isUserAdmin={isUserAdmin}
        evaluationValues={isEditing ? currentEvaluation : undefined}
      />
    );
  };

  const showEvaluation = () => {
    if (loginState.loggedIn && !isUserAdmin) {
      return (
        <Row justify="center" align="middle">
          <Col style={{ marginTop: '2rem' }}>
            <p>
              {currentEvaluation
                ? t('page-evaluation-already-evaluted-subtitle')
                : t('page-evaluation-subtitle')}
            </p>
            <Row
              justify="center"
              align="middle"
              style={{ textAlign: 'center' }}
            >
              <Button
                style={{ marginRight: '1rem' }}
                type={isEvaluationPageTrue ? 'primary' : 'default'}
                onClick={() => {
                  setEvaluationRadioSelected(true);
                  onEvaluation();
                }}
              >
                <LikeTwoTone /> {t('page-evaluation-liked-button')}
              </Button>
              <Button
                style={{ marginLeft: '1rem' }}
                type={
                  isEvaluationPageTrue || isEvaluationPageTrue === null
                    ? 'default'
                    : 'primary'
                }
                onClick={() => {
                  setEvaluationRadioSelected(false);
                  onEvaluation();
                }}
              >
                <DislikeTwoTone /> {t('page-evaluation-disliked-button')}
              </Button>
            </Row>
          </Col>
        </Row>
      );
    }
  };

  return (
    <Col
      pull={1}
      span={22}
      push={1}
      className="CreatePages"
      id="create-read-edit-pages-root"
    >
      {showTagsModal()}
      {showAddLanguageModal()}
      {showSelectDefaultPageLanguageModal()}
      <Form
        layout="horizontal"
        wrapperCol={{ span: 24 }}
        size="large"
        onFinish={submitNewPage}
        ref={formRef}
        key={`
          ${uniqid()}
          ${pagesState.pageTitleInitialValue}${
            pagesState.pageDescriptionInitialValue
          }`}
        initialValues={{
          title: pagesState.pageTitleInitialValue,
          description: pagesState.pageDescriptionInitialValue,
        }}
      >
        <Row id="return-button-row">
          <Col span={24}>
            <Form.Item>
              <Button
                type="link"
                className="return-button"
                onClick={() => returnOrShowModalOfDiscardChanges()}
              >
                <ReturnSymbol className="return-symbol" />
                {t('page-back-button-label')}
              </Button>
            </Form.Item>
          </Col>
        </Row>
        <Row className="editor-content-row-style">
          <Col span={24}>
            {setHeaderTitle()}
            {showTags()}
            <Row justify="center">
              {loginState.loggedIn && isUserAdmin && (
                <Col span={5}>
                  {showSlug()}
                  {showLanguagesPanel()}
                  {showEditButton()}
                  {showPublishCancelButtons()}
                  {showGoToEvaluationsButton()}
                </Col>
              )}
              <Col span={19}>
                <Row className="input-form-editor">
                  <Col span={22} offset={2}>
                    <Spin
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                      spinning={pageResponseLoadingState}
                      size="large"
                    >
                      {pageModeState !== PageMode.Read &&
                        !pagesState.pages.length && (
                          <Empty
                            description={
                              <Space direction="vertical">
                                <p>{t('create-page-no-language-added')}</p>
                                <Button
                                  type="primary"
                                  shape="round"
                                  onClick={() => {
                                    saveCurrentPageContent();
                                    dispatch(
                                      CreateReadEditPageSlice.actions.OPEN_ADD_LANGUAGE_MODAL(),
                                    );
                                  }}
                                >
                                  {t('create-page-add-language-button')}
                                </Button>
                                <Button
                                  type="primary"
                                  shape="round"
                                  ghost
                                  style={{ border: 'none' }}
                                  onClick={() => {
                                    returnOrShowModalOfDiscardChanges();
                                  }}
                                >
                                  {t('create-page-cancel-creation-button')}
                                </Button>
                              </Space>
                            }
                          />
                        )}
                      {pagesState.pages.length > 0 && (
                        <>
                          <PageForm
                            pageMode={pageModeState}
                            loading={pageResponseLoadingState}
                            currentLanguage={pagesState.currentLanguage}
                          />
                          <PageReactQuillWrapper
                            pageMode={pageModeState}
                            editorInitialValue={
                              pagesState.pageEditorInitialValue
                            }
                            loading={pageResponseLoadingState}
                          />
                        </>
                      )}
                    </Spin>
                  </Col>
                </Row>
                {showEvaluation()}
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
      {showEvaluationModal()}
    </Col>
  );
};
