import React, { Fragment, useEffect, useState } from 'react';
import { Row, Col, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { DownOutlined, RightOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';

import { ReactComponent as TrashCan } from '../../../assets/popover-icons/trash-can.svg';
import { ReactComponent as EditPencil } from '../../../assets/popover-icons/edit.svg';

import { Header } from '../../../components/Header/Header';
import {
  IDefaultSortBehaviour,
  PagingTable,
} from '../../../components/PagingTable/PagingTable';
import { Filter } from '../../../components/Filter/Filter';
import { AppDispatch, RootState } from '../../../store/store';
import { PageSlice, asyncActionsCreatedPages } from '../Pages.Slice';
import { showModal } from '../../../helpers/showModal/showModal';
import { scrollToTopFunction } from '../../../helpers/useScrollToTop/useScrollToTop';

import './CreatedPages.scss';
import { DeleteSelectedButton } from '../../../components/DeleteSelectedButton/DeleteSelectedButton';
import { PathTrackerSlice } from '../../PathTracker/PathTracker.Slice';
import { PageMode } from '../CreateReadEditPages/CreateReadEditPage.Slice';

// This conversion is necessary because the backend expects the full name of the language in uppercase, while the navbar stores the current language as an abbreviation.
const languageMap: { [key: string]: string } = {
  en: 'ENGLISH',
  es: 'SPANISH',
  pt: 'PORTUGUESE',
};

export const CreatedPages = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const pagesCreatedState = useSelector((state: RootState) => state.page);
  const navbarState = useSelector((state: RootState) => state.navBar);

  useEffect(() => {
    return () => {
      dispatch(PageSlice.actions.PAGE_BACK_TO_ORIGINAL_STATE());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!pagesCreatedState.reloadCreatedPages) {
      return;
    }

    if (pagesCreatedState.pagesCreatedTableLoading) {
      return;
    }

    let queryString = `page=${
      pagesCreatedState.pagesCreatedPageIndex - 1
    }&size=${pagesCreatedState.pagesCreatedPageSize}`;

    if (pagesCreatedState.pagesCreatedFilterQueryString.length > 0) {
      queryString = `${queryString}&${pagesCreatedState.pagesCreatedFilterQueryString.join(
        '&',
      )}`;
    }

    if (pagesCreatedState.pagesCreatedOrderQueryString) {
      queryString = `${queryString}&${pagesCreatedState.pagesCreatedOrderQueryString}`;
    }

    if (pagesCreatedState.pagesCreatedFirstQueryBehaviour) {
      queryString = `${queryString}&sort=modifiedAt,desc`;
    }

    const language = languageMap[navbarState.defaultLanguage];
    dispatch(
      asyncActionsCreatedPages.GET_PAGE_LIST_WITH_FILTER({
        queryString,
        language,
      }),
    );
  }, [
    dispatch,
    pagesCreatedState.pagesCreatedTableLoading,
    pagesCreatedState.pagesCreatedFirstQueryBehaviour,
    pagesCreatedState.reloadCreatedPages,
    pagesCreatedState.pagesCreatedPageIndex,
    pagesCreatedState.pagesCreatedPageSize,
    pagesCreatedState.pagesCreatedOrderQueryString,
    pagesCreatedState.pagesCreatedFilterQueryString,
    navbarState.defaultLanguage,
  ]);

  const [columnsWidth, setColumnsWidth] = useState({
    titleColumnWidth: 4,
    tagsColumnWidth: 4,
    updatedByColumnWidth: 3.5,
    updatedAtColumnWidth: 3.5,
  });

  const resizeHandlerSpeed = 0.028;
  const resizeColumns = (
    columnName:
      | 'titleColumnWidth'
      | 'tagsColumnWidth'
      | 'updatedByColumnWidth'
      | 'updatedAtColumnWidth',
    movement: number,
  ) => {
    setColumnsWidth({
      ...columnsWidth,
      [columnName]: (columnsWidth[columnName] += movement * resizeHandlerSpeed),
    });
  };

  const mockedColumnsCursor = [
    {
      columnTitle: t('pages-created-column-name-label'),
      columnProperty: 'title',
      width: columnsWidth.titleColumnWidth,
      onResizeAction: (event: MouseEvent) => {
        resizeColumns('titleColumnWidth', event.movementX);
      },
    },
    {
      columnTitle: t('page-tags-title'),
      columnProperty: 'tags',
      width: columnsWidth.tagsColumnWidth,
      onResizeAction: (event: MouseEvent) => {
        resizeColumns('tagsColumnWidth', event.movementX);
      },
      disableSort: true,
    },
    {
      columnTitle: t('pages-created-column-updatedBy-label'),
      columnProperty: 'updatedByName',
      width: columnsWidth.updatedByColumnWidth,
      onResizeAction: (event: MouseEvent) => {
        resizeColumns('updatedByColumnWidth', event.movementX);
      },
    },
    {
      columnTitle: t('pages-created-column-latestUpdate-label'),
      columnProperty: 'modifiedAt',
      width: columnsWidth.updatedAtColumnWidth,
      onResizeAction: (event: MouseEvent) => {
        resizeColumns('updatedAtColumnWidth', event.movementX);
      },
    },
  ];

  const defaultSortBehaviour: IDefaultSortBehaviour = {
    columnProperty: 'modifiedAt',
    order: 'descend',
    disableDefaultSortBehaviour: () => {
      if (pagesCreatedState.pagesCreatedFirstQueryBehaviour) {
        dispatch(
          PageSlice.actions.PAGES_CREATED_DISABLE_DEFAULT_SORT_BEHAVIOUR(),
        );
      }
    },
  };

  const showDeletePageConfirmationModal = (id: number) => {
    showModal(
      'warning',
      `${t('pages-created-warning-selected-one-documentation-sure')}`,
      `${t('pages-created-warning-delete-one-documentation')}`,
      () => {
        dispatch(asyncActionsCreatedPages.DELETE_ONE_PAGE(id));
      },
      () => {},
      `${t('pages-created-okText-delete-documentation')}`,
      `${t('pages-created-cancelText-delete-documentation')}`,
    );
  };

  const options = [
    {
      optionName: t('pages-created-edit-action-documentations'),
      optionIcon: <EditPencil />,
      optionOnClick: (id: number, page: any) => {
        navigate(`/pages/${page.page.slug}`, {
          state: { mode: PageMode.Edit, id: id },
        });
      },
    },
    {
      optionName: t('pages-created-remove-action-documentations'),
      optionIcon: <TrashCan />,
      optionOnClick: (id: number) => {
        showDeletePageConfirmationModal(id);
      },
    },
  ];

  const onChangePage = (pageIndex: number) => {
    scrollToTopFunction('create-page-button');

    dispatch(PageSlice.actions.PAGES_CREATED_CHANGE_PAGE_INDEX(pageIndex));
  };

  const dispatchSortBy = (sortBy: string) => {
    dispatch(
      PageSlice.actions.PAGES_CREATED_SET_ORDER_QUERY_STRING(
        sortBy ? `sort=${sortBy}` : '',
      ),
    );
  };

  const onChangeSort = (
    field: string | string[],
    order: string | undefined | null,
  ) => {
    let sortBy = '';

    if (!order || !field) {
      return dispatchSortBy(sortBy);
    }

    if (defaultSortBehaviour) {
      defaultSortBehaviour.disableDefaultSortBehaviour();
    }

    const fieldToQueryParam = Array.isArray(field) ? field[0] : field;

    switch (fieldToQueryParam) {
      case 'modifiedAt':
        sortBy =
          order === 'ascend' ? fieldToQueryParam : `${fieldToQueryParam},desc`;
        return dispatchSortBy(sortBy);

      case 'updatedByName':
        sortBy =
          order === 'ascend'
            ? `page.${fieldToQueryParam},ignoreCase`
            : `page.${fieldToQueryParam},desc,ignoreCase`;
        return dispatchSortBy(sortBy);

      default:
        sortBy =
          order === 'ascend'
            ? `${fieldToQueryParam},ignoreCase`
            : `${fieldToQueryParam},desc,ignoreCase`;
        return dispatchSortBy(sortBy);
    }
  };

  const clearFilterFields = () => {
    dispatch(PageSlice.actions.PAGES_CREATED_CLEAN_FILTER_FIELDS());
  };

  const submitFilter = (values: any) => {
    const queryParams: string[] = [];
    Object.keys(values).forEach((key) => {
      if (values[key]) {
        queryParams.push(`${key}=${values[key]}`);
      }
    });

    dispatch(
      PageSlice.actions.PAGES_CREATED_SET_FILTER_QUERY_STRING(queryParams),
    );
  };

  const filterFields = [
    {
      fieldTitle: t('pages-created-filter-title-field-documentation'),
      fieldType: 'input',
      fieldProperty: 'title',
      fieldValue: pagesCreatedState.pagesCreatedFilterByPageName,
    },
    {
      fieldTitle: t('pages-created-filter-updatedBy-field-documentation'),
      fieldType: 'input',
      fieldProperty: 'updatedByName',
      fieldValue: pagesCreatedState.pagesCreatedFilterByModifiedBy,
    },
  ];

  const toggleFilter = () => {
    if (!pagesCreatedState.pagesCreatedCollapseFilters) {
      dispatch(PageSlice.actions.PAGES_CREATED_COLLAPSE_FILTERS(true));
      return;
    }

    dispatch(PageSlice.actions.PAGES_CREATED_COLLAPSE_FILTERS(false));
  };

  const filterButton = () => {
    if (!pagesCreatedState.pagesCreatedCollapseFilters) {
      return (
        <Button id="open-filter-button" type="link" onClick={toggleFilter}>
          {t('pages-created-open-filter-button-documentation')}
          <DownOutlined />
        </Button>
      );
    }
    return (
      <Button id="close-filter-button" type="link" onClick={toggleFilter}>
        {t('pages-created-close-filter-button-documentation')}
        <RightOutlined />
      </Button>
    );
  };

  const collapseFiltersButtonAndArea = () => {
    return (
      <Fragment>
        <Col span={14} offset={3} className="filters-button-and-collapse">
          {filterButton()}
        </Col>
        <Row className="filter-collapse-area">
          <Col span={24}>
            <Filter
              submitFilterFields={submitFilter}
              clearFilterFields={clearFilterFields}
              openedFilter={pagesCreatedState.pagesCreatedCollapseFilters}
              fields={filterFields}
            />
          </Col>
        </Row>
      </Fragment>
    );
  };

  const openPageDetails = (record: any) => {
    dispatch(
      PathTrackerSlice.actions.SET_PREVIOUS_PATH_FOR_PAGES('/created-pages'),
    );
    navigate(`/pages/${record.page.slug}`);
  };

  const getPageId = (dataRow: any) => {
    return dataRow.page.id;
  };

  const selectRowsToPages = (x: any) => {
    dispatch(PageSlice.actions.PAGES_CREATED_SET_SELECTED_ROWS(x));
  };

  const selectIdFromRowsInPages = (x: any) => {
    dispatch(PageSlice.actions.PAGES_CREATED_SET_ID_TO_DELETE_PAGES(x));
  };

  const deleteSelectedPages = (id: number[]) => {
    dispatch(asyncActionsCreatedPages.DELETE_MANY_PAGES(id));
  };

  return (
    <Col pull={1} span={22} push={1} id="created-pages-id">
      <Row className="PagesCreated">
        <Col span={24}>
          <Header
            title={t('header-footer-documentations')}
            subtitle={t('pages-created-documentations-subtitle')}
            showEllipsis
          >
            <Row>
              <Col span={24}>
                <Row className="row-with-filter-and-button-to-create-new-page">
                  <Col span={4}>
                    <Button
                      id="create-page-button"
                      shape="round"
                      onClick={() => {
                        navigate(`/pages`, {
                          state: { mode: PageMode.Create },
                        });
                      }}
                    >
                      {t('pages-created-create-a-new-documentation')}
                    </Button>
                  </Col>
                  <Col span={2} offset={1}>
                    <DeleteSelectedButton
                      numberOfItemsSelected={
                        pagesCreatedState.pagesCreatedSelectedRowToDelete
                      }
                      messageConfirmationByGivenTab={`${t(
                        'pages-created-selected-delete-documentation',
                      )}`}
                      singularContentConfirmationByGivenTab={`${t(
                        'pages-created-documentation',
                      )}`}
                      pluralContentConfirmationByGivenTab={`${t(
                        'pages-created-documentations',
                      )}`}
                      deleteOneTitleModal={`${t(
                        'pages-created-warning-selected-one-documentation-sure',
                      )}`}
                      deleteOneContentModal={`${t(
                        'pages-created-warning-delete-one-documentation',
                      )}`}
                      deleteManyTitleModal={`${t(
                        'pages-created-warning-selected-many-documentations-sure',
                      )}`}
                      deleteManyContentModal={`${t(
                        'pages-created-warning-delete-many',
                      )}`}
                      onOkText={`${t('pages-created-okText-delete-documentation')}`}
                      onCancelText={`${t('pages-created-cancelText-delete-documentation')}`}
                      deleteAction={() =>
                        deleteSelectedPages(
                          pagesCreatedState.pagesCreatedDeleteManyPages!,
                        )
                      }
                    />
                  </Col>
                  {collapseFiltersButtonAndArea()}
                </Row>
                <Row>
                  <Col span={24} id="table-id">
                    <PagingTable
                      dataSource={pagesCreatedState.pageList?.content || []}
                      tableLoading={pagesCreatedState.pagesCreatedTableLoading}
                      columns={mockedColumnsCursor}
                      options={options}
                      pageSize={pagesCreatedState.pagesCreatedPageSize}
                      pageIndex={pagesCreatedState.pagesCreatedPageIndex}
                      numberOfItems={
                        pagesCreatedState.pageList?.totalElements || 0
                      }
                      onChangePage={onChangePage}
                      onChangeSort={onChangeSort}
                      rowClickAction={openPageDetails}
                      emptyText={t('pages-created-empty-table-documentations')}
                      defaultSortBehaviour={defaultSortBehaviour}
                      getIdFromRow={getPageId}
                      selectRowsToGivenTab={(x: any) => selectRowsToPages(x)}
                      selectIdFromRowsSelected={(x: any) =>
                        selectIdFromRowsInPages(x)
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Header>
        </Col>
      </Row>
    </Col>
  );
};
