import { Col, Modal, Progress, Result, Row, Space } from 'antd';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/store';
import {
  asyncActions,
  DownloadStep,
  FileState,
  RepositoryDownloadMultipleModalSlice,
} from './RepositoryDownloadMultipleModal.Slice';
import './RepositoryDownloadMultipleModal.scss';
import { t } from 'i18next';

export interface IRepositoryDownloadMultipleModalProps {
  open: boolean;
  title: string;
  okText: string;
  cancelText: string;
  applicationLanguage: string;
  onClose: () => void;
  files: any;
}

export const RepositoryDownloadMultipleModal: React.FC<
  IRepositoryDownloadMultipleModalProps
> = ({
  open,
  title,
  okText,
  cancelText,
  applicationLanguage,
  onClose,
  files,
}) => {
  const dispatch = useDispatch<AppDispatch>();

  const currentStep = useSelector(
    (state: RootState) => state.repositoryDownloadMultipleModal.currentStep,
  );

  const currentFileIndex = useSelector(
    (state: RootState) =>
      state.repositoryDownloadMultipleModal.currentFileIndex,
  );

  const stepProgress = useSelector(
    (state: RootState) => state.repositoryDownloadMultipleModal.stepProgress,
  );

  const totalProgress = useSelector(
    (state: RootState) => state.repositoryDownloadMultipleModal.totalProgress,
  );

  const fileList = useSelector(
    (state: RootState) => state.repositoryDownloadMultipleModal.fileList,
  );

  const isTransferring = useSelector(
    (state: RootState) => state.repositoryDownloadMultipleModal.isTransferring,
  );

  const abortControllerRef = useRef<AbortController>(new AbortController());

  const validFileList = fileList.filter(
    (file) => file.state !== FileState.ERROR,
  );

  // Update the progress tracking variables whenever there is
  // an update on the file list
  useEffect(() => {
    const transferProgress = Math.floor(
      validFileList.reduce((acc, file) => {
        return acc + file.transferProgress;
      }, 0) / validFileList.length,
    );

    const compressionProgress = Math.floor(
      validFileList.reduce((acc, file) => {
        return acc + file.compressionProgress;
      }, 0) / validFileList.length,
    );

    dispatch(
      RepositoryDownloadMultipleModalSlice.actions.SET_TRANSFER_PROGRESS(
        transferProgress,
      ),
    );

    dispatch(
      RepositoryDownloadMultipleModalSlice.actions.SET_COMPRESSION_PROGRESS(
        compressionProgress,
      ),
    );

    dispatch(RepositoryDownloadMultipleModalSlice.actions.SET_TOTAL_PROGRESS());
  }, [fileList]);

  useEffect(() => {
    if (currentStep !== DownloadStep.TRANSFERRING || isTransferring) {
      return;
    }

    const file = fileList ? fileList[currentFileIndex] : undefined;
    if (!file) {
      dispatch(
        RepositoryDownloadMultipleModalSlice.actions.SET_CURRENT_STEP(
          currentFileIndex > 0 ? DownloadStep.COMPRESSING : DownloadStep.ERROR,
        ),
      );
      return;
    }

    if (file.state === FileState.DOWNLOADED) {
      return;
    }

    abortControllerRef.current = new AbortController();

    dispatch(
      asyncActions.TRANSFER_FILE_MODAL({
        rootId: file.file.rootArtifactId,
        id: file.file.id,
        abortSignal: abortControllerRef.current.signal,
      }),
    );
  }, [currentStep, isTransferring]);

  useEffect(() => {
    if (currentStep === DownloadStep.COMPRESSING) {
      dispatch(asyncActions.ZIP_FILES(validFileList));
      return;
    }

    if (currentStep !== DownloadStep.DOWNLOADING) {
      return;
    }

    const dateFormat = new Intl.DateTimeFormat(applicationLanguage, {
      dateStyle: 'long',
      timeStyle: 'long',
    } as Intl.DateTimeFormatOptions);

    const fileName = `devportal-download-${dateFormat.format(new Date()).replaceAll(' ', '_')}`;
    dispatch(
      RepositoryDownloadMultipleModalSlice.actions.DOWNLOAD_ZIP(fileName),
    );
  }, [currentStep]);

  useEffect(() => {
    if (!open) {
      if (abortControllerRef?.current) {
        abortControllerRef.current.abort();
      }

      dispatch(
        RepositoryDownloadMultipleModalSlice.actions.RESTORE_INITIAL_STATE(),
      );
      return;
    }

    if (!files) {
      return;
    }

    dispatch(
      RepositoryDownloadMultipleModalSlice.actions.SET_FILE_LIST(
        files.map((file: any) => {
          return {
            file: file,
            state: FileState.IDLE,
            transferProgress: 0,
            compressionProgress: 0,
            data: undefined,
          };
        }),
      ),
    );
    dispatch(
      RepositoryDownloadMultipleModalSlice.actions.SET_CURRENT_STEP(
        DownloadStep.TRANSFERRING,
      ),
    );
  }, [open, files]);

  const canCancel =
    currentStep === DownloadStep.TRANSFERRING ||
    currentStep === DownloadStep.COMPRESSING;

  const modalRegularContent = () => {
    const stepLabel =
      currentStep === DownloadStep.TRANSFERRING
        ? t('repository-download-multiple-files-transferring-files-label')
        : currentStep === DownloadStep.COMPRESSING
          ? t('repository-download-multiple-files-compressing-files-label')
          : currentStep === DownloadStep.DOWNLOADING
            ? t('repository-download-multiple-files-downloading-zip-label')
            : '';

    const fileProgress = fileList[currentFileIndex]
      ? currentStep === DownloadStep.TRANSFERRING
        ? fileList[currentFileIndex].transferProgress
        : fileList[currentFileIndex].compressionProgress
      : 0;

    return (
      <>
        <Col
          span={9}
          className="repository-download-multiple-modal-total-progress-column"
        >
          <Space direction="vertical">
            <Progress
              type="circle"
              percent={totalProgress}
              status={totalProgress < 100 ? 'active' : 'success'}
            />
            <div className="repository-download-multiple-modal-total-progress">
              {t('repository-download-multiple-files-total-progress-label')}
            </div>
          </Space>
        </Col>
        <Col
          span={15}
          className="repository-download-multiple-modal-total-step-column"
        >
          <Space
            direction="vertical"
            className="repository-download-multiple-modal-total-step-space"
          >
            <div>
              <b>{`${t('repository-download-multiple-files-step-label')} ${currentStep}/${DownloadStep.DOWNLOADING}`}</b>
              <br />
              {stepLabel}
              <Progress
                percent={stepProgress}
                size="small"
                status={stepProgress === 100 ? 'success' : 'active'}
              />
            </div>
            <div>
              <b>
                {`${t('repository-download-multiple-files-file-label')} ${currentFileIndex + 1}/${fileList.length}`}
              </b>
              <br />
              {fileList[currentFileIndex] &&
                fileList[currentFileIndex].file.name}
              <Progress percent={fileProgress} size="small" status="active" />
            </div>
          </Space>
        </Col>
      </>
    );
  };

  return (
    <Modal
      centered
      open={open}
      title={title}
      okText={okText}
      cancelText={cancelText}
      okButtonProps={{ disabled: canCancel }}
      cancelButtonProps={{ disabled: !canCancel }}
      confirmLoading={canCancel}
      onOk={onClose}
      onCancel={() => {
        onClose();
      }}
      bodyStyle={
        currentStep === DownloadStep.FINISHED ||
        currentStep === DownloadStep.ERROR
          ? { padding: 0 }
          : {}
      }
    >
      <Row justify="space-evenly">
        {currentStep === DownloadStep.FINISHED && (
          <Col span={24}>
            <Result
              status="success"
              subTitle={t('repository-download-multiple-files-success-label')}
            />
          </Col>
        )}

        {currentStep === DownloadStep.ERROR && (
          <Col span={24}>
            <Result
              status="error"
              subTitle={t('repository-download-multiple-files-error-label')}
            />
          </Col>
        )}

        {currentStep !== DownloadStep.FINISHED &&
          currentStep !== DownloadStep.ERROR &&
          modalRegularContent()}
      </Row>
    </Modal>
  );
};
