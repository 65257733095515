import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  InboxOutlined,
} from '@ant-design/icons';
import { Alert, Modal, Space, Tooltip, Upload, UploadFile } from 'antd';
import React, { useEffect, useState } from 'react';
import { securedMultipartApi } from '../../services/api.config';
import { RcFile } from 'antd/lib/upload';
import { UploadFileStatus } from 'antd/lib/upload/interface';
import { t } from 'i18next';

const { Dragger } = Upload;

export interface IRepositoryUploadModalProps {
  open: boolean;
  title: string;
  okText: string;
  cancelText: string;
  draggerText: string;
  draggerHintText: string;
  loading: boolean;
  rootId: number | undefined;
  releaseId: number | undefined;
  onClose: () => void;
}

interface IAbortController {
  fileUid: string;
  controller: AbortController;
}

export const RepositoryUploadModal: React.FC<IRepositoryUploadModalProps> = ({
  open,
  title,
  okText,
  cancelText,
  draggerText,
  draggerHintText,
  loading,
  rootId,
  releaseId,
  onClose,
}) => {
  const [fileList, setFileList] = useState<UploadFile[]>();
  const [abortControllers, setAbortControllers] = useState<IAbortController[]>(
    [],
  );

  const updateProgressPercent = (uid: string, percent: number) => {
    setFileList((list) =>
      list?.map((file) => {
        if (file.uid === uid && file.status == 'uploading') {
          return {
            ...file,
            percent: percent,
          };
        }

        return file;
      }),
    );
  };

  const updateFileListFileStatus = (
    uid: string,
    status: UploadFileStatus | undefined,
    error: object = {},
  ) => {
    setFileList((list) =>
      list?.map((file) => {
        if (file.uid !== uid) {
          return file;
        }

        return {
          ...file,
          status: status,
          error: error,
        };
      }),
    );
  };

  const abortAllControllers = () => {
    abortControllers?.map((abortController) => {
      abortController.controller.abort();
    });
  };

  useEffect(() => {
    return () => {
      setFileList(undefined);
      abortAllControllers();
      setAbortControllers([]);
    };
  }, [open]);

  const onUploadProgress = (progressEvent: any, uid: string) => {
    const percentCompleted = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total,
    );

    updateProgressPercent(uid, percentCompleted);
  };

  const isUploading = (): boolean => {
    if (!fileList) {
      return false;
    }

    return fileList.some((file) => file.status === 'uploading');
  };

  const isFinished = (): boolean => {
    if (!fileList) {
      return false;
    }

    return !isUploading();
  };

  const isFinishedError = (): boolean => {
    if (!fileList) {
      return false;
    }

    return fileList.every((file) => file.status === 'error');
  };

  return (
    <Modal
      centered
      open={open}
      title={title}
      okText={okText}
      cancelText={cancelText}
      cancelButtonProps={{ disabled: !isUploading() }}
      confirmLoading={loading}
      onOk={onClose}
      onCancel={() => {
        abortAllControllers();
        onClose();
      }}
    >
      <Space direction="vertical" style={{ width: '100%' }}>
        {(isFinished() || isFinishedError()) && (
          <Alert
            message={
              isFinishedError()
                ? t('repository-upload-complete-error-title')
                : t('repository-upload-complete-success-title')
            }
            description={
              isFinishedError()
                ? t('repository-upload-complete-error-content')
                : t('repository-upload-complete-success-content')
            }
            type={isFinishedError() ? 'error' : 'success'}
            showIcon
          />
        )}
        <Dragger
          multiple={true}
          onChange={(info) => {
            setFileList([...info.fileList]);
          }}
          showUploadList={{
            showDownloadIcon: false,
            showPreviewIcon: false,
            showRemoveIcon: false,
          }}
          fileList={fileList}
          iconRender={(file: UploadFile<any>) => {
            const fileStatus = file.status;
            if (fileStatus === 'uploading') {
              return;
            }

            const icon =
              fileStatus === 'error' ? (
                <ExclamationCircleOutlined style={{ color: '#ff4d4f' }} />
              ) : (
                <CheckCircleOutlined style={{ color: '#52c41a' }} />
              );

            const message =
              fileStatus === 'error'
                ? t('repository-upload-error')
                : t('repository-upload-sucess');

            return (
              <Tooltip placement="bottom" title={message}>
                <div
                  style={{
                    display: 'flex',
                    alignContent: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {icon}
                </div>
              </Tooltip>
            );
          }}
          customRequest={(params) => {
            const { data, file } = params;
            const formData = new FormData();
            if (data) {
              Object.keys(data).forEach((key) => {
                formData.append(key, data[key] as string);
              });
            }
            formData.append('files', file);

            const newAbortController: IAbortController = {
              fileUid: (file as RcFile).uid,
              controller: new AbortController(),
            };

            setAbortControllers((previousState) => [
              ...previousState,
              newAbortController,
            ]);

            securedMultipartApi
              .post(`/root/${rootId}/artifact/${releaseId}/files`, formData, {
                onUploadProgress: (progressEvent) => {
                  onUploadProgress(progressEvent, (file as RcFile).uid);
                },
                signal: newAbortController.controller.signal,
                timeout: 0,
              })
              .then(() => {
                updateFileListFileStatus((file as RcFile).uid, 'success');
              })
              .catch((e) => {
                // If the request was canceled by the user
                if (e.code === 'ERR_CANCELED') {
                  updateFileListFileStatus((file as RcFile).uid, 'error', {
                    message: t('repository-upload-cancelled'),
                  });
                  return;
                }

                if (e.response?.request) {
                  updateFileListFileStatus((file as RcFile).uid, 'error', {
                    message:
                      e.response.request.status === 409
                        ? t('repository-upload-file-already-exists')
                        : t('repository-upload-unknown-error'),
                  });
                }
              })
              .finally(() => {
                setAbortControllers((previousState) =>
                  previousState.filter(
                    (abortController) =>
                      abortController.fileUid !== (file as RcFile).uid,
                  ),
                );
              });
          }}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">{draggerText}</p>
          <p className="ant-upload-hint">{draggerHintText}</p>
        </Dragger>
      </Space>
    </Modal>
  );
};
