import { securedBaseApi } from './api.config';

export enum NotificationType {
  Created = 'CREATED',
  Edited = 'EDITED',
  Deleted = 'DELETED',
}

export enum NotificationItemType {
  File = 'FILE',
  Artifact = 'ARTIFACT',
  Page = 'PAGE',
}

interface INotificationItem {
  name: string;
  type: NotificationItemType;
  identifier: string;
}

interface INotificationUser {
  name: string;
  id: number;
}

export interface INotification {
  id: number;
  viewed: boolean;
  notificationType: NotificationType;
  liked: boolean;
  item: INotificationItem;
  user: INotificationUser;
}

export interface INotificationList {
  content: INotification[];
  totalElements: number;
  totalPages: number;
}

export const notificationApi = {
  getUnviewedCount: () => {
    return securedBaseApi.get<number>('/notifications/unviewed-count');
  },

  getAllNotifications: (
    language: string,
    page: number = 0,
    size: number = 10,
  ) => {
    return securedBaseApi.get<INotificationList>(
      `/notifications?page=${page}&size=${size}&adminLanguage=${language}`,
    );
  },

  markAsViewed: (ids: number[]) => {
    return securedBaseApi.patch('/notifications/viewed', ids);
  },

  markAllAsViewed: () => {
    return securedBaseApi.patch('/notifications/mark-all-as-viewed');
  },

  delete: (id: number) => {
    return securedBaseApi.delete(`/notifications/${id}`);
  },

  deleteAll: () => {
    return securedBaseApi.delete('/notifications');
  },
};
