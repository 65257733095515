import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  INotification,
  notificationApi,
} from '../../services/notification.api';
import { messageNotification } from '../../helpers/messageNotification/messageNotification';
import { unhookedTranslation } from '../../helpers/unhookedTranslation/unhookedTranslation';

export enum NotificationDeleteStatus {
  Pending,
  Error,
}

export interface INotificationsListInitialState {
  isOpen: boolean;
  shouldUpdate: boolean;
  shouldUpdateTracker: boolean;
  isLoading: boolean;
  notifications:
    | (INotification & { deleteStatus?: NotificationDeleteStatus })[]
    | undefined;
  notificationCountWhenOpened: number;
  askToUpdate: boolean;
  currentPage: number;
  hasMoreNotifications: boolean;
}

const initialState: INotificationsListInitialState = {
  isOpen: false,
  shouldUpdate: false,
  shouldUpdateTracker: false,
  isLoading: false,
  notifications: undefined,
  notificationCountWhenOpened: 0,
  askToUpdate: false,
  currentPage: 0,
  hasMoreNotifications: true,
};

export const asyncActions = {
  GET_NOTIFICATIONS: createAsyncThunk(
    'GET_NOTIFICATIONS',
    async (payload: any) => {
      const page = payload.page ? payload.page : 0;
      const size = payload.size ? payload.size : 10;
      const notifications = await notificationApi.getAllNotifications(
        payload.language,
        page,
        size,
      );

      return { notifications: notifications.data, page: page, size: size };
    },
  ),
  DELETE_NOTIFICATION: createAsyncThunk(
    'DELETE_NOTIFICATION',
    async (notificationId: number) => {
      const deleteNotificationResponse =
        await notificationApi.delete(notificationId);

      return deleteNotificationResponse.data;
    },
  ),
  DELETE_ALL_NOTIFICATIONS: createAsyncThunk(
    'DELETE_ALL_NOTIFICATIONS',
    async () => {
      const deleteAllNotificationsResponse = await notificationApi.deleteAll();

      return deleteAllNotificationsResponse.data;
    },
  ),
  MARK_NOTIFICATION_AS_VIEWED: createAsyncThunk(
    'MARK_NOTIFICATION_AS_VIEWED',
    async (notificationId: number) => {
      const markNotificationAsViewedResponse =
        await notificationApi.markAsViewed([notificationId]);

      return markNotificationAsViewedResponse.data;
    },
  ),
  MARK_ALL_NOTIFICATIONS_AS_VIEWED: createAsyncThunk(
    'MARK_ALL_NOTIFICATIONS_AS_VIEWED',
    async () => {
      const markAllNotificationsAsViewedResponse =
        await notificationApi.markAllAsViewed();

      return markAllNotificationsAsViewedResponse.data;
    },
  ),
};

export const NotificationsListSlice = createSlice({
  name: 'notificationsListSlice',
  initialState,
  reducers: {
    SET_IS_OPEN: (state, action) => {
      state.isOpen = action.payload;
    },
    SET_SHOULD_UPDATE: (state, action) => {
      state.shouldUpdate = action.payload;
    },
    SET_NOTIFICATION_COUNT_WHEN_OPENED: (state, action) => {
      state.notificationCountWhenOpened = action.payload;
    },
    SET_ASK_TO_UPDATE: (state, action) => {
      state.askToUpdate = action.payload;
    },
    SET_SHOULD_UPDATE_TRACKER: (state, action) => {
      state.shouldUpdateTracker = action.payload;
    },
  },
  extraReducers: (builder) => [
    builder.addCase(asyncActions.GET_NOTIFICATIONS.pending, (state) => {
      state.isLoading = true;
    }),
    builder.addCase(
      asyncActions.GET_NOTIFICATIONS.fulfilled,
      (state, action) => {
        state.isLoading = false;

        const currentPage = action.payload.page;
        state.currentPage = currentPage;
        state.hasMoreNotifications =
          action.payload.notifications.totalPages > currentPage + 1;

        const newNotifications = action.payload.notifications.content;

        if (state.notifications && currentPage != 0) {
          const notifications = [...state.notifications];
          newNotifications.forEach((newNotification) => {
            if (
              !notifications.some(
                (notification) => notification.id === newNotification.id,
              )
            ) {
              notifications.push(newNotification);
            }
          });
          state.notifications = notifications;
          return;
        }

        state.notifications = newNotifications;
        state.askToUpdate = false;
      },
    ),
    builder.addCase(
      asyncActions.DELETE_NOTIFICATION.pending,
      (state, action) => {
        state.notifications = state.notifications?.map((item) => {
          if (item.id === action.meta.arg) {
            return { ...item, deleteStatus: NotificationDeleteStatus.Pending };
          }

          return item;
        });
      },
    ),
    builder.addCase(
      asyncActions.DELETE_NOTIFICATION.rejected,
      (state, action) => {
        state.notifications = state.notifications?.map((item) => {
          if (item.id === action.meta.arg) {
            return { ...item, deleteStatus: NotificationDeleteStatus.Error };
          }

          return item;
        });
      },
    ),
    builder.addCase(
      asyncActions.DELETE_NOTIFICATION.fulfilled,
      (state, action) => {
        state.notifications = state.notifications?.filter(
          (item) => item.id !== action.meta.arg,
        );
        state.shouldUpdateTracker = true;
      },
    ),
    builder.addCase(asyncActions.DELETE_ALL_NOTIFICATIONS.pending, (state) => {
      state.isLoading = true;
    }),
    builder.addCase(asyncActions.DELETE_ALL_NOTIFICATIONS.rejected, (state) => {
      state.isLoading = false;
      messageNotification.errorMessage(
        unhookedTranslation('notification-list-error-delete-all-title'),
        unhookedTranslation('notification-list-error-delete-all-content'),
      );
    }),
    builder.addCase(
      asyncActions.DELETE_ALL_NOTIFICATIONS.fulfilled,
      (state) => {
        state.isLoading = false;
        state.shouldUpdate = true;
        state.shouldUpdateTracker = true;
      },
    ),
    builder.addCase(
      asyncActions.MARK_NOTIFICATION_AS_VIEWED.fulfilled,
      (state, action) => {
        if (!state.notifications) {
          return;
        }

        const notificationId = action.meta.arg;

        state.notifications = state.notifications.map((notification) => {
          if (notification.id !== notificationId) {
            return notification;
          }

          return { ...notification, viewed: true };
        });
      },
    ),
    builder.addCase(
      asyncActions.MARK_ALL_NOTIFICATIONS_AS_VIEWED.pending,
      (state) => {
        state.isLoading = true;
      },
    ),
    builder.addCase(
      asyncActions.MARK_ALL_NOTIFICATIONS_AS_VIEWED.rejected,
      (state) => {
        state.isLoading = false;
        messageNotification.errorMessage(
          unhookedTranslation('notification-list-error-mark-all-read-title'),
          unhookedTranslation('notification-list-error-mark-all-read-content'),
        );
      },
    ),
    builder.addCase(
      asyncActions.MARK_ALL_NOTIFICATIONS_AS_VIEWED.fulfilled,
      (state) => {
        state.isLoading = false;

        if (!state.notifications) {
          return;
        }

        state.notifications = state.notifications.map((notification) => {
          return { ...notification, viewed: true };
        });
      },
    ),
  ],
});
