import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Row,
  Col,
  Button,
  Input,
  Drawer,
  Breadcrumb,
  Dropdown,
  Space,
  MenuProps,
  Popover,
  Form,
  Tooltip,
  Skeleton,
  Result,
  Alert,
  Modal,
} from 'antd';
import { showModal } from '../../helpers/showModal/showModal';
import '../../components/Header/Header.scss';
import './Repository.scss';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/store';
import {
  asyncActions,
  RepositoryContentTypes,
  RepositorySlice,
} from './Repository.Slice';
import {
  CopyOutlined,
  DeleteOutlined,
  DislikeTwoTone,
  DownOutlined,
  FileAddFilled,
  FolderAddFilled,
  HomeOutlined,
  LikeTwoTone,
  RightCircleOutlined,
} from '@ant-design/icons';
import { RepositoryCreateEditModal } from '../../components/RepositoryCreateEditModal/RepositoryCreateEditModal';
import { IArtifactResponse, IContent } from '../../services/repository.api';
import { messageNotification } from '../../helpers/messageNotification/messageNotification';
import { RepositoryDownloadModal } from '../../components/RepositoryDownloadModal/RepositoryDownloadModal';
import { RepositoryViewTxtModal } from '../../components/RepositoryViewTxtModal/RepositoryViewTxtModal';
import { RepositoryViewPdfModal } from '../../components/RepositoryViewPdfModal/RepositoryViewPdfModal';
import { RepositoryUploadModal } from '../../components/RepositoryUploadModal/RepositoryUploadModal';
import { RepositoryTable } from './RepositoryTable/RepositoryTable';
import { debounce } from 'lodash';
import { ReactComponent as Move } from '../../assets/popover-icons/move.svg';
import { ReactComponent as Download } from '../../assets/popover-icons/download.svg';
import { IDefaultSortBehaviour } from '../../components/PagingTable/PagingTable';
import { scrollToItemFunction } from '../../helpers/useScrollToTop/useScrollToTop';
import { Header } from '../../components/Header/Header';
import Link from 'antd/lib/typography/Link';
import { asyncPageActions } from '../PagesList/CreateReadEditPages/CreateReadEditPage.Slice';
import { RepositoryDownloadMultipleModal } from '../../components/RepositoryDownloadMultipleModal/RepositoryDownloadMultipleModal';
import { ReactComponent as TrashCan } from '../../assets/popover-icons/trash-can.svg';

// This conversion is necessary because the pages backend expects the full name of the language in uppercase, while the navbar stores the current language as an abbreviation.
const languageMap: { [key: string]: string } = {
  en: 'ENGLISH',
  es: 'SPANISH',
  pt: 'PORTUGUESE',
};
import { EvaluationModal } from '../../components/EvaluationModal/EvaluationModal';

export const Repository: React.FC = () => {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState<any>(null);
  const [searchValue, setSearchValue] = useState<string>('');
  const [openPopoverSlug, setOpenPopoverSlug] = useState<string | null>(null);
  const [isCreatingNew, setIsCreatingNew] = useState<boolean>(false);
  const [associationCompleted, setAssociationCompleted] = useState(false);

  const [evaluationRadioSelected, setEvaluationRadioSelected] = useState<
    boolean | null
  >(true);

  const navigate = useNavigate();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { Search } = Input;

  const applicationLanguage = useSelector(
    (state: RootState) => state.navBar.defaultLanguage,
  );
  const dispatch = useDispatch<AppDispatch>();
  const repositoryState = useSelector((state: RootState) => state.repository);

  const loginState = useSelector((state: RootState) => state.login);
  const isUserAdmin = loginState.user?.role === 'A';
  const userId = loginState.user?.id;

  const defaultFileId = useSelector(
    (state: RootState) => state.repository.defaultFileId,
  );

  const pageObjectResponse = useSelector(
    (state: RootState) => state.repository.pageObjectResponse,
  );

  const navbarState = useSelector((state: RootState) => state.navBar);

  const isSearching =
    useSelector(
      (state: RootState) => state.repository.searchRepositoryByName,
    ) !== '';

  const defaultLanguage = languageMap[navbarState.defaultLanguage];

  const { releaseId, fileId } = useParams();

  const abortControllerRef = useRef<AbortController>(new AbortController());

  // Check if something is loading
  const isLoading = Object.values(repositoryState.loadingState).some(
    (value) => value,
  );

  const debouncedSetRepositoryNameToSearch = useCallback(
    debounce((value) => {
      if (value.trim() === '') {
        return dispatch(
          asyncActions.CLEAR_SEARCH_REPOSITORY({
            rootId: repositoryState.rootId,
            parentId: releaseId,
            queryString: 'name',
          }),
        );
      }
      dispatch(
        asyncActions.FIND_REPOSITORY_BY_NAME({
          rootId: repositoryState.rootId,
          parentId: releaseId,
          name: value.trim(),
        }),
      );
    }, 500),
    [dispatch, repositoryState.rootId, releaseId],
  );

  useEffect(() => {
    const controller = abortControllerRef.current;
    return () => {
      controller.abort();
      dispatch(RepositorySlice.actions.RESET_TO_INITIAL_STATE());
    };
  }, []);

  useEffect(() => {
    dispatch(RepositorySlice.actions.SET_SHOULD_UPDATE(true));
  }, [releaseId]);

  useEffect(() => {
    if (isLoading || repositoryState.transferState.isTransferring) {
      return;
    }

    if (
      !releaseId ||
      !fileId ||
      !repositoryState.rootId ||
      repositoryState.errorState
    ) {
      return;
    }

    const item = repositoryState.artifacts?.content.find(
      (x: { id: number }) => x.id === +fileId,
    );
    if (!item || item.genericType !== 'FILE') {
      return;
    }

    dispatch(RepositorySlice.actions.OPEN_DOWNLOAD_MODAL(item.name));
    transferFile(+fileId, true);

    navigate(`/repositories/${releaseId}`);
  }, [fileId, releaseId, repositoryState]);

  useEffect(() => {
    if (
      !repositoryState.downloadFileAfterTransfer ||
      repositoryState.fileDownloaded ||
      !repositoryState.transferState.isTransferComplete ||
      repositoryState.errorState
    ) {
      return;
    }

    downloadTransferredFile();
    dispatch(RepositorySlice.actions.SET_FILE_DOWNLOADED(true));
  }, [repositoryState.transferState]);

  useEffect(() => {
    if (
      isLoading ||
      !repositoryState.shouldUpdate ||
      repositoryState.searchRepositoryByName ||
      repositoryState.errorState
    ) {
      return;
    }

    if (releaseId) {
      if (!repositoryState.rootId) {
        dispatch(asyncActions.GET_ROOT_ID_BY_ARTIFACT_ID(+releaseId));
      }
    } else {
      const queryString = repositoryState.repositoryFirstQueryBehaviour
        ? '&sort=name'
        : '';
      dispatch(asyncActions.GET_ALL_ARTIFACTS(queryString));
    }

    dispatch(RepositorySlice.actions.SET_SHOULD_UPDATE(false));
  }, [dispatch, repositoryState, releaseId]);

  useEffect(() => {
    if (
      !releaseId ||
      !repositoryState.rootId ||
      isLoading ||
      repositoryState.searchRepositoryByName ||
      repositoryState.errorState
    ) {
      return;
    }

    dispatch(asyncActions.GET_BREADCRUMB_BY_ID(releaseId));

    dispatch(
      asyncActions.GET_CONTENT_BY_ID({
        rootId: repositoryState.rootId,
        parentId: releaseId,
        queryString: 'name',
      }),
    );
  }, [releaseId, repositoryState.rootId, repositoryState.shouldUpdate]);

  const data = repositoryState.artifacts?.content;

  useEffect(() => {
    if (repositoryState.errorState) {
      return;
    }
    // Don't open default files if we are seaching by name or downloading something
    if (
      !defaultFileId ||
      repositoryState.searchRepositoryByName.length ||
      fileId
    ) {
      return;
    }

    const defaultFileItem = data?.find(
      (item: { id: number; genericType: string }) =>
        item.id === defaultFileId && item.genericType === 'FILE',
    );

    if (defaultFileItem) {
      return onOpenFile(defaultFileItem);
    }
  }, [defaultFileId]);

  useEffect(() => {
    if (
      !repositoryState.isMovingFilesAndArtifacts ||
      !repositoryState.isMovingArtifactsComplete ||
      !repositoryState.isMovingFilesComplete
    ) {
      return;
    }

    dispatch(RepositorySlice.actions.FINISH_MOVING_FILES_AND_ARTIFACTS());
  }, [
    repositoryState.isMovingFilesAndArtifacts,
    repositoryState.isMovingArtifactsComplete,
    repositoryState.isMovingFilesComplete,
  ]);

  // Handle navigator back button click
  useEffect(() => {
    const handlePopState = () => {
      if (!repositoryState.isAtRoot && repositoryState.breadcrumb) {
        const lastOpen =
          repositoryState.breadcrumb[repositoryState.breadcrumb?.length - 1];
        dispatch(
          RepositorySlice.actions.SET_CLICKED_ITEM_ID(
            `ARTIFACT-${lastOpen.id}`,
          ),
        );
      }
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [repositoryState.breadcrumb]);

  useEffect(() => {
    if (repositoryState.isAtRoot && repositoryState.openedRootItemId) {
      scrollToItemFunction(repositoryState.openedRootItemId);
      return;
    }

    if (!repositoryState.isAtRoot && repositoryState.openedItemId) {
      scrollToItemFunction(repositoryState.openedItemId);
      return;
    }
  }, [repositoryState.openedRootItemId, repositoryState.openedItemId, data]);

  useEffect(() => {
    const currentIdItem = Number(repositoryState.currentArtifactData?.id);

    if (pageObjectResponse && associationCompleted) {
      // Get the current list of pageIds or initialize it as an empty array
      const currentPageIds =
        repositoryState.currentArtifactData?.pages?.map(
          (page: any) => page.id,
        ) || [];

      // Check if the pageObjectResponse.id is already in the list
      const updatedPageIds = currentPageIds.includes(pageObjectResponse.id)
        ? currentPageIds // Keep the list unchanged if the ID is already present
        : [...currentPageIds, pageObjectResponse.id]; // Add the new ID

      // Update the Artifact with the modified list of pageIds
      dispatch(
        asyncActions.UPDATE_ARTIFACT_OR_RELEASE({
          rootId: repositoryState.rootId,
          id: currentIdItem,
          body: {
            ...repositoryState.currentArtifactData,
            pageIds: updatedPageIds, // PageIds updated list
          },
        }),
      );

      // Update the page by removing old artifact IDs and adding the new one
      const prevArtifacts = pageObjectResponse.artifactIds.filter(
        (artifactId: number) => artifactId !== currentIdItem,
      );

      const updatedPage = {
        ...pageObjectResponse,
        artifactIds: [...prevArtifacts, currentIdItem],
      };

      dispatch(
        asyncPageActions.UPDATE_A_PAGE({
          id: pageObjectResponse.id,
          payload: updatedPage,
        }),
      );

      // Reset the state related to the page
      dispatch(RepositorySlice.actions.RESET_PAGE_OBJECT_RESPONSE());
    }
  }, [pageObjectResponse, associationCompleted, dispatch, repositoryState]);

  useEffect(() => {
    if (
      repositoryState.shouldUpdate &&
      repositoryState.searchRepositoryByName
    ) {
      debouncedSetRepositoryNameToSearch(
        repositoryState.searchRepositoryByName,
      );
      dispatch(RepositorySlice.actions.SET_SHOULD_UPDATE(false));
    }
  }, [
    repositoryState.shouldUpdate,
    repositoryState.searchRepositoryByName,
    debouncedSetRepositoryNameToSearch,
    dispatch,
  ]);

  const formatDateColumn = (unformattedDate: string) => {
    const date = new Date(unformattedDate);

    if (isNaN(date.getTime())) {
      return;
    }

    const dateFormat = new Intl.DateTimeFormat(applicationLanguage, {
      dateStyle: 'long',
      timeStyle: 'long',
    } as Intl.DateTimeFormatOptions);

    const formattedDate = dateFormat.format(date);

    return formattedDate;
  };

  const showLateralInfo = () => {
    const createdAt =
      selectedRecord && selectedRecord.createdAt
        ? formatDateColumn(selectedRecord.createdAt)
        : undefined;

    const updatedAt =
      selectedRecord && selectedRecord.updatedAt
        ? formatDateColumn(selectedRecord.updatedAt)
        : undefined;

    return (
      <Drawer
        title={t('repository-artifact-details-label')}
        placement="right"
        onClose={() => setDrawerVisible(false)}
        open={drawerVisible}
        width={400}
      >
        {selectedRecord && (
          <>
            {selectedRecord.name && (
              <p>
                <span className="repository-details-title">
                  {t('repository-artifact-name-label')}
                </span>
                <br />
                {selectedRecord.name}
              </p>
            )}
            {selectedRecord.description && (
              <p>
                <span className="repository-details-title">
                  {t('repository-artifact-description-label')}
                </span>
                <br />
                {selectedRecord.description}
              </p>
            )}
            {selectedRecord.createdByFullName && (
              <p>
                <span className="repository-details-title">
                  {t('repository-artifact-createdBy-label')}
                </span>
                <br />
                {selectedRecord.createdByFullName}
              </p>
            )}
            {createdAt && (
              <p>
                <span className="repository-details-title">
                  {t('repository-artifact-createdAt-label')}
                </span>
                <br />
                {createdAt}
              </p>
            )}
            {updatedAt && updatedAt !== createdAt && (
              <p>
                <span className="repository-details-title">
                  {t('repository-artifact-last-modified-label')}
                </span>
                <br />
                {updatedAt}
              </p>
            )}
            {selectedRecord.contentLengthString && (
              <p>
                <span className="repository-details-title">
                  {t('repository-artifact-size-label')}
                </span>
                <br />
                {selectedRecord.contentLengthString}
              </p>
            )}
            {selectedRecord.path && (
              <p>
                <span className="repository-details-title">
                  {t('repository-artifact-location')}
                </span>
                <br />
                <Link
                  onClick={() => {
                    setSearchValue('');
                    dispatch(
                      asyncActions.CLEAR_SEARCH_REPOSITORY({
                        rootId: selectedRecord.rootArtifactId,
                        parentId: selectedRecord.parentArtifactId,
                        queryString: 'name',
                      }),
                    );
                    setDrawerVisible(false);
                    navigate(
                      `/repositories/${selectedRecord.parentArtifactId}`,
                    );
                  }}
                >
                  {selectedRecord.path}
                </Link>
              </p>
            )}
            {isSearching && (
              <Alert
                message={t('repository-sidebar-search-edit-info-title')}
                description={
                  <>
                    <p>
                      {t('repository-sidebar-search-edit-info-first-paragraph')}
                    </p>
                    <p>
                      {t(
                        'repository-sidebar-search-edit-info-second-paragraph',
                      )}
                    </p>
                  </>
                }
                type="info"
                showIcon
              />
            )}
          </>
        )}
      </Drawer>
    );
  };

  const onOpenLateralInfo = (dataInfoItem: any) => {
    setSelectedRecord(dataInfoItem);
    setDrawerVisible(true);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchValue(value);
    debouncedSetRepositoryNameToSearch(value);
  };

  const setRepositoryNameToSearch = debounce((repositoryName: string) => {
    if (repositoryName.trim() === '') {
      return dispatch(
        asyncActions.CLEAR_SEARCH_REPOSITORY({
          rootId: repositoryState.rootId,
          parentId: releaseId,
          queryString: 'name',
        }),
      );
    }
    dispatch(
      asyncActions.FIND_REPOSITORY_BY_NAME({
        rootId: repositoryState.rootId,
        parentId: releaseId,
        name: repositoryName.trim(),
      }),
    );
  }, 500);

  const dispatchToScrollRealease = (record: any) => {
    dispatch(
      RepositorySlice.actions.SET_CLICKED_ITEM_ID(
        `${record.genericType}-${record.id}`,
      ),
    );
  };

  const goToRepositoryChildren = (record: any) => {
    setSearchValue('');
    if (repositoryState.isAtRoot) {
      dispatch(
        RepositorySlice.actions.SET_CLICKED_ROOT_ITEM_ID(
          `${record.genericType}-${record.id}`,
        ),
      );
    }

    dispatch(
      asyncActions.CLEAR_SEARCH_REPOSITORY({
        rootId: repositoryState.rootId,
        parentId: releaseId,
        queryString: 'name',
      }),
    );

    navigate(`/repositories/${record.id}`);
  };

  const goToRootRepository = () => {
    dispatch(RepositorySlice.actions.SET_IS_AT_ROOT(true));
    dispatch(RepositorySlice.actions.SET_ROOT_ID(undefined));
    navigate('/repositories');
  };

  const handleCreateButtonMenuClick = (e: any) => {
    if (e.key === 'release') {
      dispatch(RepositorySlice.actions.OPEN_CREATE_RELEASE_MODAL());
    } else if (e.key === 'file') {
      dispatch(RepositorySlice.actions.OPEN_UPLOAD_MODAL());
    }
  };

  const downloadTransferredFile = () => {
    const url = window.URL.createObjectURL(
      new Blob([repositoryState.transferState.transferredData]),
    );
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      repositoryState.transferState.transferredFileName,
    );
    document.body.appendChild(link);
    link.click();
  };

  const setDefaultFile = (id: number) => {
    dispatch(
      asyncActions.SET_DEFAULT_FILE({
        releaseId: releaseId,
        id: id,
      }),
    );
  };

  const getFileDownloadUrl = (id: number, parentArtifactId?: number) => {
    const fileReleaseId = parentArtifactId ? parentArtifactId : releaseId;
    const url = `${window.location.origin}/repositories/${fileReleaseId}/download/${id}`;
    navigator.clipboard.writeText(url);

    messageNotification.successMessage(
      t('repository-download-link-copied-to-clipboard-title'),
      t('repository-download-link-copied-to-clipboard-description'),
    );
  };

  const onEditArtifact = (id: number) => {
    const item = repositoryState.artifacts?.content.find(
      (x: { id: number; genericType: string }) =>
        x.id === id && x.genericType === 'ARTIFACT',
    );
    if (!item) {
      return;
    }
    dispatch(
      RepositorySlice.actions.OPEN_EDIT_ARTIFACT_MODAL({
        object: item,
        allowNameEdit: true,
      }),
    );
  };

  const onEditRelease = (id: number) => {
    const item = repositoryState.artifacts?.content.find(
      (x: { id: number; genericType: string }) =>
        x.id === id && x.genericType === 'ARTIFACT',
    );
    if (!item) {
      return;
    }
    dispatch(
      RepositorySlice.actions.OPEN_EDIT_RELEASE_MODAL({
        object: item,
        allowNameEdit: true,
      }),
    );
  };

  const transferFile = (
    id: number,
    downloadAfterTransfer: boolean = false,
    parentArtifactId?: number,
  ) => {
    abortControllerRef.current = new AbortController();
    dispatch(
      RepositorySlice.actions.SET_DOWNLOAD_AFTER_TRANSFER(
        downloadAfterTransfer,
      ),
    );

    const rootId = parentArtifactId ? parentArtifactId : repositoryState.rootId;
    dispatch(
      asyncActions.TRANSFER_FILE({
        rootId: rootId,
        id: id,
        abortSignal: abortControllerRef.current.signal,
      }),
    );
  };

  const cancelTransfer = () => {
    abortControllerRef.current.abort();
  };

  const onDownloadFile = (
    id: number,
    filename: string,
    rootArtifactId?: number,
  ) => {
    dispatch(RepositorySlice.actions.OPEN_DOWNLOAD_MODAL(filename));
    transferFile(id, true, rootArtifactId);
  };

  const onPdfPreview = (item: any) => {
    dispatch(RepositorySlice.actions.OPEN_PREVIEW_PDF_MODAL(item.name));
    if (isSearching) {
      transferFile(item.id, false, item.rootArtifactId);
      return;
    }

    transferFile(item.id);
  };

  const onTxtPreview = (item: any) => {
    dispatch(RepositorySlice.actions.OPEN_PREVIEW_TXT_MODAL(item.name));
    if (isSearching) {
      transferFile(item.id, false, item.rootArtifactId);
      return;
    }

    transferFile(item.id);
  };

  const onOpenFile = (item: any) => {
    if (item.name.endsWith('.pdf')) {
      onPdfPreview(item);
    } else if (item.name.endsWith('.txt')) {
      onTxtPreview(item);
    }
  };

  const getIdFromString = (urlOrId: string): number | null => {
    // Get all digits from the string
    const numbers = urlOrId.match(/\d+/g);

    // If there are numbers, return the last one from the array converting it to the proper type.
    // If it fails, returns null.
    if (numbers) {
      return parseInt(numbers[numbers.length - 1]) || null;
    }

    return null;
  };

  const onMoveItem = (
    destinationUrlOrId: string,
    itemId: number,
    itemType: string,
  ): void => {
    const destinationArtifactId = getIdFromString(destinationUrlOrId);
    if (!destinationArtifactId) {
      messageNotification.errorMessage(
        t('repository-move-copy-invalid-url-title'),
        t('repository-move-copy-invalid-url-description'),
      );
      return;
    }

    if (
      repositoryState.currentArtifactId &&
      destinationArtifactId === +repositoryState.currentArtifactId
    ) {
      messageNotification.warningMessage(
        t('repository-move-copy-same-folder-title'),
        t('repository-move-copy-same-folder-description'),
      );
      return;
    }

    if (itemType === 'FILE') {
      dispatch(
        asyncActions.MOVE_FILE({
          destinationArtifactId: destinationArtifactId,
          fileId: itemId,
        }),
      );
      return;
    } else if (itemType === 'ARTIFACT') {
      dispatch(
        asyncActions.MOVE_ARTIFACT({
          destinationArtifactId: destinationArtifactId,
          artifactId: itemId,
        }),
      );
      return;
    }

    messageNotification.errorMessage(
      t('repository-move-copy-unknown-item-type-title'),
      t('repository-move-copy-unknown-item-type-description'),
    );
  };

  const onMoveItems = (
    destinationUrlOrId: string,
    fileIds: any,
    artifactIds: any,
  ) => {
    const destinationArtifactId = getIdFromString(destinationUrlOrId);
    if (!destinationArtifactId) {
      messageNotification.errorMessage(
        t('repository-move-copy-invalid-url-title'),
        t('repository-move-copy-invalid-url-description'),
      );
      return;
    }

    if (!fileIds.length && !artifactIds.length) {
      messageNotification.errorMessage(
        t('repository-move-copy-unknown-item-type-title'),
        t('repository-move-copy-unknown-item-type-description'),
      );
      return;
    }

    if (
      repositoryState.currentArtifactId &&
      destinationArtifactId === +repositoryState.currentArtifactId
    ) {
      messageNotification.warningMessage(
        t('repository-move-copy-same-folder-title'),
        t('repository-move-copy-same-folder-description'),
      );
      return;
    }

    if (fileIds.length && artifactIds.length) {
      dispatch(RepositorySlice.actions.SET_IS_MOVING_FILES_AND_ARTIFACTS(true));
    }

    if (fileIds.length) {
      dispatch(
        asyncActions.MOVE_FILES({
          destinationArtifactId: destinationArtifactId,
          fileIds: fileIds,
        }),
      );
    }

    if (artifactIds.length) {
      dispatch(
        asyncActions.MOVE_ARTIFACTS({
          destinationArtifactId: destinationArtifactId,
          artifactIds: artifactIds,
        }),
      );
    }
  };

  const onCopyItem = (
    destinationUrlOrId: string,
    itemId: number,
    itemType: string,
  ): void => {
    const destinationArtifactId = getIdFromString(destinationUrlOrId);
    if (!destinationArtifactId) {
      messageNotification.errorMessage(
        t('repository-move-copy-invalid-url-title'),
        t('repository-move-copy-invalid-url-description'),
      );
      return;
    }

    if (
      repositoryState.currentArtifactId &&
      destinationArtifactId === +repositoryState.currentArtifactId
    ) {
      messageNotification.warningMessage(
        t('repository-move-copy-same-folder-title'),
        t('repository-move-copy-same-folder-description'),
      );
      return;
    }

    if (itemType === 'FILE') {
      dispatch(
        asyncActions.COPY_FILE({
          destinationArtifactId: destinationArtifactId,
          fileId: itemId,
        }),
      );
      return;
    } else if (itemType === 'ARTIFACT') {
      dispatch(
        asyncActions.COPY_ARTIFACT({
          destinationArtifactId: destinationArtifactId,
          artifactId: itemId,
        }),
      );
      return;
    }

    messageNotification.errorMessage(
      t('repository-move-copy-unknown-item-type-title'),
      t('repository-move-copy-unknown-item-type-description'),
    );
  };

  const onCopyItems = (
    destinationUrlOrId: string,
    fileIds: any,
    artifactIds: any,
  ) => {
    const destinationArtifactId = getIdFromString(destinationUrlOrId);
    if (!destinationArtifactId) {
      messageNotification.errorMessage(
        t('repository-move-copy-invalid-url-title'),
        t('repository-move-copy-invalid-url-description'),
      );
      return;
    }

    if (!fileIds.length && !artifactIds.length) {
      messageNotification.errorMessage(
        t('repository-move-copy-unknown-item-type-title'),
        t('repository-move-copy-unknown-item-type-description'),
      );
      return;
    }

    if (
      repositoryState.currentArtifactId &&
      destinationArtifactId === +repositoryState.currentArtifactId
    ) {
      messageNotification.warningMessage(
        t('repository-move-copy-same-folder-title'),
        t('repository-move-copy-same-folder-description'),
      );
      return;
    }

    if (fileIds.length && artifactIds.length) {
      dispatch(
        RepositorySlice.actions.SET_IS_COPYING_FILES_AND_ARTIFACTS(true),
      );
    }

    if (fileIds.length) {
      dispatch(
        asyncActions.COPY_FILES({
          destinationArtifactId: destinationArtifactId,
          fileIds: fileIds,
        }),
      );
    }

    if (artifactIds.length) {
      dispatch(
        asyncActions.COPY_ARTIFACTS({
          destinationArtifactId: destinationArtifactId,
          artifactIds: artifactIds,
        }),
      );
    }
  };

  const showMoveButton = () => {
    const itemsCount = repositoryState.selectedRowsItems.length;
    if (!itemsCount || itemsCount === 1) {
      return;
    }

    const fileIds = repositoryState.selectedRowsItems
      .filter((element: any) => {
        return element.genericType === 'FILE';
      })
      .map((item: any) => {
        return item.id;
      });

    const artifactIds = repositoryState.selectedRowsItems
      .filter((element: any) => {
        return element.genericType === 'ARTIFACT';
      })
      .map((item: any) => {
        return item.id;
      });

    const itemsString =
      fileIds.length && artifactIds.length
        ? t('repository-move-copy-delete-items-button-items')
        : fileIds.length
          ? t('repository-move-copy-delete-items-button-files')
          : t('repository-move-copy-delete-items-button-artifacts');

    return (
      <Popover
        trigger="click"
        placement="bottom"
        content={
          <Form
            name="moveUrlForm"
            layout="inline"
            autoComplete="off"
            onFinish={(values) => {
              onMoveItems(values.url, fileIds, artifactIds);
            }}
          >
            <Form.Item noStyle>
              <Input.Group compact>
                <Form.Item name="url" rules={[{ required: true }]} noStyle>
                  <Input
                    style={{ width: '200px' }}
                    placeholder={t('repository-move-url-placeholder')}
                  />
                </Form.Item>
                <Form.Item noStyle>
                  <Button type="primary" htmlType="submit">
                    <RightCircleOutlined />
                  </Button>
                </Form.Item>
              </Input.Group>
            </Form.Item>
          </Form>
        }
      >
        <Button
          id="move-selected-items"
          shape="round"
          className="move-selected-button"
        >
          <Space>
            <Move style={{ width: '15px', height: '15px' }} />
            {`${t('repository-move-items-button-prefix')} ${itemsCount} ${itemsString}`}
          </Space>
        </Button>
      </Popover>
    );
  };

  const showCopyButton = () => {
    const itemsCount = repositoryState.selectedRowsItems.length;
    if (!itemsCount || itemsCount === 1) {
      return;
    }

    const fileIds = repositoryState.selectedRowsItems
      .filter((element: any) => {
        return element.genericType === 'FILE';
      })
      .map((item: any) => {
        return item.id;
      });

    const artifactIds = repositoryState.selectedRowsItems
      .filter((element: any) => {
        return element.genericType === 'ARTIFACT';
      })
      .map((item: any) => {
        return item.id;
      });

    const itemsString =
      fileIds.length && artifactIds.length
        ? t('repository-move-copy-delete-items-button-items')
        : fileIds.length
          ? t('repository-move-copy-delete-items-button-files')
          : t('repository-move-copy-delete-items-button-artifacts');

    return (
      <Popover
        trigger="click"
        placement="bottom"
        content={
          <Form
            name="moveUrlForm"
            layout="inline"
            autoComplete="off"
            onFinish={(values) => {
              onCopyItems(values.url, fileIds, artifactIds);
            }}
          >
            <Form.Item noStyle>
              <Input.Group compact>
                <Form.Item name="url" rules={[{ required: true }]} noStyle>
                  <Input
                    style={{ width: '200px' }}
                    placeholder={t('repository-move-url-placeholder')}
                  />
                </Form.Item>
                <Form.Item noStyle>
                  <Button type="primary" htmlType="submit">
                    <RightCircleOutlined />
                  </Button>
                </Form.Item>
              </Input.Group>
            </Form.Item>
          </Form>
        }
      >
        <Button
          id="copy-selected-items"
          shape="round"
          className="copy-selected-button"
        >
          <Space>
            <CopyOutlined
              style={{ fontSize: '15px', color: 'rgb(65, 180, 210)' }}
            />
            {`${t('repository-copy-items-button-prefix')} ${itemsCount} ${itemsString}`}
          </Space>
        </Button>
      </Popover>
    );
  };

  const showDeleteButton = () => {
    const itemsCount = repositoryState.selectedRowsItems.length;
    if (!itemsCount || itemsCount === 1) {
      return;
    }

    const hasFiles = repositoryState.selectedRowsItems.some(
      (item: any) => item.genericType === 'FILE',
    );

    const hasArtifacts = repositoryState.selectedRowsItems.some(
      (item: any) => item.genericType === 'ARTIFACT',
    );

    const itemsString =
      hasFiles && hasArtifacts
        ? t('repository-move-copy-delete-items-button-items')
        : hasFiles
          ? t('repository-move-copy-delete-items-button-files')
          : t('repository-move-copy-delete-items-button-artifacts');

    const itemsToBeDeleted = repositoryState.selectedRowsItems.reduce(
      (acc: any, item: any) => {
        acc[item.id] = item.genericType;
        return acc;
      },
      {} as { [key: number]: string },
    );

    const deleteType =
      hasFiles && hasArtifacts
        ? RepositoryContentTypes.Multiple
        : hasFiles
          ? RepositoryContentTypes.File
          : RepositoryContentTypes.Artifact;

    return (
      <Button
        id="delete-selected-items"
        shape="round"
        className="delete-selected-button"
        onClick={() => {
          Modal.confirm({
            centered: true,
            closable: true,
            title: `${t('repository-delete-items-button-prefix')} ${itemsString}`,
            content: (
              <>
                <p>
                  <b>
                    {itemsCount} {itemsString}{' '}
                    {t('repository-delete-items-suffix')}
                  </b>
                </p>
                <p>{t('repository-delete-items-confirm')}</p>
              </>
            ),
            cancelText: 'Cancelar',
            okText: 'Apagar',
            onOk: () => {
              dispatch(RepositorySlice.actions.SET_DELETE_TYPE(deleteType));
              dispatch(
                asyncActions.DELETE_MULTIPLE_DATA({
                  rootId: repositoryState.rootId,
                  itemsToBeDeleted: itemsToBeDeleted,
                }),
              );
            },
          });
        }}
      >
        <Space>
          <DeleteOutlined
            style={{ fontSize: '15px', color: 'rgb(65, 180, 210)' }}
          />
          {`${t('repository-delete-items-button-prefix')} ${itemsCount} ${itemsString}`}
        </Space>
      </Button>
    );
  };

  const showDownloadButton = () => {
    if (
      !repositoryState.selectedRowsItems ||
      repositoryState.selectedRowsItems.length === 0
    ) {
      return;
    }

    const files = repositoryState.selectedRowsItems
      .filter((element: any) => {
        return element.genericType === 'FILE';
      })
      .map((item: any) => {
        return {
          id: item.id,
          parentArtifactId: item.parentArtifactId,
          rootArtifactId: item.rootArtifactId,
          name: item.name,
        };
      });

    const fileCount = files?.length;

    if (fileCount <= 1) {
      return;
    }

    return (
      <Button
        id="download-selected-files"
        shape="round"
        className="download-selected-button"
        onClick={() => {
          dispatch(RepositorySlice.actions.OPEN_DOWNLOAD_MULTIPLE_MODAL(files));
        }}
      >
        <Space>
          <Download style={{ width: '15px', height: '15px' }} />
          {`${t('repository-download-multiple-files-button-prefix')} ${fileCount} ${t('repository-download-multiple-files-button-sufix')}`}
        </Space>
      </Button>
    );
  };

  const showCreateButton = () => {
    if (!isUserAdmin && repositoryState.isAtRoot) {
      return;
    }

    if (isUserAdmin && repositoryState.isAtRoot) {
      return (
        <Button
          id="create-repository-button"
          name="new-repository"
          type="primary"
          shape="round"
          ghost
          onClick={() =>
            dispatch(RepositorySlice.actions.OPEN_CREATE_ARTIFACT_MODAL())
          }
          disabled={isLoading || isSearching || repositoryState.errorState}
        >
          {t('repository-create-button')}
        </Button>
      );
    }

    if (
      !isUserAdmin &&
      !repositoryState.currentArtifactPermissions.includes('WRITE')
    ) {
      return;
    }

    const items: MenuProps['items'] = [
      {
        label: t('repository-create-release-button'),
        key: 'release',
        icon: <FolderAddFilled />,
      },
      {
        label: t('repository-create-file-button'),
        key: 'file',
        icon: <FileAddFilled />,
      },
    ];

    const menuProps = {
      items,
      onClick: handleCreateButtonMenuClick,
    };

    return (
      <Dropdown
        menu={menuProps}
        disabled={isLoading || isSearching || repositoryState.errorState}
      >
        <Button type="primary" shape="round" ghost>
          <Space>
            {t('repository-create-button')}
            <DownOutlined />
          </Space>
        </Button>
      </Dropdown>
    );
  };

  const showActionButtons = () => {
    if (repositoryState.errorState || !loginState.loggedIn) {
      return;
    }

    return (
      <>
        {showDownloadButton()}
        {showMoveButton()}
        {showCopyButton()}
        {showDeleteButton()}
        {showCreateButton()}
      </>
    );
  };

  const showSearchInputAndActionButtons = () => {
    return (
      <Col span={22} className="repository-buttons-col">
        <Col>
          <Search
            type="text"
            name="search-button"
            className="search-button"
            placeholder={t(`repository-listing-search`)}
            onSearch={(x) => setRepositoryNameToSearch(x)}
            onChange={handleSearchChange}
            value={searchValue}
            disabled={repositoryState.errorState}
          />
        </Col>
        {showActionButtons()}
      </Col>
    );
  };

  const handleBreadCrumbClick = (item: any) => {
    const breadCrumbIndex =
      repositoryState.breadcrumb &&
      repositoryState.breadcrumb.findIndex((x) => x.id === item.id);

    if (breadCrumbIndex === 0 && repositoryState.breadcrumb) {
      const firtsItem = {
        id: repositoryState.breadcrumb[1].id,
        name: repositoryState.breadcrumb[1].name,
        genericType: 'ARTIFACT',
      };
      dispatchToScrollRealease(firtsItem);
    }
    if (breadCrumbIndex && repositoryState.breadcrumb) {
      const next = repositoryState.breadcrumb[breadCrumbIndex + 1];
      const nextItem = {
        id: next.id,
        name: next.name,
        genericType: 'ARTIFACT',
      };
      dispatchToScrollRealease(nextItem);
    }
    goToRepositoryChildren(item);
  };

  const showBreadcrumb = () => {
    if (!releaseId || !repositoryState.breadcrumb) {
      return;
    }

    return (
      <Row>
        <Col span={24} className="repository-breadcrumb-col">
          <Breadcrumb separator=">">
            <Breadcrumb.Item
              className="repository-breadcrumb-link"
              onClick={() => goToRootRepository()}
            >
              <HomeOutlined />
            </Breadcrumb.Item>
            {repositoryState.breadcrumb.map((item) => {
              const isCurrentFolder =
                item.name === repositoryState.currentArtifactData?.name;

              return (
                <Breadcrumb.Item
                  key={`bc-${item.id}`}
                  onClick={() => {
                    if (!isCurrentFolder) {
                      handleBreadCrumbClick(item);
                    }
                  }}
                >
                  <span
                    style={{ fontWeight: isCurrentFolder ? 'bold' : '' }}
                    className={
                      isCurrentFolder
                        ? 'repository-breadcrumb-link-current'
                        : 'repository-breadcrumb-link'
                    }
                  >
                    {item.name}
                  </span>
                </Breadcrumb.Item>
              );
            })}
          </Breadcrumb>
        </Col>
      </Row>
    );
  };

  const defaultSortBehaviour: IDefaultSortBehaviour = {
    columnProperty: 'name',
    order: 'ascend',
    disableDefaultSortBehaviour: () => {
      if (repositoryState.repositoryFirstQueryBehaviour) {
        dispatch(
          RepositorySlice.actions.REPOSITORY_DISABLE_DEFAULT_SORT_BEHAVIOUR(),
        );
      }
    },
  };

  const dispatchSortBy = (sortBy: string) => {
    if (!repositoryState.isAtRoot) {
      dispatch(
        asyncActions.GET_CONTENT_BY_ID({
          rootId: repositoryState.rootId,
          parentId: releaseId,
          queryString: sortBy,
        }),
      );
      return;
    }
    dispatch(asyncActions.GET_ALL_ARTIFACTS(sortBy));
  };

  const onChangeSortRepository = (
    field: string | string[],
    order: string | undefined | null,
  ) => {
    let sortBy = '';

    if (!order || !field) {
      return dispatchSortBy(sortBy);
    }

    if (defaultSortBehaviour) {
      defaultSortBehaviour.disableDefaultSortBehaviour();
    }

    const fieldToQueryParam = Array.isArray(field) ? field[0] : field;

    switch (fieldToQueryParam) {
      case 'updatedAt':
        sortBy =
          order === 'ascend' ? fieldToQueryParam : `${fieldToQueryParam},desc`;
        return dispatchSortBy(sortBy);

      default:
        sortBy =
          order === 'ascend'
            ? `${fieldToQueryParam},ignoreCase`
            : `${fieldToQueryParam},desc,ignoreCase`;
        return dispatchSortBy(sortBy);
    }
  };

  const onEvaluation = (record: any) => {
    const isEditingEvaluation =
      record.artifactReviewDTO || record.fileReviewDTO;
    if (isEditingEvaluation) {
      dispatch(
        RepositorySlice.actions.OPEN_EDIT_EVALUATION_MODAL({ object: record }),
      );
    } else {
      dispatch(RepositorySlice.actions.OPEN_EVALUATION_MODAL(record));
    }
  };

  const onCreateEvaluation = (
    evaluationValues: any,
    itemType: string,
    itemId: number,
  ) => {
    dispatch(RepositorySlice.actions.CLOSE_EVALUATION_MODAL());
    if (itemType === 'FILE') {
      dispatch(
        asyncActions.NEW_FILE_EVALUATION({
          evaluationValues,
          userId,
          fileId: itemId,
        }),
      );
    }

    if (itemType === 'ARTIFACT') {
      dispatch(
        asyncActions.NEW_ARTIFACT_EVALUATION({
          liked: evaluationValues.liked,
          folderDescription: evaluationValues.folderDescription,
          folderOrganization: evaluationValues.folderOrganization,
          locationOfDocuments: evaluationValues.locationOfDocuments,
          description: evaluationValues.description,
          userId,
          artifactId: itemId,
        }),
      );
    }
  };

  const onEditEvaluation = (
    evaluationValues: any,
    itemType: string,
    itemId: number,
  ) => {
    dispatch(RepositorySlice.actions.CLOSE_EDIT_EVALUATION_MODAL());
    if (itemType === 'FILE') {
      dispatch(
        asyncActions.UPDATE_FILE_EVALUATION({
          evaluationValues,
          userId,
          fileId: itemId,
        }),
      );
    }

    if (itemType === 'ARTIFACT') {
      dispatch(
        asyncActions.UPDATE_ARTIFACT_EVALUATION({
          evaluationValues,
          userId,
          artifactId: itemId,
        }),
      );
    }
    dispatch(RepositorySlice.actions.REMOVE_EDIT_EVALUATION_MODE());
  };

  const onDeleteEvaluation = (
    itemType: string,
    itemId: number,
    isEditing: boolean,
  ) => {
    if (itemType === 'FILE') {
      dispatch(
        asyncActions.DELETE_FILE_EVALUATION({
          userId,
          fileId: itemId,
        }),
      );
    }

    if (itemType === 'ARTIFACT') {
      dispatch(
        asyncActions.DELETE_ARTIFACT_EVALUATION({
          userId,
          artifactId: itemId,
        }),
      );
    }

    if (isEditing) {
      dispatch(RepositorySlice.actions.CLOSE_EDIT_EVALUATION_MODAL());
      dispatch(RepositorySlice.actions.REMOVE_EDIT_EVALUATION_MODE());
      return;
    }
    dispatch(RepositorySlice.actions.CLOSE_EVALUATION_MODAL());
  };

  const showEvaluationModal = () => {
    const isEditing = repositoryState.isEditingEvaluation;
    const item = isEditing
      ? repositoryState.evaluationToEdit
      : repositoryState.itemToEvaluate;

    if (!item) {
      return;
    }

    const isOpen = isEditing
      ? repositoryState.openEditEvaluationModal
      : repositoryState.openEvaluationModal;

    const evaluationValues =
      item.genericType === 'ARTIFACT'
        ? repositoryState?.evaluationToEdit?.artifactReviewDTO
        : repositoryState?.evaluationToEdit?.fileReviewDTO;

    return (
      <EvaluationModal
        isOpen={isOpen}
        isEditing={isEditing}
        title={`${t('evaluation-modal-title')} ${item.name}`}
        okText={t('evaluation-modal-save-button')}
        cancelText={t('evaluation-modal-cancel-button')}
        deleteText={t('evaluation-modal-delete-button')}
        requiredText={t('evaluation-modal-required-label')}
        onCancel={() => {
          dispatch(RepositorySlice.actions.REMOVE_EDIT_EVALUATION_MODE());
          if (isEditing) {
            dispatch(RepositorySlice.actions.CLOSE_EDIT_EVALUATION_MODAL());
            return;
          }
          dispatch(RepositorySlice.actions.CLOSE_EVALUATION_MODAL());
        }}
        itemType={item.genericType}
        itemId={item.id}
        defaultEvaluationRadio={
          isEditing && evaluationValues
            ? evaluationValues.liked
            : evaluationRadioSelected
        }
        onCreateEvaluation={(values: any, itemType: string, itemId: number) =>
          onCreateEvaluation(values, itemType, itemId)
        }
        onEditEvaluation={(values: any, itemType: string, itemId: number) =>
          onEditEvaluation(values, itemType, itemId)
        }
        onDeleteEvaluation={(itemType: string, itemId: number) =>
          onDeleteEvaluation(itemType, itemId, isEditing)
        }
        isUserAdmin={isUserAdmin}
        evaluationValues={isEditing ? evaluationValues : undefined}
      />
    );
  };

  const showRepositoryTable = () => {
    if (repositoryState.errorState) {
      return (
        <Result
          status="error"
          title={t('repository-load-error-title')}
          subTitle={t('repository-load-error-content')}
        />
      );
    }

    return (
      <Skeleton loading={!data}>
        <Row>
          <Col span={24}>
            <RepositoryTable
              emptyText={t('repository-empty-table')}
              dataSource={data!}
              isSearching={isSearching}
              tableLoading={isLoading}
              onChangeSort={onChangeSortRepository}
              rowClickAction={(record: any) => {
                if (record.genericType === 'FILE') {
                  onOpenFile(record);
                  return;
                }
                goToRepositoryChildren(record);
              }}
              selectRowsToGivenTab={(rows: any) => {
                dispatch(RepositorySlice.actions.SET_SELECTED_ROWS_ITEMS(rows));
              }}
              selectIdFromRowsSelected={() => {}}
              onMoveItem={onMoveItem}
              onCopyItem={onCopyItem}
              disableOptionsColumn={!repositoryState.isAtRoot}
              onOpenLateralInfo={onOpenLateralInfo}
              onEditArtifact={onEditArtifact}
              onEditRelease={onEditRelease}
              defaultFileId={defaultFileId}
              setDefaultFile={setDefaultFile}
              getFileDownloadUrl={getFileDownloadUrl}
              downloadFile={onDownloadFile}
              deleteItem={showDeleteModal}
              formatDateColumn={formatDateColumn}
              defaultSortBehaviour={defaultSortBehaviour}
              isUserAdmin={isUserAdmin}
              onEvaluation={onEvaluation}
            />
          </Col>
        </Row>
      </Skeleton>
    );
  };

  const showCreateModal = () => {
    return (
      <RepositoryCreateEditModal
        open={repositoryState.openCreateModal}
        allowNameEdit={true}
        title={
          repositoryState.creatEditType === RepositoryContentTypes.Artifact
            ? t('repository-create-artifact-modal-title')
            : t('repository-create-release-modal-title')
        }
        okText={t('repository-create-edit-modal-save-label')}
        cancelText={t('repository-create-edit-modal-cancel-label')}
        nameText={t('repository-create-edit-modal-name-label')}
        descriptionText={t(
          'repository-create-edit-modal-description-label-max-length',
        )}
        confirmLoading={repositoryState.isCreatingOrSavingArtifactOrRelease}
        onCancel={() => {
          dispatch(RepositorySlice.actions.CLOSE_CREATE_MODAL());
        }}
        onOk={(values) => {
          if (
            repositoryState.creatEditType === RepositoryContentTypes.Artifact
          ) {
            dispatch(
              asyncActions.NEW_ARTIFACT({
                name: values.name,
                description: values.description,
                publicAccess: false,
              }),
            );
          } else {
            dispatch(
              asyncActions.NEW_RELEASE({
                rootId: repositoryState.rootId,
                id: releaseId,
                body: {
                  name: values.name,
                  description: values.description,
                },
              }),
            );
          }
        }}
      />
    );
  };

  const showEditModal = () => {
    const item = repositoryState.objectToEdit;
    if (!item) {
      return;
    }

    return (
      <RepositoryCreateEditModal
        open={repositoryState.openEditModal}
        allowNameEdit={repositoryState.editModalAllowNameEdit}
        title={
          repositoryState.creatEditType === RepositoryContentTypes.Artifact
            ? t('repository-edit-artifact-modal-title')
            : t('repository-edit-release-modal-title')
        }
        okText={t('repository-create-edit-modal-save-label')}
        cancelText={t('repository-create-edit-modal-cancel-label')}
        nameText={t('repository-create-edit-modal-name-label')}
        descriptionText={t(
          'repository-create-edit-modal-description-label-max-length',
        )}
        confirmLoading={repositoryState.isCreatingOrSavingArtifactOrRelease}
        currentName={item.name}
        currentDescription={item.description}
        onCancel={() => {
          dispatch(RepositorySlice.actions.CLOSE_EDIT_MODAL());
        }}
        onOk={(values) => {
          const name = repositoryState.editModalAllowNameEdit
            ? values.name
            : item.name;
          // File editing is not supported
          if (repositoryState.creatEditType === RepositoryContentTypes.File) {
            return;
          }

          let itemToBeUpdatedBody: IArtifactResponse | IContent | undefined =
            undefined;
          let itemRootId: number | undefined = undefined;

          if (
            repositoryState.creatEditType === RepositoryContentTypes.Artifact
          ) {
            itemToBeUpdatedBody = item as IArtifactResponse;
            itemRootId = item.id;
          } else {
            itemToBeUpdatedBody = item as IContent;
            itemRootId = itemToBeUpdatedBody.rootArtifactId;
          }

          dispatch(
            asyncActions.UPDATE_ARTIFACT_OR_RELEASE({
              rootId: itemRootId,
              id: item.id,
              body: {
                ...itemToBeUpdatedBody,
                name: name,
                description: values.description,
              },
              type: repositoryState.creatEditType,
            }),
          );
        }}
      />
    );
  };

  const showDeleteModal = (id: number) => {
    const item = repositoryState.artifacts?.content.find(
      (x: { id: number }) => x.id === id,
    );
    if (!item) {
      return;
    }

    const deleteType =
      item.genericType === 'FILE'
        ? RepositoryContentTypes.File
        : repositoryState.isAtRoot
          ? RepositoryContentTypes.Artifact
          : RepositoryContentTypes.Release;

    const modalTitle =
      deleteType === RepositoryContentTypes.File
        ? 'repository-file-delete-modal-title'
        : repositoryState.isAtRoot
          ? 'repository-artifact-delete-modal-title'
          : 'repository-release-delete-modal-title';

    showModal(
      'warning',
      `${t(modalTitle)}`,
      `${t('repository-artifact-delete-modal-label')}`,
      () => {
        dispatch(RepositorySlice.actions.SET_DELETE_TYPE(deleteType));
        // FIXME: not sure about the rootId
        dispatch(
          asyncActions.DELETE_DATA({
            rootId: repositoryState.isAtRoot ? item.id : repositoryState.rootId,
            id: item.id,
            type: item.genericType,
          }),
        );
        if (searchValue) {
          return setRepositoryNameToSearch(searchValue);
        }
      },
      () => {},
      `${t('repository-delete-modal-delete-label')}`,
      `${t('repository-delete-modal-cancel-label')}`,
    );
  };

  const showDownloadModal = () => {
    return (
      <RepositoryDownloadModal
        open={repositoryState.openDownloadModal}
        title={t('repository-download-modal-title')}
        okText={t('repository-download-modal-close-label')}
        cancelText={t('repository-download-modal-cancel-label')}
        fileName={repositoryState.downloadingFileName}
        percent={repositoryState.transferState.transferProgress}
        loading={repositoryState.transferState.isTransferring}
        onOk={() => {
          dispatch(RepositorySlice.actions.CLOSE_DOWNLOAD_MODAL());
        }}
        onCancel={() => {
          cancelTransfer();
          dispatch(RepositorySlice.actions.CLOSE_DOWNLOAD_MODAL());
        }}
      />
    );
  };

  const showDownloadMultipleModal = () => {
    return (
      <RepositoryDownloadMultipleModal
        open={repositoryState.openDownloadMultipleModal}
        title={t('repository-download-multiple-files-modal-title')}
        okText={t('repository-download-multiple-files-close-label')}
        cancelText={t('repository-download-multiple-files-cancel-label')}
        files={repositoryState.downloadingMultipleFiles}
        applicationLanguage={applicationLanguage}
        onClose={() => {
          dispatch(RepositorySlice.actions.CLOSE_DOWNLOAD_MULTIPLE_MODAL());
        }}
      />
    );
  };

  const showUploadModal = () => {
    return (
      <RepositoryUploadModal
        open={repositoryState.openUploadModal}
        title={t('repository-upload-modal-title')}
        okText={t('repository-upload-modal-close-label')}
        cancelText={t('repository-upload-modal-cancel-label')}
        draggerText={t('repository-upload-modal-dragger-text')}
        draggerHintText={t('repository-upload-modal-dragger-description')}
        loading={repositoryState.transferState.isTransferring}
        rootId={repositoryState.rootId}
        releaseId={+releaseId!}
        onClose={() => {
          dispatch(RepositorySlice.actions.CLOSE_UPLOAD_MODAL());
        }}
      />
    );
  };

  const showPreviewPdfModal = () => {
    return (
      <RepositoryViewPdfModal
        open={repositoryState.openPreviewPdfModal}
        fileName={repositoryState.downloadingFileName}
        title={t('repository-preview-modal-title')}
        downloadText={t('repository-preview-modal-download-label')}
        closeText={t('repository-preview-modal-close-label')}
        cancelText={t('repository-preview-modal-cancel-label')}
        progress={repositoryState.transferState.transferProgress}
        loading={repositoryState.transferState.isTransferring}
        onOk={() => {
          downloadTransferredFile();
        }}
        onCancel={() => {
          cancelTransfer();
          dispatch(RepositorySlice.actions.CLOSE_PREVIEW_PDF_MODAL());
        }}
        fileData={repositoryState.transferState.transferredData}
      />
    );
  };

  const showPreviewTxtModal = () => {
    return (
      <RepositoryViewTxtModal
        open={repositoryState.openPreviewTxtModal}
        fileName={repositoryState.downloadingFileName}
        title={t('repository-preview-modal-title')}
        downloadText={t('repository-preview-modal-download-label')}
        closeText={t('repository-preview-modal-close-label')}
        cancelText={t('repository-preview-modal-cancel-label')}
        progress={repositoryState.transferState.transferProgress}
        loading={repositoryState.transferState.isTransferring}
        onOk={() => {
          downloadTransferredFile();
        }}
        onCancel={() => {
          cancelTransfer();
          dispatch(RepositorySlice.actions.CLOSE_PREVIEW_TXT_MODAL());
        }}
        fileData={repositoryState.transferState.transferredData}
      />
    );
  };

  const handleOpenChange = (
    newOpen: boolean,
    slug?: string | null,
    createNew = false,
  ) => {
    if (newOpen) {
      setOpenPopoverSlug(slug || null);
      setIsCreatingNew(createNew); // Define if the popover is for creating a new association
      setAssociationCompleted(false);
    } else {
      setOpenPopoverSlug(null);
      setIsCreatingNew(false); // Reset the state
    }
  };

  const verifyUrl = (url: string): boolean => {
    // Regular expression to check if the URL starts with "http" or "https" and ends with "/pages/:slug"
    const urlPattern = /^http.*\/pages\/[^\s]+$/;
    return urlPattern.test(url);
  };

  const documentationIsAlreadyAssociated = (slug: string) => {
    // Get the list of associated documents
    const associatedDocuments =
      repositoryState?.currentArtifactData?.pages || [];

    const documentationAlreadyAssociated = associatedDocuments.find(
      (doc: any) => doc.slug === slug,
    )
      ? true
      : false;
    if (documentationAlreadyAssociated) {
      messageNotification.errorMessage(
        t('repository-documentation-association-duplicated-url-title'),
        t('repository-documentation-association-duplicated-url-description'),
      );
      return;
    }
    return documentationAlreadyAssociated;
  };

  const onAssociationDocumentation = (urlOrSlug: string) => {
    const isAnUrl = verifyUrl(urlOrSlug);
    const duplicateLength = (urlOrSlug.match(/https?/g) || []).length;
    if (duplicateLength > 1) {
      messageNotification.errorMessage(
        t('repository-documentation-association-invalid-url-title'),
        t('repository-documentation-association-invalid-url-description'),
      );
      return;
    }
    documentationIsAlreadyAssociated(urlOrSlug);

    if (isAnUrl) {
      const slug = urlOrSlug.slice(urlOrSlug.lastIndexOf('/pages/') + 7);
      documentationIsAlreadyAssociated(slug);

      dispatch(
        asyncActions.GET_PAGE_BY_SLUG({
          slug,
        }),
      );
      return;
    }

    const slug = urlOrSlug.trim();
    dispatch(
      asyncActions.GET_PAGE_BY_SLUG({
        slug,
      }),
    );
  };

  const removeDocumentAssociation = (slug: string) => {
    // Get the current artifact ID
    const currentIdItem = repositoryState.currentArtifactData?.id;
    if (!currentIdItem || !repositoryState.currentArtifactData) {
      return;
    }

    // Check if 'pages' is defined
    if (!repositoryState.currentArtifactData.pages) {
      return;
    }

    // Find the page corresponding to the slug
    const pageData = repositoryState.currentArtifactData.pages.find(
      (page: any) => page.slug === slug,
    );
    if (!pageData) {
      return;
    }

    // Update the artifact by removing the page from the 'pages' field
    const updatedPages = repositoryState.currentArtifactData.pages.filter(
      (page: any) => page.slug !== slug,
    );

    const updatedArtifact = {
      ...repositoryState.currentArtifactData,
      pageIds: updatedPages.map((page: { id: number }) => page.id),
    };

    dispatch(
      asyncActions.UPDATE_ARTIFACT_OR_RELEASE({
        rootId: repositoryState.rootId,
        id: currentIdItem,
        body: updatedArtifact,
      }),
    );

    // Update the page by removing the reference to the artifact
    const updatedArtifactIdsList = pageData.artifactsIds?.filter(
      (artifactId: number) => artifactId !== currentIdItem,
    );

    const updatedPage = {
      ...pageData,
      artifactsIds: updatedArtifactIdsList ?? [],
    };

    dispatch(
      asyncPageActions.UPDATE_A_PAGE({
        id: pageData.id,
        payload: updatedPage,
      }),
    );

    // Reset the state related to the page
    dispatch(RepositorySlice.actions.RESET_PAGE_OBJECT_RESPONSE());
  };

  const associationPopoverContent = (
    slug: string | null,
    isCreating: boolean,
  ) => {
    return (
      <Form
        form={form}
        name={`associationUrlForm-${isCreating ? 'create-new' : slug}`}
        layout="inline"
        autoComplete="off"
        onFinish={(values) => {
          onAssociationDocumentation(values.url);
          setOpenPopoverSlug(null); // Close the popover upon completion
          setIsCreatingNew(false); // Reset the creation state
          setAssociationCompleted(true); // Mark the association as completed
          form.resetFields();
        }}
      >
        <Form.Item noStyle>
          <Input.Group compact>
            <Form.Item name="url" rules={[{ required: true }]} noStyle>
              <Input
                style={{ width: '200px' }}
                placeholder={t(
                  'repository-documentation-association-url-placeholder',
                )}
              />
            </Form.Item>
            <Form.Item noStyle>
              <Button type="primary" htmlType="submit">
                <RightCircleOutlined />
              </Button>
            </Form.Item>
          </Input.Group>
        </Form.Item>
      </Form>
    );
  };

  const goToEvaluations = (evaluatedItem: any) => {
    navigate('/evaluations', {
      state: {
        itemId: evaluatedItem.id,
        itemTitle: evaluatedItem.name,
        itemType: evaluatedItem.genericType,
      },
    });
  };

  const showEvaluationOnHeader = () => {
    const evaluateItem = {
      ...repositoryState.currentArtifactData,
      genericType: 'ARTIFACT',
    };

    if (isUserAdmin) {
      const hasEvaluations =
        evaluateItem &&
        Array.isArray(evaluateItem.listArtifactReviewsDTOs) &&
        evaluateItem.listArtifactReviewsDTOs.length > 0;
      if (hasEvaluations) {
        return (
          <Col style={{ marginTop: '2rem' }}>
            <Button
              type="primary"
              shape="round"
              ghost
              onClick={() => goToEvaluations(evaluateItem)}
            >
              {t('repository-evaluation-button-label')}
            </Button>
          </Col>
        );
      }

      return (
        <Col style={{ marginTop: '2rem' }}>
          <Row style={{ fontWeight: 'bold' }}>
            {t('repository-evaluation-evaluations-label')}
          </Row>
          <Row>{t('repository-evaluation-not-evaluated-yet-label')}</Row>
        </Col>
      );
    }

    const isEvaluationRecordTrue = evaluateItem.artifactReviewDTO
      ? evaluateItem.artifactReviewDTO?.liked
      : null;

    return (
      <Col style={{ marginTop: '2rem' }}>
        <p>
          {evaluateItem.artifactReviewDTO
            ? t('repository-evaluation-already-evaluted-subtitle')
            : t('repository-evaluation-subtitle')}
        </p>
        <Row align="middle">
          <Button
            type={isEvaluationRecordTrue ? 'primary' : 'default'}
            className="evaluation-button"
            onClick={() => {
              if (evaluateItem.artifactReviewDTO) {
                dispatch(RepositorySlice.actions.SET_EDIT_EVALUATION_MODE());
              }
              setEvaluationRadioSelected(true);
              onEvaluation(evaluateItem);
            }}
          >
            <LikeTwoTone /> {t('repository-evaluation-liked-button')}
          </Button>
          <Button
            type={
              isEvaluationRecordTrue || isEvaluationRecordTrue === null
                ? 'default'
                : 'primary'
            }
            className="evaluation-button"
            onClick={() => {
              if (evaluateItem.artifactReviewDTO) {
                dispatch(RepositorySlice.actions.SET_EDIT_EVALUATION_MODE());
              }
              setEvaluationRadioSelected(false);
              onEvaluation(evaluateItem);
            }}
          >
            <DislikeTwoTone /> {t('repository-evaluation-disliked-button')}
          </Button>
        </Row>
      </Col>
    );
  };

  const showHeader = () => {
    if (repositoryState.isAtRoot) {
      return (
        <Header title={t('header-repository')} subtitle="" showEllipsis>
          <Row>{t('repository-subtitle')}</Row>
        </Header>
      );
    }

    // Get the list of associated documents
    const associatedDocuments =
      repositoryState?.currentArtifactData?.pages || [];

    return (
      <Row className="Header">
        <Col className="left-column" span={16}>
          <Row>
            <Col className="left-column-header" span={24}>
              <hr className="left-column-header-divider" />
              <Tooltip
                title={repositoryState.currentArtifactData?.name}
                placement="topLeft"
                overlayStyle={{ maxWidth: '50vw' }}
              >
                <h3 className="left-column-header-title">
                  {repositoryState.currentArtifactData?.name}
                </h3>
              </Tooltip>
              {showBreadcrumb()}
            </Col>
          </Row>
          <Row>
            <Col span={16}>
              <Space>
                <span className="repository-description-title">
                  {t('repository-description-title')}
                </span>
                {(isUserAdmin ||
                  repositoryState.currentArtifactPermissions.includes(
                    'WRITE',
                  )) && (
                  <Link
                    disabled={
                      isLoading || isSearching || repositoryState.errorState
                    }
                    onClick={() => {
                      dispatch(
                        RepositorySlice.actions.OPEN_EDIT_RELEASE_MODAL({
                          object: repositoryState.currentArtifactData,
                          allowNameEdit: false,
                        }),
                      );
                    }}
                  >
                    {t('repository-description-edit-button-title')}
                  </Link>
                )}
              </Space>
            </Col>
            <Col span={16}>
              {repositoryState.currentArtifactData &&
              repositoryState.currentArtifactData.description
                ? repositoryState.currentArtifactData.description
                : t('repository-description-not-available')}
            </Col>
          </Row>
          <Row>{showEvaluationOnHeader()}</Row>
        </Col>
        <Col className="right-column" span={8}>
          <div className="repository-header-documentation">
            <Row align="middle" style={{ fontWeight: 'bold' }}>
              {t('repository-documentation-association-title')}
            </Row>
            <div className="associated-documents-scrollable">
              {!associatedDocuments.length &&
                t('repository-any-documentation-associated')}
              {associatedDocuments.length > 0 &&
                associatedDocuments.map(
                  (
                    doc: {
                      pageContents: any[];
                      defaultLanguage: any;
                      slug: string;
                    },
                    index: React.Key | null | undefined,
                  ): React.JSX.Element => {
                    let associatedDocumentContent = undefined;
                    if (doc.pageContents) {
                      const pageContentsInUserLanguage = doc.pageContents.find(
                        (page: any) => page.language === defaultLanguage,
                      );
                      if (pageContentsInUserLanguage) {
                        associatedDocumentContent = pageContentsInUserLanguage;
                      } else if (doc.defaultLanguage) {
                        const pageContentsInDefaultLanguage =
                          doc.pageContents.find(
                            (page: any) =>
                              page.language === doc.defaultLanguage,
                          );
                        associatedDocumentContent =
                          pageContentsInDefaultLanguage;
                      } else {
                        associatedDocumentContent = doc.pageContents[0];
                      }
                    }

                    return (
                      <Row
                        key={index}
                        style={{ justifyContent: 'space-between' }}
                      >
                        <Button
                          type="link"
                          onClick={() => navigate(`/pages/${doc.slug}`)}
                        >
                          {associatedDocumentContent &&
                            associatedDocumentContent.title}
                        </Button>
                        {isUserAdmin && (
                          <Button
                            type="text"
                            ghost
                            onClick={() => removeDocumentAssociation(doc.slug)}
                          >
                            <Tooltip
                              placement="bottom"
                              title={`${t(
                                'repository-remove-documentation-association-button',
                              )}`}
                            >
                              <TrashCan />
                            </Tooltip>
                          </Button>
                        )}
                      </Row>
                    );
                  },
                )}
            </div>
            <Row justify="center">
              {isUserAdmin && (
                <Popover
                  content={associationPopoverContent(null, true)}
                  trigger="click"
                  open={isCreatingNew && openPopoverSlug === null}
                  onOpenChange={(newOpen) =>
                    handleOpenChange(newOpen, null, true)
                  }
                  className="evaluation-button"
                >
                  <Button
                    type="primary"
                    shape="round"
                    ghost
                    className="evaluation-button"
                    disabled={
                      isLoading || isSearching || repositoryState.errorState
                    }
                  >
                    {t('repository-documentation-association-button')}
                  </Button>
                </Popover>
              )}
            </Row>
          </div>
        </Col>
      </Row>
    );
  };

  return (
    <Col pull={1} span={22} push={1} id="repository-root-list-id">
      <div>
        {showHeader()}
        {showSearchInputAndActionButtons()}
      </div>
      {showRepositoryTable()}
      {showLateralInfo()}
      {showCreateModal()}
      {showEditModal()}
      {showDownloadModal()}
      {showDownloadMultipleModal()}
      {showUploadModal()}
      {showPreviewPdfModal()}
      {showPreviewTxtModal()}
      {showEvaluationModal()}
    </Col>
  );
};
