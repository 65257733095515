import React, { useState } from 'react';
import { Input, Button, Form, Row, Col } from 'antd';
import './LoginForm.scss';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface ILoginForm {
  loginRequest: Function;
  loginLoading: boolean;
}

export const LoginForm: React.FC<ILoginForm> = ({
  loginRequest,
  loginLoading,
}: ILoginForm) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [email, setEmail] = useState<string | undefined>(undefined);

  return (
    <Form
      className="LoginForm"
      layout="vertical"
      wrapperCol={{ span: 20 }}
      onFinish={(loginForm) =>
        loginRequest({
          email: loginForm.email.toLowerCase(),
          password: loginForm.password,
        })
      }
    >
      <Form.Item className="login-form-input-layout">
        <Form.Item
          name="email"
          rules={[
            { required: true, message: t('login-validation-email') },
            { type: 'email', message: t('login-validation-email-type') },
            () => ({
              validator(rule, value) {
                if (!value || value.split('@').shift().length <= 64) {
                  return Promise.resolve();
                }
                return Promise.reject(t('login-validation-email-type'));
              },
            }),
          ]}
        >
          <Row>
            <Col span={24}>
              <p className="login-form-label">{t('login-label-email')}</p>
              <Input
                className="login-form-input"
                name="emailLoginInput"
                placeholder={t('login-placeholder-email')}
                size="middle"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value.toLowerCase());
                }}
                maxLength={254}
              />
            </Col>
          </Row>
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: t('login-validation-password') }]}
        >
          <Row>
            <Col span={24}>
              <p className="login-form-label">{t('login-label-password')}</p>
              <Input.Password
                className="login-form-input"
                name="passwordLoginInput"
                placeholder={t('login-placeholder-password')}
                size="middle"
                maxLength={64}
              />
            </Col>
          </Row>
        </Form.Item>
        <Row className="login-form-forgotten-password">
          <Button
            name="forgotMyPasswordButton"
            type="link"
            className="login-form-link"
            onClick={() => navigate('/forgot-password')}
          >
            {t('login-forgot-my-password')}
          </Button>
        </Row>
      </Form.Item>
      <Form.Item>
        <Row className="login-form-button-center-enter">
          <Button
            name="loginButton"
            htmlType="submit"
            shape="round"
            className="login-form-button-layout"
            loading={loginLoading}
          >
            {t('login-submit-button')}
          </Button>
        </Row>
      </Form.Item>
    </Form>
  );
};
