import React, { useEffect, useState } from 'react';
import { Header } from '../../components/Header/Header';
import '../../components/Header/Header.scss';
import { EvaluationsTable } from './EvaluationsTable/EvaluationsTable';
import { useTranslation } from 'react-i18next';
import { Button, Col, Form, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/store';
import {
  BookOutlined,
  FileOutlined,
  FolderOpenTwoTone,
} from '@ant-design/icons';
import { EvaluationsCommentsModal } from './EvaluationsCommentsModal/EvaluationsCommentsModal';
import { asyncEvaluationActions, EvaluationSlice } from './Evaluations.Slice';
import { EvaluationModal } from '../../components/EvaluationModal/EvaluationModal';
import { useLocation, useNavigate } from 'react-router-dom';
import { IDefaultSortBehaviour } from '../../components/PagingTable/PagingTable';
import { scrollToTopFunction } from '../../helpers/useScrollToTop/useScrollToTop';
import { ReactComponent as ReturnSymbol } from '../../assets/return_symbol.svg';
import {
  IFileEvaluations,
  IFolderEvaluations,
  IPageEvaluations,
} from '../../services/evaluations.api';
import { PageLanguage } from '../PagesList/CreateReadEditPages/CreateReadEditPage.Slice';
import './Evaluations.scss';

// This conversion is necessary because the backend expects the full name of the language in uppercase, while the navbar stores the current language as an abbreviation.
const languageMap: { [key: string]: string } = {
  en: PageLanguage.English,
  es: PageLanguage.Spanish,
  pt: PageLanguage.Portuguese,
};

export const Evaluations: React.FC = () => {
  const [evaluationRadioSelected, setEvaluationRadioSelected] = useState<
    boolean | null
  >(null);

  const [selectedRecordToOpen, setSelectedRecordToOpen] = useState<
    IFileEvaluations | IFolderEvaluations | IPageEvaluations | undefined
  >(undefined);

  const applicationLanguage = useSelector(
    (state: RootState) => state.navBar.defaultLanguage,
  );

  const pageObjectResponse = useSelector(
    (state: RootState) => state.evaluation.pageObjectResponse,
  );

  const evaluationState = useSelector((state: RootState) => state.evaluation);

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const dataSource = evaluationState.evaluationsList;

  const location = useLocation();
  const { itemId, itemType } = location.state || {};
  let { itemTitle } = location.state || {};

  const userLanguage = languageMap[applicationLanguage];
  const currentPage = pageObjectResponse?.pageContents.find(
    (page: any) => page.language === userLanguage,
  );
  itemTitle = currentPage ? currentPage.title : itemTitle;

  const { t } = useTranslation();

  useEffect(() => {
    const sortBy = '';
    if (itemType === 'ARTIFACT') {
      dispatch(
        asyncEvaluationActions.GET_FOLDER_EVALUATIONS({ itemId, sortBy }),
      );
      return;
    }
    if (itemType === 'FILE') {
      dispatch(asyncEvaluationActions.GET_FILE_EVALUATIONS({ itemId, sortBy }));
      return;
    }
    dispatch(asyncEvaluationActions.GET_PAGE_EVALUATIONS({ itemId, sortBy }));
  }, [itemId, itemType]);

  const icons = {
    documentation: () => {
      return (
        <BookOutlined
          style={{
            marginRight: 8,
            fontSize: '1.5rem',
            color: '#003399',
            cursor: 'pointer',
          }}
        />
      );
    },
    file: () => {
      return (
        <FileOutlined
          style={{
            marginRight: 8,
            fontSize: '1.5rem',
            color: '#003399',
            cursor: 'pointer',
          }}
        />
      );
    },
    folder: () => {
      return (
        <FolderOpenTwoTone
          style={{
            marginRight: 8,
            fontSize: '1.5rem',
            color: '#003399',
            cursor: 'pointer',
          }}
        />
      );
    },
  };

  const getIcon = () => {
    if (itemType === 'ARTIFACT') {
      return icons.folder();
    }
    if (itemType === 'FILE') {
      return icons.file();
    }
    return icons.documentation();
  };

  const formatDateColumn = (unformattedDate: string) => {
    const date = new Date(unformattedDate);

    if (isNaN(date.getTime())) {
      return;
    }

    const dateFormat = new Intl.DateTimeFormat(applicationLanguage, {
      dateStyle: 'long',
      timeStyle: 'long',
    } as Intl.DateTimeFormatOptions);

    const formattedDate = dateFormat.format(date);

    return formattedDate;
  };

  const onEvaluationModalInfo = (record: any) => {
    setSelectedRecordToOpen(record);
    setEvaluationRadioSelected(record.liked);
    dispatch(EvaluationSlice.actions.OPEN_EVALUATION_MODAL(record));
  };

  const showEvaluationModal = () => {
    const userName = `${selectedRecordToOpen?.user.firstName} ${selectedRecordToOpen?.user.lastName}`;

    if (!itemId) {
      return;
    }

    return (
      <EvaluationModal
        isOpen={evaluationState.openEvaluationModal}
        isEditing={false}
        title={`${t('evaluation-admin-modal-title')} ${itemTitle} - ${userName}`}
        okText={''}
        cancelText={t('evaluation-modal-close-button')}
        deleteText={''}
        requiredText={''}
        onCancel={() => {
          dispatch(EvaluationSlice.actions.CLOSE_EVALUATION_MODAL());
        }}
        itemType={itemType}
        itemId={itemId}
        defaultEvaluationRadio={evaluationRadioSelected}
        onCreateEvaluation={() => {}}
        onEditEvaluation={() => {}}
        onDeleteEvaluation={() => {}}
        isUserAdmin={true}
        evaluationValues={selectedRecordToOpen}
      />
    );
  };

  const dispatchSortBy = (sortBy: string) => {
    dispatch(
      EvaluationSlice.actions.EVALUATION_SORT_BY(
        sortBy ? `sort=${sortBy}` : '',
      ),
    );
    if (itemType === 'ARTIFACT') {
      dispatch(
        asyncEvaluationActions.GET_FOLDER_EVALUATIONS({ itemId, sortBy }),
      );
      return;
    }
    if (itemType === 'FILE') {
      dispatch(asyncEvaluationActions.GET_FILE_EVALUATIONS({ itemId, sortBy }));
      return;
    }
    dispatch(asyncEvaluationActions.GET_PAGE_EVALUATIONS({ itemId, sortBy }));
  };

  const defaultSortBehaviour: IDefaultSortBehaviour = {
    columnProperty: 'updatedAt',
    order: 'ascend',
    disableDefaultSortBehaviour: () => {
      if (evaluationState.evaluationFirstQueryBehaviour) {
        dispatch(EvaluationSlice.actions.DISABLE_DEFAULT_SORT_BEHAVIOUR());
      }
    },
  };

  const onChangeSortEvaluation = (
    field: string | string[],
    order: string | undefined | null,
  ) => {
    let sortBy = '';

    if (!order || !field) {
      return dispatchSortBy(sortBy);
    }

    if (defaultSortBehaviour) {
      defaultSortBehaviour.disableDefaultSortBehaviour();
    }

    const fieldToQueryParam = Array.isArray(field) ? field[0] : field;

    switch (fieldToQueryParam) {
      case 'updatedAt':
        sortBy =
          order === 'ascend' ? fieldToQueryParam : `${fieldToQueryParam},desc`;
        return dispatchSortBy(sortBy);

      default:
        sortBy =
          order === 'ascend'
            ? `${fieldToQueryParam},ignoreCase`
            : `${fieldToQueryParam},desc,ignoreCase`;
        return dispatchSortBy(sortBy);
    }
  };

  const onChangePage = (pageIndex: number) => {
    scrollToTopFunction('comments-button');
    dispatch(EvaluationSlice.actions.EVALUATION_CHANGE_PAGE(pageIndex));
  };

  const showEvaluationTable = () => {
    return (
      <Row>
        <Col span={24}>
          <EvaluationsTable
            dataSource={dataSource}
            tableLoading={evaluationState.isLoading}
            onChangeSort={onChangeSortEvaluation}
            disableOptionsColumn={false}
            formatDateColumn={formatDateColumn}
            genericType={itemType}
            onEvaluationModalInfo={onEvaluationModalInfo}
            defaultSortBehaviour={defaultSortBehaviour}
            numberOfItems={evaluationState.numberOfEvaluations}
            pageSize={evaluationState.pageSize}
            pageIndex={evaluationState.pageIndex}
            onChangePage={onChangePage}
          />
        </Col>
      </Row>
    );
  };

  const showEvaluationCommentsModal = () => {
    return (
      <EvaluationsCommentsModal
        isOpen={evaluationState.openEvaluationCommentsModal}
        title={`${t('evaluation-comments-modal-title')} ${itemTitle}`}
        closeText={t('evaluation-comments-modal-close-button')}
        onOk={() => {
          dispatch(EvaluationSlice.actions.CLOSE_EVALUATION_COMMENTS_MODAL());
        }}
        onCancel={() => {
          dispatch(EvaluationSlice.actions.CLOSE_EVALUATION_COMMENTS_MODAL());
        }}
        commentsList={evaluationState.commentsList}
      />
    );
  };

  return (
    <Col pull={1} span={22} push={1} id="evaluations-list-id">
      <>
        <Row id="return-button-row">
          <Col span={24}>
            <Form.Item>
              <Button
                type="link"
                className="return-button"
                onClick={() => navigate(-1)}
              >
                <ReturnSymbol className="return-symbol" />
                {t('evaluation-back-button-label')}
              </Button>
            </Form.Item>
          </Col>
        </Row>
        <Header title={t('evaluation-title-label')} subtitle="" showEllipsis>
          <Col>
            <Row style={{ fontWeight: 'bold' }}>
              {t('evaluation-subtitle-label')}:
            </Row>
            <Row>
              {getIcon()} {itemTitle}
            </Row>
          </Col>
        </Header>
        <Button
          style={{ marginTop: '2rem', marginBottom: '2rem' }}
          id="comments-button"
          type="primary"
          shape="round"
          ghost
          onClick={() =>
            dispatch(EvaluationSlice.actions.OPEN_EVALUATION_COMMENTS_MODAL())
          }
        >
          {t('evaluation-comments-button-label')}
        </Button>
        {showEvaluationTable()}
      </>
      {showEvaluationCommentsModal()}
      {showEvaluationModal()}
    </Col>
  );
};
