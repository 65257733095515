import React, { useState } from 'react';
import { Row, Col, Table, Menu, Tooltip, Tag } from 'antd';
import { TablePaginationConfig } from 'antd/lib/table/interface';
import { ReactComponent as Info } from '../../../assets/avatar-dropdown/logs.svg';

import './EvaluationTable.scss';
import { useTranslation } from 'react-i18next';
import { DislikeFilled, LikeFilled, PictureTwoTone } from '@ant-design/icons';

import { IDefaultSortBehaviour } from '../../../components/PagingTable/PagingTable';

interface IEvaluationTable {
  dataSource: any[] | undefined;
  tableLoading: boolean;
  onChangeSort: Function;
  disableOptionsColumn?: boolean;
  formatDateColumn: Function;
  genericType: string;
  onEvaluationModalInfo: Function;
  defaultSortBehaviour: IDefaultSortBehaviour;
  numberOfItems: number;
  pageSize: number;
  pageIndex: number;
  onChangePage: Function;
}

interface IColumnCursor {
  columnTitle: string;
  columnProperty: string | string[];
  disableSort?: boolean;
  width?: number;
  onResizeAction?: any;
}

interface ITupleOptions {
  optionId?: string;
  optionName: string;
  optionIcon: JSX.Element;
  optionOnClick: Function;
  optionDisableVerification?: Function;
  optionDisabledMessage?: string;
}

export const EvaluationsTable: React.FC<IEvaluationTable> = ({
  dataSource,
  tableLoading,
  onChangeSort,
  disableOptionsColumn = false,
  formatDateColumn,
  genericType,
  onEvaluationModalInfo,
  defaultSortBehaviour,
  numberOfItems,
  pageSize,
  pageIndex,
  onChangePage,
}: IEvaluationTable) => {
  const { t } = useTranslation();

  const dateColumnsProperties = ['updatedAt'];

  const folderOptions = [
    {
      label: t('evaluation-modal-folder-description-checkbox-label'),
      value: 'folderDescription',
    },
    {
      label: t('evaluation-modal-folder-documentation-location-checkbox-label'),
      value: 'locationOfDocuments',
    },
    {
      label: t('evaluation-modal-folder-organization-checkbox-label'),
      value: 'folderOrganization',
    },
  ];

  const fileOptions = [
    {
      label: t('evaluation-modal-file-information-accuracy-checkbox-label'),
      value: 'informationAccuracy',
    },
    {
      label: t('evaluation-modal-file-clarity-checkbox-label'),
      value: 'clarity',
    },
    {
      label: t('evaluation-modal-file-information-availability-checkbox-label'),
      value: 'availabilityOfInformation',
    },
    {
      label: t('evaluation-modal-file-preferred-language-checkbox-label'),
      value: 'preferredLanguage',
    },
    {
      label: t('evaluation-modal-file-location-checkbox-label'),
      value: 'locate',
    },
    {
      label: t('evaluation-modal-file-content-depth-checkbox-label'),
      value: 'contentDepth',
    },
  ];

  const documentationOptions = [
    {
      label: t(
        'evaluation-modal-documentation-information-accuracy-checkbox-label',
      ),
      value: 'informationAccuracy',
    },
    {
      label: t('evaluation-modal-documentation-clarity-checkbox-label'),
      value: 'clarity',
    },
    {
      label: t('evaluation-modal-documentation-description-checkbox-label'),
      value: 'pageDescription',
    },
    {
      label: t(
        'evaluation-modal-documentation-information-availability-checkbox-label',
      ),
      value: 'availabilityOfInformation',
    },
    {
      label: t(
        'evaluation-modal-documentation-preferred-language-checkbox-label',
      ),
      value: 'preferredLanguage',
    },
    {
      label: t('evaluation-modal-documentation-location-checkbox-label'),
      value: 'locate',
    },
    {
      label: t('evaluation-modal-documentation-content-depth-checkbox-label'),
      value: 'depthOfInformation',
    },
  ];

  const sortedCheckBoxOptions = (options: any) => {
    options.sort((a: any, b: any) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });

    return options;
  };

  let optionsList;

  if (genericType === 'ARTIFACT') {
    optionsList = sortedCheckBoxOptions(folderOptions);
  } else if (genericType === 'FILE') {
    optionsList = sortedCheckBoxOptions(fileOptions);
  } else {
    optionsList = sortedCheckBoxOptions(documentationOptions);
  }

  const [columnsWidth, setColumnsWidth] = useState({
    firstNameColumnWidth: 2,
    emailColumnWidth: 3,
    evaluationColumnWidth: 2,
    evaluationCheckboxColumnWidth: 4,
    evaluationCommentColumnWidth: 3,
    updatedAtColumnWidth: 3,
  });

  const resizeHandlerSpeed = 0.028;
  const resizeColumns = (
    columnName:
      | 'firstNameColumnWidth'
      | 'emailColumnWidth'
      | 'evaluationColumnWidth'
      | 'evaluationCheckboxColumnWidth'
      | 'evaluationCommentColumnWidth'
      | 'updatedAtColumnWidth',
    movement: number,
  ) => {
    setColumnsWidth({
      ...columnsWidth,
      [columnName]: (columnsWidth[columnName] += movement * resizeHandlerSpeed),
    });
  };

  const evaluationsColumns: IColumnCursor[] = [
    {
      columnTitle: t('evaluation-table-name'),
      columnProperty: ['user', 'firstName'],
      width: columnsWidth.firstNameColumnWidth,
      onResizeAction: (event: MouseEvent) => {
        resizeColumns('firstNameColumnWidth', event.movementX);
      },
    },
    {
      columnTitle: t('evaluation-table-email'),
      columnProperty: ['user', 'email'],
      width: columnsWidth.emailColumnWidth,
      onResizeAction: (event: MouseEvent) => {
        resizeColumns('emailColumnWidth', event.movementX);
      },
    },
    {
      columnTitle: t('evaluation-table-evaluation'),
      columnProperty: 'liked',
      width: columnsWidth.evaluationColumnWidth,
      onResizeAction: (event: MouseEvent) => {
        resizeColumns('evaluationColumnWidth', event.movementX);
      },
    },
    {
      columnTitle: t('evaluation-table-options'),
      columnProperty: 'checkbox',
      width: columnsWidth.evaluationCheckboxColumnWidth,
      onResizeAction: (event: MouseEvent) => {
        resizeColumns('evaluationCheckboxColumnWidth', event.movementX);
      },
      disableSort: true,
    },
    {
      columnTitle: t('evaluation-table-comments'),
      columnProperty: 'description',
      width: columnsWidth.evaluationCommentColumnWidth,
      onResizeAction: (event: MouseEvent) => {
        resizeColumns('evaluationCommentColumnWidth', event.movementX);
      },
    },
    {
      columnTitle: t('evaluation-table-last-modified'),
      columnProperty: 'updatedAt',
      width: columnsWidth.updatedAtColumnWidth,
      onResizeAction: (event: MouseEvent) => {
        resizeColumns('updatedAtColumnWidth', event.movementX);
      },
    },
  ];

  const mockedOptions = [
    {
      optionName: t('evaluation-table-information-button'),
      optionIcon: <Info />,
      optionOnClick: (_id: number, item: any) => {
        onEvaluationModalInfo(item);
      },
    },
  ];

  const checkIfColumnHasDefaultSortBehaviour = (columnProperty: string) => {
    if (
      defaultSortBehaviour &&
      defaultSortBehaviour.columnProperty === columnProperty
    ) {
      return defaultSortBehaviour.order;
    }

    return undefined;
  };

  const generateOptions = (
    menuOptions: ITupleOptions[],
    itemId: number,
    rowItem: any,
  ) => {
    const menuOptionItens = menuOptions.map((x) => {
      const itemWithIconAndName = (
        <Tooltip placement="left" title={x.optionName}>
          <div>{x.optionIcon}</div>
        </Tooltip>
      );

      return (
        <Menu.Item
          key={x.optionName.toLowerCase()}
          onClick={() => {
            x.optionOnClick(itemId, rowItem);
          }}
        >
          {itemWithIconAndName}
        </Menu.Item>
      );
    });

    return <Menu style={{ border: 'none' }}>{menuOptionItens}</Menu>;
  };

  const generateColumns = () => {
    let tableColumns: JSX.Element[] = [];

    tableColumns = evaluationsColumns.map((x: any) => {
      if (dateColumnsProperties.includes(x.columnProperty as string)) {
        return (
          <>
            <Table.Column
              title={x.columnTitle}
              key={x.columnProperty.toString()}
              sorter
              className={x.className}
              ellipsis
              showSorterTooltip={false}
              render={(y: any) =>
                formatDateColumn(y[x.columnProperty as string])
              }
              width={x.width ? `${x.width}rem` : '4.5rem'}
            />
            <Table.Column
              className="resizeHandle"
              onHeaderCell={() => {
                return {
                  onMouseDown: () => {
                    window.addEventListener('mousemove', x.onResizeAction);
                    window.addEventListener('mouseup', () => {
                      window.removeEventListener('mousemove', x.onResizeAction);
                    });
                  },
                };
              }}
            />
          </>
        );
      }

      if (x.columnProperty === 'liked') {
        return (
          <>
            <Table.Column
              title={x.columnTitle}
              key={x.columnProperty.toString()}
              sorter
              className={x.className}
              align="center"
              defaultSortOrder={checkIfColumnHasDefaultSortBehaviour(
                x.columnProperty as string,
              )}
              ellipsis
              showSorterTooltip={false}
              render={(record: any) => {
                return (
                  <span title={record[x.columnProperty as string]}>
                    {record[x.columnProperty as string]}
                    {record.liked ? (
                      <Tooltip
                        placement="left"
                        title={t('evaluation-table-liked-icon')}
                      >
                        <LikeFilled style={{ color: '#41b4d2' }} />
                      </Tooltip>
                    ) : (
                      <Tooltip
                        placement="left"
                        title={t('evaluation-table-disliked-icon')}
                      >
                        {' '}
                        <DislikeFilled style={{ color: '#D46b08' }} />
                      </Tooltip>
                    )}
                  </span>
                );
              }}
              width={x.width ? `${x.width}rem` : '4.2rem'}
            />
          </>
        );
      }

      if (x.columnProperty === 'checkbox') {
        return (
          <>
            <Table.Column
              title={x.columnTitle}
              key={x.columnProperty.toString()}
              className={x.className}
              defaultSortOrder={checkIfColumnHasDefaultSortBehaviour(
                x.columnProperty as string,
              )}
              showSorterTooltip={false}
              render={(record: any) => {
                const checkbox = optionsList
                  .filter((option: any) => record[option.value])
                  .map((option: any) => option.label);
                const updatedRecord = {
                  ...record,
                  checkbox,
                };

                if (!updatedRecord?.checkbox?.length) {
                  return (
                    <Tooltip
                      placement="bottomLeft"
                      title={t('evaluation-table-none-options-label')}
                    >
                      <Tag key={`any-tag`}>
                        {t('evaluation-table-none-options-label')}
                      </Tag>
                    </Tooltip>
                  );
                }
                return (
                  <Tooltip
                    placement="bottomLeft"
                    title={updatedRecord.checkbox.join(', ')}
                  >
                    {updatedRecord.checkbox.map((item: any) => {
                      return (
                        <Tag
                          key={`tag-${updatedRecord.checkbox.value}`}
                          color={updatedRecord.liked ? 'geekblue' : 'orange'}
                        >
                          {item}
                        </Tag>
                      );
                    })}
                  </Tooltip>
                );
              }}
              width={x.width ? `${x.width}rem` : '4.2rem'}
            />
            <Table.Column
              className="resizeHandle"
              onHeaderCell={() => {
                return {
                  onMouseDown: () => {
                    window.addEventListener('mousemove', x.onResizeAction);
                    window.addEventListener('mouseup', () => {
                      window.removeEventListener('mousemove', x.onResizeAction);
                    });
                  },
                };
              }}
            />
          </>
        );
      }

      if (x.columnProperty === 'description') {
        const image = (
          <PictureTwoTone
            style={{
              marginRight: 8,
              fontSize: '1.5rem',
              color: '#003399',
              cursor: 'pointer',
            }}
          />
        );
        return (
          <>
            <Table.Column
              title={x.columnTitle}
              key={x.columnProperty.toString()}
              sorter
              className={x.className}
              ellipsis
              showSorterTooltip={false}
              render={(record: any) => {
                const descriptionReplacedImg = record?.description?.replace(
                  /<img[^>]*>/g,
                  image,
                );
                // Remove HTML tags
                const descriptionRemovedTagsHtml =
                  descriptionReplacedImg?.replace(/<\/?[^>]+(>|$)/g, '');

                const commentContent = (
                  <span>
                    {descriptionRemovedTagsHtml &&
                      descriptionRemovedTagsHtml
                        .split(image)
                        .map((part: any, index: number) => (
                          <React.Fragment key={index}>
                            {part}
                            {index <
                              descriptionRemovedTagsHtml.split(image).length -
                                1 && (
                              <PictureTwoTone
                                style={{
                                  marginRight: 8,
                                  fontSize: '1.5rem',
                                  color: '#003399',
                                  cursor: 'pointer',
                                }}
                              />
                            )}
                          </React.Fragment>
                        ))}
                  </span>
                );
                return (
                  <Tooltip
                    placement="leftBottom"
                    overlayStyle={{ maxWidth: '50vw' }}
                    title={
                      <div
                        style={{
                          // Ant Design (antd) Tooltip does not have a direct configuration to set a maximum height with ellipsis
                          maxHeight: '30vh',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          display: '-webkit-box',
                          WebkitLineClamp: 10,
                          WebkitBoxOrient: 'vertical',
                        }}
                      >
                        {!record?.description
                          ? t('evaluation-modal-any-comment-label')
                          : commentContent}
                      </div>
                    }
                  >
                    {!record?.description
                      ? t('evaluation-modal-any-comment-label')
                      : commentContent}
                  </Tooltip>
                );
              }}
              width={x.width ? `${x.width}rem` : '4.2rem'}
            />
            <Table.Column
              className="resizeHandle"
              onHeaderCell={() => {
                return {
                  onMouseDown: () => {
                    window.addEventListener('mousemove', x.onResizeAction);
                    window.addEventListener('mouseup', () => {
                      window.removeEventListener('mousemove', x.onResizeAction);
                    });
                  },
                };
              }}
            />
          </>
        );
      }

      return (
        <>
          <Table.Column
            title={x.columnTitle}
            dataIndex={x.columnProperty}
            key={x.columnTitle}
            className={x.className}
            sorter={x.disableSort ? !x.disableSort : true}
            ellipsis
            showSorterTooltip={false}
            width={x.width ? `${x.width}rem` : '4.2rem'}
          />
          <Table.Column
            className="resizeHandle"
            onHeaderCell={() => {
              return {
                onMouseDown: () => {
                  window.addEventListener('mousemove', x.onResizeAction);
                  window.addEventListener('mouseup', () => {
                    window.removeEventListener('mousemove', x.onResizeAction);
                  });
                },
              };
            }}
          />
        </>
      );
    });

    if (!disableOptionsColumn) {
      tableColumns.push(
        <Table.Column
          title=""
          key="options"
          className="optionsColumn"
          ellipsis
          align="center"
          render={(record: any) =>
            generateOptions(mockedOptions, record.id, record)
          }
          width={`${1.1 * mockedOptions.length}rem`}
        />,
      );
    }

    return tableColumns;
  };

  const sortHandler = (
    field: string | string[],
    order: string | undefined | null,
  ) => {
    onChangeSort(field, order);
  };

  const paginationConfig: TablePaginationConfig = {
    total: numberOfItems,
    showTotal: (total, range) =>
      `${range[0]}-${range[1]} ${t('paging-table-pagination-of')} ${total}`,
    current: pageIndex,
    defaultPageSize: pageSize,
    pageSizeOptions: [],
    position: ['bottomCenter'],
  };

  return (
    <Row className="EvaluationTable">
      <Col span={24}>
        <Table
          onChange={(pagination, filters, sorter: any) => {
            const sorterField = sorter.field ? sorter.field : sorter.columnKey;

            sortHandler(sorterField, sorter.order);
            onChangePage(pagination.current);
          }}
          loading={tableLoading}
          dataSource={dataSource}
          pagination={paginationConfig}
        >
          {generateColumns()}
        </Table>
      </Col>
    </Row>
  );
};
