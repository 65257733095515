import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { messageNotification } from '../../helpers/messageNotification/messageNotification';
import { unhookedTranslation } from '../../helpers/unhookedTranslation/unhookedTranslation';
import {
  IArtifactData,
  IArtifactList,
  IArtifactResponse,
  IBreadcrumb,
  IContent,
  IContentList,
  repositoryApi,
} from '../../services/repository.api';
import axios from 'axios';
import { IPageBackendDTO } from '../../services/createReadEditPage.api';

export enum RepositoryContentTypes {
  Artifact,
  Release,
  File,
  Multiple,
}

interface ITransferState {
  isTransferring: boolean;
  isTransferComplete: boolean;
  transferProgress: number;
  transferredFileName: string;
  transferredData: string;
}

interface ILoadingState {
  isLoadingRootId: boolean;
  isLoadingArtifacts: boolean;
  isLoadingContent: boolean;
  isLoadingBreadcrumb: boolean;
  isLoadingSearch: boolean;
  isLoadingMoveFiles: boolean;
  isLoadingCopyFile: boolean;
  isLoadingCopyFiles: boolean;
  isLoadingMoveArtifacts: boolean;
  isLoadingCopyArtifact: boolean;
  isLoadingCopyArtifacts: boolean;
}

interface IRepositoryInitialState {
  isAtRoot: boolean;
  errorState: boolean;
  loadingState: ILoadingState;
  shouldUpdate: boolean;
  rootId: number | undefined;
  artifacts: IArtifactList | IContentList | undefined;
  breadcrumb: IBreadcrumb[] | undefined;
  openedRootItemId: string;
  openedItemId: string;
  openCreateModal: boolean;
  openEditModal: boolean;
  editModalAllowNameEdit: boolean;
  creatEditType: RepositoryContentTypes;
  deleteType: RepositoryContentTypes;
  objectToEdit: IArtifactResponse | IContent | undefined;
  isCreatingOrSavingArtifactOrRelease: boolean;
  isDeletingData: boolean;
  openDownloadModal: boolean;
  openDownloadMultipleModal: boolean;
  openPreviewPdfModal: boolean;
  openPreviewTxtModal: boolean;
  openUploadModal: boolean;
  downloadingFileName: string;
  downloadingMultipleFiles: any;
  isSettingFileAsDefault: boolean;
  downloadFileAfterTransfer: boolean;
  fileDownloaded: boolean;
  transferState: ITransferState;
  defaultFileId: number | undefined;
  searchRepositoryByName: string;
  selectedRowsItems: any;
  isMovingFilesAndArtifacts: boolean;
  isMovingFilesComplete: boolean;
  isMovingArtifactsComplete: boolean;
  isCopyingFilesAndArtifacts: boolean;
  isCopyingFilesComplete: boolean;
  isCopyingArtifactsComplete: boolean;
  repositoryFirstQueryBehaviour: boolean;
  currentArtifactPermissions: string[];
  currentArtifactId: number | undefined;
  currentArtifactData: IArtifactData | undefined;
  pageObjectResponse: IPageBackendDTO | undefined;
  pageRejected: boolean;
  openEvaluationModal: boolean;
  itemToEvaluate: any | undefined;
  isEvaluatingItem: boolean;
  isDeletingEvaluation: boolean;
  isEditingEvaluation: boolean;
  openEditEvaluationModal: boolean;
  evaluationToEdit: IArtifactData | undefined;
}

const initialState: IRepositoryInitialState = {
  isAtRoot: true,
  errorState: false,
  loadingState: {
    isLoadingRootId: false,
    isLoadingArtifacts: false,
    isLoadingContent: false,
    isLoadingBreadcrumb: false,
    isLoadingSearch: false,
    isLoadingMoveFiles: false,
    isLoadingCopyFile: false,
    isLoadingCopyFiles: false,
    isLoadingMoveArtifacts: false,
    isLoadingCopyArtifact: false,
    isLoadingCopyArtifacts: false,
  },
  shouldUpdate: true,
  rootId: undefined,
  artifacts: undefined,
  breadcrumb: undefined,
  openedRootItemId: '',
  openedItemId: '',
  openCreateModal: false,
  openEditModal: false,
  editModalAllowNameEdit: true,
  creatEditType: RepositoryContentTypes.Artifact,
  deleteType: RepositoryContentTypes.Artifact,
  objectToEdit: undefined,
  isCreatingOrSavingArtifactOrRelease: false,
  isDeletingData: false,
  openDownloadModal: false,
  openDownloadMultipleModal: false,
  openPreviewPdfModal: false,
  openPreviewTxtModal: false,
  openUploadModal: false,
  downloadingFileName: '',
  downloadingMultipleFiles: undefined,
  isSettingFileAsDefault: false,
  downloadFileAfterTransfer: true,
  fileDownloaded: false,
  transferState: {
    isTransferring: false,
    isTransferComplete: false,
    transferProgress: 0,
    transferredFileName: '',
    transferredData: '',
  },
  defaultFileId: undefined,
  searchRepositoryByName: '',
  selectedRowsItems: [],
  isMovingFilesAndArtifacts: false,
  isMovingFilesComplete: false,
  isMovingArtifactsComplete: false,
  isCopyingFilesAndArtifacts: false,
  isCopyingFilesComplete: false,
  isCopyingArtifactsComplete: false,
  repositoryFirstQueryBehaviour: true,
  currentArtifactPermissions: [],
  currentArtifactId: undefined,
  currentArtifactData: undefined,
  pageObjectResponse: undefined,
  pageRejected: false,
  openEvaluationModal: false,
  itemToEvaluate: undefined,
  isEvaluatingItem: false,
  isDeletingEvaluation: false,
  isEditingEvaluation: false,
  openEditEvaluationModal: false,
  evaluationToEdit: undefined,
};

export const asyncActions = {
  GET_ALL_ARTIFACTS: createAsyncThunk(
    'GET_ALL_ARTIFACTS',
    async (queryString: string) => {
      const sortString = queryString ? `${queryString}` : 'name';
      const artifactListResponse =
        await repositoryApi.getAllArtifacts(sortString);

      return {
        artifactListResponse: artifactListResponse.data,
      };
    },
  ),
  FIND_REPOSITORY_BY_NAME: createAsyncThunk(
    'FIND_REPOSITORY_BY_NAME',
    async (arg: any) => {
      const artifactSearchListResponse =
        await repositoryApi.findRepositoryByName(
          arg.rootId,
          arg.parentId,
          arg.name,
        );

      // Verify if it is the root; if so, rootId and parentId should be undefined
      const resultSearchListResponse = !arg.rootId
        ? artifactSearchListResponse.data
        : artifactSearchListResponse.data.listing;

      return {
        name: arg.name,
        artifactListResponse: resultSearchListResponse,
      };
    },
  ),
  CLEAR_SEARCH_REPOSITORY: createAsyncThunk(
    'CLEAR_SEARCH_REPOSITORY',
    async (arg: any) => {
      const sortString = arg.queryString ? `${arg.queryString}` : 'name';
      if (arg.rootId) {
        const contentResponseList = await repositoryApi.getContentById(
          arg.rootId,
          arg.parentId,
          sortString,
        );
        return {
          artifactListResponse: contentResponseList.data.listing,
        };
      } else {
        const rootResponseList =
          await repositoryApi.getAllArtifacts(sortString);
        return {
          artifactListResponse: rootResponseList.data,
        };
      }
    },
  ),
  GET_BREADCRUMB_BY_ID: createAsyncThunk(
    'GET_BREADCRUMB_BY_ID',
    async (id: number | string) => {
      const breadcrumbResponse = await repositoryApi.getBreadcrumbById(id);

      return {
        breadcrumbResponse: breadcrumbResponse.data,
      };
    },
  ),
  GET_CONTENT_BY_ID: createAsyncThunk(
    'GET_CONTENT_BY_ID',
    async (payload: any) => {
      const contentResponse = await repositoryApi.getContentById(
        payload.rootId,
        payload.parentId,
        payload.queryString,
      );

      return {
        contentResponse: contentResponse.data,
        contentId: payload.parentId,
      };
    },
  ),
  GET_ROOT_ID_BY_ARTIFACT_ID: createAsyncThunk(
    'GET_ROOT_ID_BY_ARTIFACT_ID',
    async (id: number) => {
      const rootIdResponse = await repositoryApi.getRootIdByArtifactId(id);

      return { rootIdResponse: rootIdResponse.data };
    },
  ),
  NEW_ARTIFACT: createAsyncThunk(
    'NEW_ARTIFACT',
    async (payload: any, { rejectWithValue }) => {
      try {
        const newArtifactResponse = await repositoryApi.newArtifact(payload);

        return { newArtifactRespose: newArtifactResponse.data };
      } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
          return rejectWithValue(error.response.status);
        }
      }
    },
  ),
  NEW_RELEASE: createAsyncThunk(
    'NEW_RELEASE',
    async (payload: any, { rejectWithValue }) => {
      try {
        const newReleaseResponse = await repositoryApi.newRelease(
          payload.rootId,
          payload.id,
          payload.body,
        );

        return { newReleaseResponse: newReleaseResponse.data };
      } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
          return rejectWithValue(error.response.status);
        }
      }
    },
  ),
  UPDATE_ARTIFACT_OR_RELEASE: createAsyncThunk(
    'UPDATE_ARTIFACT_OR_RELEASE',
    async (payload: any) => {
      const updateArtifactOrReleaseResponse =
        await repositoryApi.updateArtifactOrRelease(
          payload.rootId,
          payload.id,
          payload.body,
        );

      return {
        updateArtifactOrReleaseResponse: updateArtifactOrReleaseResponse.data,
        updateType: payload.type,
      };
    },
  ),
  DELETE_DATA: createAsyncThunk('DELETE_DATA', async (payload: any) => {
    const deleteDataResponse = await repositoryApi.deleteData(
      payload.rootId,
      payload.id,
      payload.type,
    );

    return { deleteDataResponse: deleteDataResponse.data };
  }),
  DELETE_MULTIPLE_DATA: createAsyncThunk(
    'DELETE_MULTIPLE_DATA',
    async (payload: any) => {
      const deleteMultipleDataResponse = await repositoryApi.deleteMultipleData(
        payload.rootId,
        payload.itemsToBeDeleted,
      );

      return { deleteMultipleDataResponse: deleteMultipleDataResponse.data };
    },
  ),
  TRANSFER_FILE: createAsyncThunk(
    'TRANSFER_FILE',
    async (payload: any, { dispatch }) => {
      dispatch(RepositorySlice.actions.SET_TRANSFER_PROGRESS(0));
      const transferFileResponse = await repositoryApi.transferFile(
        payload.rootId,
        payload.id,
        (progressEvent) => {
          if (progressEvent.total === 0) {
            return;
          }

          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total,
          );
          dispatch(
            RepositorySlice.actions.SET_TRANSFER_PROGRESS(percentCompleted),
          );
        },
        payload.abortSignal,
      );

      return {
        headers: transferFileResponse.headers,
        transferFileResponse: transferFileResponse.data,
      };
    },
  ),
  SET_DEFAULT_FILE: createAsyncThunk(
    'SET_DEFAULT_FILE',
    async (payload: any) => {
      const setDefaultFileResponse = await repositoryApi.setDefaultFile(
        payload.releaseId,
        payload.id,
      );

      return {
        setDefaultFileResponse: setDefaultFileResponse.data,
      };
    },
  ),
  MOVE_FILE: createAsyncThunk('MOVE_FILE', async (payload: any) => {
    const moveFileResponse = await repositoryApi.moveFile(
      payload.destinationArtifactId,
      payload.fileId,
    );

    return { moveFileResponse: moveFileResponse };
  }),
  MOVE_FILES: createAsyncThunk('MOVE_FILES', async (payload: any) => {
    const moveFilesResponse = await repositoryApi.moveFiles(
      payload.destinationArtifactId,
      payload.fileIds,
    );

    return { moveFilesResponse: moveFilesResponse };
  }),
  COPY_FILE: createAsyncThunk('COPY_FILE', async (payload: any) => {
    const copyFileResponse = await repositoryApi.copyFile(
      payload.destinationArtifactId,
      payload.fileId,
    );

    return { copyFilesResponse: copyFileResponse };
  }),
  COPY_FILES: createAsyncThunk('COPY_FILES', async (payload: any) => {
    const copyFilesResponse = await repositoryApi.copyFiles(
      payload.destinationArtifactId,
      payload.fileIds,
    );

    return { copyFilesResponse: copyFilesResponse };
  }),
  MOVE_ARTIFACT: createAsyncThunk('MOVE_ARTIFACT', async (payload: any) => {
    const moveArtifactResponse = await repositoryApi.moveArtifact(
      payload.destinationArtifactId,
      payload.artifactId,
    );

    return { moveArtifactResponse: moveArtifactResponse };
  }),
  MOVE_ARTIFACTS: createAsyncThunk('MOVE_ARTIFACTS', async (payload: any) => {
    const moveArtifactsResponse = await repositoryApi.moveArtifacts(
      payload.destinationArtifactId,
      payload.artifactIds,
    );

    return { moveArtifactsResponse: moveArtifactsResponse };
  }),
  COPY_ARTIFACT: createAsyncThunk('COPY_ARTIFACT', async (payload: any) => {
    const copyArtifactResponse = await repositoryApi.copyArtifact(
      payload.destinationArtifactId,
      payload.artifactId,
    );

    return { copyArtifactResponse: copyArtifactResponse };
  }),
  COPY_ARTIFACTS: createAsyncThunk('COPY_ARTIFACTS', async (payload: any) => {
    const copyArtifactsResponse = await repositoryApi.copyArtifacts(
      payload.destinationArtifactId,
      payload.artifactIds,
    );

    return { copyArtifactResponse: copyArtifactsResponse };
  }),
  GET_PAGE_BY_SLUG: createAsyncThunk(
    'GET_PAGE_BY_SLUG',
    async (payload: any) => {
      const pageResponse = await repositoryApi.getPageBySlug(payload.slug);

      return {
        pageObjectResponse: pageResponse.data,
      };
    },
  ),
  NEW_FILE_EVALUATION: createAsyncThunk(
    'NEW_FILE_EVALUATION',
    async (payload: any) => {
      const newFileEvaluationResponse = await repositoryApi.newFileEvaluation(
        payload.evaluationValues,
        payload.userId,
        payload.fileId,
      );

      return {
        fileEvaluationResponse: newFileEvaluationResponse.data,
      };
    },
  ),
  NEW_ARTIFACT_EVALUATION: createAsyncThunk(
    'NEW_ARTIFACT_EVALUATION',
    async (payload: any) => {
      const newFolderEvaluationResponse =
        await repositoryApi.newFolderEvaluation(payload);

      return {
        folderEvaluationResponse: newFolderEvaluationResponse.data,
      };
    },
  ),
  UPDATE_FILE_EVALUATION: createAsyncThunk(
    'UPDATE_FILE_EVALUATION',
    async (payload: any) => {
      const updateFileEvaluationResponse =
        await repositoryApi.updateFileEvaluation(
          payload.evaluationValues,
          payload.userId,
          payload.fileId,
        );

      return {
        fileEvaluationResponse: updateFileEvaluationResponse.data,
      };
    },
  ),
  UPDATE_ARTIFACT_EVALUATION: createAsyncThunk(
    'UPDATE_ARTIFACT_EVALUATION',
    async (payload: any) => {
      const updateFolderEvaluationResponse =
        await repositoryApi.updateFolderEvaluation(
          payload.evaluationValues,
          payload.userId,
          payload.artifactId,
        );

      return {
        folderEvaluationResponse: updateFolderEvaluationResponse.data,
      };
    },
  ),
  DELETE_FILE_EVALUATION: createAsyncThunk(
    'DELETE_FILE_EVALUATION',
    async (payload: any) => {
      const deleteFileEvaluationResponse =
        await repositoryApi.deleteFileEvaluation(
          payload.userId,
          payload.fileId,
        );

      return {
        fileEvaluationResponse: deleteFileEvaluationResponse.data,
      };
    },
  ),
  DELETE_ARTIFACT_EVALUATION: createAsyncThunk(
    'DELETE_ARTIFACT_EVALUATION',
    async (payload: any) => {
      const deleteFolderEvaluationResponse =
        await repositoryApi.deleteFolderEvaluation(
          payload.userId,
          payload.artifactId,
        );

      return {
        folderEvaluationResponse: deleteFolderEvaluationResponse.data,
      };
    },
  ),
};

export const RepositorySlice = createSlice({
  name: 'repositorySlice',
  initialState,
  reducers: {
    RESET_TO_INITIAL_STATE: () => {
      return initialState;
    },
    SET_SHOULD_UPDATE: (state, action) => {
      state.shouldUpdate = action.payload;
    },
    SET_IS_CREATING_ARTIFACT_OR_REPOSITORY: (state, action) => {
      state.isCreatingOrSavingArtifactOrRelease = action.payload;
    },
    SET_DELETE_TYPE: (state, action) => {
      state.deleteType = action.payload;
    },
    SET_TRANSFER_PROGRESS: (state, action) => {
      if (!state.transferState.isTransferring) {
        return;
      }
      state.transferState.transferProgress = action.payload;
    },
    OPEN_CREATE_ARTIFACT_MODAL: (state) => {
      state.creatEditType = RepositoryContentTypes.Artifact;
      state.openCreateModal = true;
    },
    OPEN_CREATE_RELEASE_MODAL: (state) => {
      state.creatEditType = RepositoryContentTypes.Release;
      state.openCreateModal = true;
    },
    CLOSE_CREATE_MODAL: (state) => {
      state.openCreateModal = false;
    },
    OPEN_EDIT_ARTIFACT_MODAL: (state, action) => {
      state.objectToEdit = action.payload.object;
      state.editModalAllowNameEdit = action.payload.allowNameEdit;
      state.creatEditType = RepositoryContentTypes.Artifact;
      state.openEditModal = true;
    },
    OPEN_EDIT_RELEASE_MODAL: (state, action) => {
      state.objectToEdit = action.payload.object;
      state.editModalAllowNameEdit = action.payload.allowNameEdit;
      state.creatEditType = RepositoryContentTypes.Release;
      state.openEditModal = true;
    },
    CLOSE_EDIT_MODAL: (state) => {
      state.openEditModal = false;
      state.objectToEdit = undefined;
    },
    OPEN_DOWNLOAD_MODAL: (state, action) => {
      state.downloadingFileName = action.payload;
      state.openDownloadModal = true;
    },
    CLOSE_DOWNLOAD_MODAL: (state) => {
      state.openDownloadModal = false;
    },
    OPEN_DOWNLOAD_MULTIPLE_MODAL: (state, action) => {
      state.downloadingMultipleFiles = action.payload;
      state.openDownloadMultipleModal = true;
    },
    CLOSE_DOWNLOAD_MULTIPLE_MODAL: (state) => {
      state.openDownloadMultipleModal = false;
      state.downloadingMultipleFiles = undefined;
    },
    OPEN_PREVIEW_PDF_MODAL: (state, action) => {
      state.downloadingFileName = action.payload;
      state.openPreviewPdfModal = true;
    },
    CLOSE_PREVIEW_PDF_MODAL: (state) => {
      state.openPreviewPdfModal = false;
      state.shouldUpdate = true;
    },
    OPEN_PREVIEW_TXT_MODAL: (state, action) => {
      state.downloadingFileName = action.payload;
      state.openPreviewTxtModal = true;
    },
    CLOSE_PREVIEW_TXT_MODAL: (state) => {
      state.openPreviewTxtModal = false;
      state.shouldUpdate = true;
    },
    OPEN_UPLOAD_MODAL: (state) => {
      state.openUploadModal = true;
    },
    CLOSE_UPLOAD_MODAL: (state) => {
      state.openUploadModal = false;
      state.shouldUpdate = true;
    },
    SET_DOWNLOAD_AFTER_TRANSFER: (state, action) => {
      state.downloadFileAfterTransfer = action.payload;
      state.shouldUpdate = true;
    },
    SET_FILE_DOWNLOADED: (state, action) => {
      state.fileDownloaded = action.payload;
      state.shouldUpdate = true;
    },
    SET_SELECTED_ROWS_ITEMS: (state, action) => {
      state.selectedRowsItems = action.payload;
    },
    SET_IS_MOVING_FILES_AND_ARTIFACTS: (state, action) => {
      state.isMovingFilesAndArtifacts = action.payload;
    },
    SET_IS_COPYING_FILES_AND_ARTIFACTS: (state, action) => {
      state.isCopyingFilesAndArtifacts = action.payload;
    },
    FINISH_MOVING_FILES_AND_ARTIFACTS: (state) => {
      state.isMovingArtifactsComplete = false;
      state.isMovingFilesComplete = false;
      state.isMovingFilesAndArtifacts = false;
      state.loadingState.isLoadingMoveFiles = false;
      state.loadingState.isLoadingMoveArtifacts = false;
      state.shouldUpdate = true;
      state.selectedRowsItems = [];
    },
    OPEN_EVALUATION_MODAL: (state, action) => {
      state.itemToEvaluate = action.payload;
      state.openEvaluationModal = true;
    },
    CLOSE_EVALUATION_MODAL: (state) => {
      state.openEvaluationModal = false;
      state.itemToEvaluate = undefined;
    },
    SET_EDIT_EVALUATION_MODE: (state) => {
      state.isEditingEvaluation = true;
    },
    REMOVE_EDIT_EVALUATION_MODE: (state) => {
      state.isEditingEvaluation = false;
    },
    OPEN_EDIT_EVALUATION_MODAL: (state, action) => {
      state.evaluationToEdit = action.payload.object;
      state.openEditEvaluationModal = true;
    },
    CLOSE_EDIT_EVALUATION_MODAL: (state) => {
      state.evaluationToEdit = undefined;
      state.openEditEvaluationModal = false;
    },
    REPOSITORY_DISABLE_DEFAULT_SORT_BEHAVIOUR: (state) => {
      state.repositoryFirstQueryBehaviour = false;
    },
    SET_ROOT_ID: (state, action) => {
      state.rootId = action.payload;
    },
    SET_IS_AT_ROOT: (state, action) => {
      state.isAtRoot = action.payload;
    },
    SET_CLICKED_ROOT_ITEM_ID: (state, action) => {
      state.openedRootItemId = action.payload;
    },
    SET_CLICKED_ITEM_ID: (state, action) => {
      state.openedItemId = action.payload;
    },
    RESET_PAGE_OBJECT_RESPONSE: (state) => {
      state.pageObjectResponse = undefined;
    },
  },
  extraReducers: (builder) => [
    builder.addCase(asyncActions.GET_ALL_ARTIFACTS.pending, (state) => {
      state.loadingState.isLoadingArtifacts = true;
      state.rootId = undefined;
      state.currentArtifactPermissions = [];
      state.currentArtifactId = undefined;
      state.currentArtifactData = undefined;
      state.defaultFileId = undefined;
    }),
    builder.addCase(asyncActions.GET_ALL_ARTIFACTS.rejected, (state) => {
      state.loadingState.isLoadingArtifacts = false;
      state.artifacts = undefined;
      state.errorState = true;
    }),
    builder.addCase(
      asyncActions.GET_ALL_ARTIFACTS.fulfilled,
      (state, action) => {
        state.isAtRoot = true;
        state.loadingState.isLoadingArtifacts = false;
        state.artifacts = action.payload.artifactListResponse;
      },
    ),
    builder.addCase(asyncActions.FIND_REPOSITORY_BY_NAME.pending, (state) => {
      state.loadingState.isLoadingSearch = true;
    }),
    // TODO: add rejected case
    builder.addCase(
      asyncActions.FIND_REPOSITORY_BY_NAME.fulfilled,
      (state, action) => {
        state.searchRepositoryByName = action.payload.name;
        state.loadingState.isLoadingSearch = false;
        state.artifacts = action.payload.artifactListResponse as
          | IArtifactList
          | IContentList;
      },
    ),
    builder.addCase(asyncActions.CLEAR_SEARCH_REPOSITORY.pending, (state) => {
      state.loadingState.isLoadingSearch = true;
      state.searchRepositoryByName = '';
    }),
    builder.addCase(
      asyncActions.CLEAR_SEARCH_REPOSITORY.fulfilled,
      (state, action) => {
        state.loadingState.isLoadingSearch = false;
        state.artifacts = action.payload.artifactListResponse;
      },
    ),
    builder.addCase(asyncActions.GET_CONTENT_BY_ID.pending, (state) => {
      state.loadingState.isLoadingContent = true;
      state.currentArtifactPermissions = [];
    }),
    builder.addCase(asyncActions.GET_CONTENT_BY_ID.rejected, (state) => {
      state.loadingState.isLoadingContent = false;
      state.errorState = true;
    }),
    builder.addCase(
      asyncActions.GET_CONTENT_BY_ID.fulfilled,
      (state, action) => {
        state.isAtRoot = false;
        state.loadingState.isLoadingContent = false;
        state.artifacts = action.payload.contentResponse.listing;
        state.currentArtifactPermissions =
          action.payload.contentResponse.permissions;
        state.defaultFileId = action.payload.contentResponse.mark_file;
        state.currentArtifactId = action.payload.contentId;
        state.currentArtifactData = {
          ...action.payload.contentResponse.artifact_data,
          id: action.payload.contentId,
        };
      },
    ),
    builder.addCase(
      asyncActions.GET_ROOT_ID_BY_ARTIFACT_ID.pending,
      (state) => {
        state.loadingState.isLoadingRootId = true;
        state.rootId = undefined;
      },
    ),
    builder.addCase(
      asyncActions.GET_ROOT_ID_BY_ARTIFACT_ID.rejected,
      (state) => {
        state.loadingState.isLoadingRootId = false;
        state.errorState = true;
        state.rootId = undefined;
        messageNotification.errorMessage(
          unhookedTranslation(
            'repository-get-root-id-error-notification-title',
          ),
          unhookedTranslation(
            'repository-get-root-id-error-notification-content',
          ),
        );
      },
    ),
    builder.addCase(
      asyncActions.GET_ROOT_ID_BY_ARTIFACT_ID.fulfilled,
      (state, action) => {
        state.loadingState.isLoadingRootId = false;
        state.rootId = action.payload.rootIdResponse.rootId;
      },
    ),
    builder.addCase(asyncActions.GET_BREADCRUMB_BY_ID.pending, (state) => {
      state.loadingState.isLoadingBreadcrumb = true;
    }),
    builder.addCase(asyncActions.GET_BREADCRUMB_BY_ID.rejected, (state) => {
      state.loadingState.isLoadingBreadcrumb = false;
      state.breadcrumb = undefined;
    }),
    builder.addCase(
      asyncActions.GET_BREADCRUMB_BY_ID.fulfilled,
      (state, action) => {
        state.loadingState.isLoadingBreadcrumb = false;
        state.breadcrumb = action.payload.breadcrumbResponse;
      },
    ),
    builder.addCase(asyncActions.NEW_ARTIFACT.pending, (state) => {
      state.isCreatingOrSavingArtifactOrRelease = true;
    }),
    builder.addCase(asyncActions.NEW_ARTIFACT.rejected, (state, action) => {
      state.isCreatingOrSavingArtifactOrRelease = false;
      state.openCreateModal = false;

      switch (action.payload) {
        case 409:
          messageNotification.errorMessage(
            unhookedTranslation('error-artifact-already-exists-title'),
            unhookedTranslation('error-artifact-already-exists-content'),
          );
          break;
      }
    }),
    builder.addCase(asyncActions.NEW_ARTIFACT.fulfilled, (state) => {
      state.isCreatingOrSavingArtifactOrRelease = false;
      state.openCreateModal = false;
      state.shouldUpdate = true;

      messageNotification.successMessage(
        unhookedTranslation('repository-artifact-sucessfuly-created-title'),
        unhookedTranslation('repository-artifact-sucessfuly-created-content'),
      );
    }),
    builder.addCase(asyncActions.NEW_RELEASE.pending, (state) => {
      state.isCreatingOrSavingArtifactOrRelease = true;
    }),
    builder.addCase(asyncActions.NEW_RELEASE.rejected, (state, action) => {
      state.isCreatingOrSavingArtifactOrRelease = false;
      state.openCreateModal = false;

      switch (action.payload) {
        case 409:
          messageNotification.errorMessage(
            unhookedTranslation('error-release-already-exists-folder-title'),
            unhookedTranslation('error-release-already-exists-folder-content'),
          );
          break;
      }
    }),
    builder.addCase(asyncActions.NEW_RELEASE.fulfilled, (state) => {
      state.isCreatingOrSavingArtifactOrRelease = false;
      state.openCreateModal = false;
      state.shouldUpdate = true;

      messageNotification.successMessage(
        unhookedTranslation('repository-release-sucessfuly-created-title'),
        unhookedTranslation('repository-release-sucessfuly-created-content'),
      );
    }),
    builder.addCase(asyncActions.DELETE_DATA.pending, (state) => {
      state.isDeletingData = true;
    }),
    builder.addCase(asyncActions.DELETE_DATA.rejected, (state) => {
      state.isDeletingData = false;
      state.shouldUpdate = true;
      messageNotification.errorMessage(
        unhookedTranslation('repository-item-delete-failed-title'),
        unhookedTranslation('repository-item-delete-failed-description'),
      );
    }),
    builder.addCase(asyncActions.DELETE_DATA.fulfilled, (state) => {
      state.isDeletingData = false;
      state.shouldUpdate = true;

      const sucessMessageDescription =
        state.deleteType === RepositoryContentTypes.File
          ? 'repository-file-sucessfuly-deleted-content'
          : state.isAtRoot
            ? 'repository-artifact-sucessfuly-deleted-content'
            : 'repository-release-sucessfuly-deleted-content';

      messageNotification.successMessage(
        unhookedTranslation('repository-item-sucessfuly-deleted-title'),
        unhookedTranslation(sucessMessageDescription),
      );
    }),
    builder.addCase(asyncActions.DELETE_MULTIPLE_DATA.pending, (state) => {
      state.isDeletingData = true;
    }),
    builder.addCase(asyncActions.DELETE_MULTIPLE_DATA.rejected, (state) => {
      state.isDeletingData = false;
      state.shouldUpdate = true;
      messageNotification.errorMessage(
        unhookedTranslation('repository-items-delete-failed-title'),
        unhookedTranslation('repository-items-delete-failed-description'),
      );
    }),
    builder.addCase(asyncActions.DELETE_MULTIPLE_DATA.fulfilled, (state) => {
      state.isDeletingData = false;
      state.shouldUpdate = true;

      const sucessMessageDescription =
        state.deleteType === RepositoryContentTypes.Multiple
          ? 'repository-items-sucessfuly-deleted-content'
          : state.deleteType === RepositoryContentTypes.File
            ? 'repository-files-sucessfuly-deleted-content'
            : 'repository-folders-sucessfuly-deleted-content';

      messageNotification.successMessage(
        unhookedTranslation('repository-item-sucessfuly-deleted-title'),
        unhookedTranslation(sucessMessageDescription),
      );
    }),
    builder.addCase(
      asyncActions.UPDATE_ARTIFACT_OR_RELEASE.pending,
      (state) => {
        state.isCreatingOrSavingArtifactOrRelease = true;
      },
    ),
    builder.addCase(
      asyncActions.UPDATE_ARTIFACT_OR_RELEASE.rejected,
      (state) => {
        state.isCreatingOrSavingArtifactOrRelease = false;
      },
    ),
    builder.addCase(
      asyncActions.UPDATE_ARTIFACT_OR_RELEASE.fulfilled,
      (state, action) => {
        state.isCreatingOrSavingArtifactOrRelease = false;
        state.openEditModal = false;
        state.shouldUpdate = true;

        const updateType = action.payload.updateType;
        if (updateType === RepositoryContentTypes.Artifact) {
          messageNotification.successMessage(
            unhookedTranslation('repository-artifact-sucessfuly-updated-title'),
            unhookedTranslation(
              'repository-artifact-sucessfuly-updated-content',
            ),
          );
        } else if (updateType === RepositoryContentTypes.Release) {
          messageNotification.successMessage(
            unhookedTranslation('repository-release-sucessfuly-updated-title'),
            unhookedTranslation(
              'repository-release-sucessfuly-updated-content',
            ),
          );
        }
      },
    ),
    builder.addCase(asyncActions.TRANSFER_FILE.pending, (state) => {
      state.transferState.isTransferring = true;
      state.transferState.isTransferComplete = false;
      state.fileDownloaded = false;
    }),
    builder.addCase(asyncActions.TRANSFER_FILE.rejected, (state) => {
      state.transferState.isTransferring = false;
      state.transferState.isTransferComplete = false;
      messageNotification.errorMessage(
        unhookedTranslation('repository-transfer-file-error-title'),
        unhookedTranslation('repository-transfer-file-error-content'),
      );
    }),
    builder.addCase(asyncActions.TRANSFER_FILE.fulfilled, (state, action) => {
      state.transferState.isTransferring = false;
      state.transferState.isTransferComplete = true;
      state.transferState.transferProgress = 100;
      state.transferState.transferredFileName = action.payload.headers[
        'content-disposition'
      ]
        .split(';')[1]
        .split('=')[1]
        .replace(/"/g, '');
      state.transferState.transferredData = action.payload.transferFileResponse;
    }),
    builder.addCase(asyncActions.SET_DEFAULT_FILE.pending, (state) => {
      state.isSettingFileAsDefault = true;
    }),
    builder.addCase(asyncActions.SET_DEFAULT_FILE.rejected, (state) => {
      state.isSettingFileAsDefault = false;
    }),
    builder.addCase(
      asyncActions.SET_DEFAULT_FILE.fulfilled,
      (state, action) => {
        state.isSettingFileAsDefault = false;
        state.shouldUpdate = true;

        const isUnsetingDefaultFile =
          action.meta.arg.id === state.defaultFileId;

        messageNotification.successMessage(
          unhookedTranslation('repository-set-default-file-success-title'),
          unhookedTranslation(
            isUnsetingDefaultFile
              ? 'repository-unset-default-file-success-description'
              : 'repository-set-default-file-success-description',
          ),
        );
      },
    ),
    builder.addCase(asyncActions.MOVE_FILE.pending, (state) => {
      state.loadingState.isLoadingMoveFiles = true;
    }),
    builder.addCase(asyncActions.MOVE_FILE.rejected, (state) => {
      state.loadingState.isLoadingMoveFiles = false;
    }),
    builder.addCase(asyncActions.MOVE_FILE.fulfilled, (state) => {
      state.loadingState.isLoadingMoveFiles = false;
      state.shouldUpdate = true;
      messageNotification.successMessage(
        unhookedTranslation('repository-move-file-success-title'),
        unhookedTranslation('repository-move-file-success-content'),
      );
    }),
    builder.addCase(asyncActions.MOVE_FILES.pending, (state) => {
      state.loadingState.isLoadingMoveFiles = true;
      state.isMovingFilesComplete = false;
    }),
    builder.addCase(asyncActions.MOVE_FILES.rejected, (state) => {
      state.isMovingFilesComplete = true;
      if (!state.isMovingFilesAndArtifacts) {
        state.loadingState.isLoadingMoveFiles = false;
      }
    }),
    builder.addCase(asyncActions.MOVE_FILES.fulfilled, (state) => {
      state.loadingState.isLoadingMoveFiles = false;

      messageNotification.successMessage(
        unhookedTranslation('repository-move-files-success-title'),
        unhookedTranslation('repository-move-files-success-content'),
      );

      state.isMovingFilesComplete = true;

      if (!state.isMovingFilesAndArtifacts) {
        state.shouldUpdate = true;
      }
    }),
    builder.addCase(asyncActions.COPY_FILE.pending, (state) => {
      state.loadingState.isLoadingCopyFile = true;
    }),
    builder.addCase(asyncActions.COPY_FILE.rejected, (state) => {
      state.loadingState.isLoadingCopyFile = false;
    }),
    builder.addCase(asyncActions.COPY_FILE.fulfilled, (state) => {
      state.loadingState.isLoadingCopyFile = false;

      messageNotification.successMessage(
        unhookedTranslation('repository-copy-file-success-title'),
        unhookedTranslation('repository-copy-file-success-content'),
      );
    }),
    builder.addCase(asyncActions.COPY_FILES.pending, (state) => {
      state.loadingState.isLoadingCopyFiles = true;
      state.isCopyingFilesComplete = false;
    }),
    builder.addCase(asyncActions.COPY_FILES.rejected, (state) => {
      state.isCopyingFilesComplete = true;
      if (!state.isCopyingFilesAndArtifacts) {
        state.loadingState.isLoadingCopyFiles = false;
      }
    }),
    builder.addCase(asyncActions.COPY_FILES.fulfilled, (state) => {
      state.loadingState.isLoadingCopyFiles = false;

      messageNotification.successMessage(
        unhookedTranslation('repository-copy-files-success-title'),
        unhookedTranslation('repository-copy-files-success-content'),
      );

      state.isCopyingFilesComplete = true;

      if (!state.isCopyingFilesAndArtifacts) {
        state.shouldUpdate = true;
      }
    }),
    builder.addCase(asyncActions.MOVE_ARTIFACT.pending, (state) => {
      state.loadingState.isLoadingMoveArtifacts = true;
    }),
    builder.addCase(asyncActions.MOVE_ARTIFACT.rejected, (state) => {
      state.loadingState.isLoadingMoveArtifacts = false;
    }),
    builder.addCase(asyncActions.MOVE_ARTIFACT.fulfilled, (state) => {
      state.loadingState.isLoadingMoveArtifacts = false;
      state.shouldUpdate = true;
      messageNotification.successMessage(
        unhookedTranslation('repository-move-artifact-success-title'),
        unhookedTranslation('repository-move-artifact-success-content'),
      );
    }),
    builder.addCase(asyncActions.MOVE_ARTIFACTS.pending, (state) => {
      state.loadingState.isLoadingMoveArtifacts = true;
      state.isMovingArtifactsComplete = false;
    }),
    builder.addCase(asyncActions.MOVE_ARTIFACTS.rejected, (state) => {
      state.isMovingArtifactsComplete = true;
      if (!state.isMovingFilesAndArtifacts) {
        state.loadingState.isLoadingMoveArtifacts = false;
      }
    }),
    builder.addCase(asyncActions.MOVE_ARTIFACTS.fulfilled, (state) => {
      state.loadingState.isLoadingMoveArtifacts = false;

      messageNotification.successMessage(
        unhookedTranslation('repository-move-artifacts-success-title'),
        unhookedTranslation('repository-move-artifacts-success-content'),
      );

      state.isMovingArtifactsComplete = true;

      if (!state.isMovingFilesAndArtifacts) {
        state.shouldUpdate = true;
      }
    }),
    builder.addCase(asyncActions.COPY_ARTIFACT.pending, (state) => {
      state.loadingState.isLoadingCopyArtifact = true;
    }),
    builder.addCase(asyncActions.COPY_ARTIFACT.rejected, (state) => {
      state.loadingState.isLoadingCopyArtifact = false;
    }),
    builder.addCase(asyncActions.COPY_ARTIFACT.fulfilled, (state) => {
      state.loadingState.isLoadingCopyArtifact = false;

      messageNotification.successMessage(
        unhookedTranslation('repository-copy-folder-success-title'),
        unhookedTranslation('repository-copy-folder-success-content'),
      );
    }),
    builder.addCase(asyncActions.COPY_ARTIFACTS.pending, (state) => {
      state.loadingState.isLoadingCopyArtifacts = true;
      state.isMovingArtifactsComplete = false;
    }),
    builder.addCase(asyncActions.COPY_ARTIFACTS.rejected, (state) => {
      state.isCopyingArtifactsComplete = true;
      if (!state.isCopyingFilesAndArtifacts) {
        state.loadingState.isLoadingCopyArtifacts = false;
      }
    }),
    builder.addCase(asyncActions.COPY_ARTIFACTS.fulfilled, (state) => {
      state.loadingState.isLoadingCopyArtifacts = false;

      messageNotification.successMessage(
        unhookedTranslation('repository-copy-folders-success-title'),
        unhookedTranslation('repository-copy-folders-success-content'),
      );

      state.isCopyingArtifactsComplete = true;

      if (!state.isCopyingFilesAndArtifacts) {
        state.shouldUpdate = true;
      }
    }),
    builder.addCase(
      asyncActions.GET_PAGE_BY_SLUG.fulfilled,
      (state, action) => {
        state.pageObjectResponse = action.payload.pageObjectResponse;
      },
    ),
    builder.addCase(asyncActions.GET_PAGE_BY_SLUG.rejected, (state) => {
      state.pageRejected = true;
    }),
    builder.addCase(asyncActions.NEW_ARTIFACT_EVALUATION.pending, (state) => {
      state.isEvaluatingItem = true;
    }),
    builder.addCase(asyncActions.NEW_ARTIFACT_EVALUATION.rejected, (state) => {
      state.isEvaluatingItem = false;
      state.openEvaluationModal = false;
    }),
    builder.addCase(asyncActions.NEW_ARTIFACT_EVALUATION.fulfilled, (state) => {
      state.isEvaluatingItem = false;
      state.openEvaluationModal = false;
      state.shouldUpdate = true;

      messageNotification.successMessage(
        unhookedTranslation(
          'repository-evaluation-artifact-sucessfuly-created-title',
        ),
        unhookedTranslation(
          'repository-evaluation-artifact-sucessfuly-created-content',
        ),
      );
    }),
    builder.addCase(asyncActions.NEW_FILE_EVALUATION.pending, (state) => {
      state.isEvaluatingItem = true;
    }),
    builder.addCase(asyncActions.NEW_FILE_EVALUATION.rejected, (state) => {
      state.isEvaluatingItem = false;
      state.openEvaluationModal = false;
    }),
    builder.addCase(asyncActions.NEW_FILE_EVALUATION.fulfilled, (state) => {
      state.isEvaluatingItem = false;
      state.openEvaluationModal = false;
      state.shouldUpdate = true;

      messageNotification.successMessage(
        unhookedTranslation(
          'repository-evaluation-file-sucessfuly-created-title',
        ),
        unhookedTranslation(
          'repository-evaluation-file-sucessfuly-created-content',
        ),
      );
    }),
    builder.addCase(
      asyncActions.UPDATE_ARTIFACT_EVALUATION.pending,
      (state) => {
        state.isEvaluatingItem = true;
      },
    ),
    builder.addCase(
      asyncActions.UPDATE_ARTIFACT_EVALUATION.rejected,
      (state) => {
        state.isEvaluatingItem = false;
      },
    ),
    builder.addCase(
      asyncActions.UPDATE_ARTIFACT_EVALUATION.fulfilled,
      (state) => {
        state.isEvaluatingItem = false;
        state.openEditEvaluationModal = false;
        state.shouldUpdate = true;

        messageNotification.successMessage(
          unhookedTranslation(
            'repository-evaluation-artifact-sucessfuly-updated-title',
          ),
          unhookedTranslation(
            'repository-evaluation-artifact-sucessfuly-updated-content',
          ),
        );
      },
    ),
    builder.addCase(asyncActions.UPDATE_FILE_EVALUATION.pending, (state) => {
      state.isEvaluatingItem = true;
    }),
    builder.addCase(asyncActions.UPDATE_FILE_EVALUATION.rejected, (state) => {
      state.isEvaluatingItem = false;
    }),
    builder.addCase(asyncActions.UPDATE_FILE_EVALUATION.fulfilled, (state) => {
      state.isEvaluatingItem = false;
      state.openEditEvaluationModal = false;
      state.shouldUpdate = true;

      messageNotification.successMessage(
        unhookedTranslation(
          'repository-evaluation-file-sucessfuly-updated-title',
        ),
        unhookedTranslation(
          'repository-evaluation-file-sucessfuly-updated-content',
        ),
      );
    }),
    builder.addCase(
      asyncActions.DELETE_ARTIFACT_EVALUATION.pending,
      (state) => {
        state.isDeletingEvaluation = true;
      },
    ),
    builder.addCase(
      asyncActions.DELETE_ARTIFACT_EVALUATION.rejected,
      (state) => {
        state.isDeletingEvaluation = false;
        state.shouldUpdate = true;
      },
    ),
    builder.addCase(
      asyncActions.DELETE_ARTIFACT_EVALUATION.fulfilled,
      (state) => {
        state.isDeletingEvaluation = false;
        state.shouldUpdate = true;

        messageNotification.successMessage(
          unhookedTranslation(
            'repository-evaluation-artifact-sucessfuly-deleted-title',
          ),
          unhookedTranslation(
            'repository-evaluation-artifact-sucessfuly-deleted-content',
          ),
        );
      },
    ),
    builder.addCase(asyncActions.DELETE_FILE_EVALUATION.pending, (state) => {
      state.isDeletingEvaluation = true;
    }),
    builder.addCase(asyncActions.DELETE_FILE_EVALUATION.rejected, (state) => {
      state.isDeletingEvaluation = false;
      state.shouldUpdate = true;
    }),
    builder.addCase(asyncActions.DELETE_FILE_EVALUATION.fulfilled, (state) => {
      state.isDeletingEvaluation = false;
      state.shouldUpdate = true;

      messageNotification.successMessage(
        unhookedTranslation(
          'repository-evaluation-file-sucessfuly-deleted-title',
        ),
        unhookedTranslation(
          'repository-evaluation-file-sucessfuly-deleted-content',
        ),
      );
    }),
  ],
});
