import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  administrativePanelLogsApi,
  ILogsItem,
} from '../../../../services/administrative-panel-logs.api';

export interface ILogs {
  showFilter: boolean;
  logsListing: ILogsTabs;
}

export interface ILogsTabs {
  logsList: ILogsItem[];
  pageSize: number;
  pageIndex: number;
  totalNumberOfElements: number;
  shouldRefresh: boolean;
  tableLoading: boolean;
  logsQueryString: string[];
  logsFirstQueryBehavior: boolean;
  sortBy: string | undefined;
  filterBy: string | undefined;
  filter: ILogsFilter;
  errorState: boolean;
}

interface ILogsFilter {
  type: string | undefined;
  userName: string | undefined;
  company: string | undefined;
  detail: string | undefined;
  createdAt: any;
}

const initialState: ILogs = {
  showFilter: false,
  logsListing: {
    logsList: [],
    pageSize: 15,
    pageIndex: 1,
    totalNumberOfElements: 0,
    shouldRefresh: true,
    tableLoading: false,
    logsQueryString: [],
    logsFirstQueryBehavior: true,
    sortBy: undefined,
    filterBy: undefined,
    filter: {
      type: undefined,
      userName: undefined,
      company: undefined,
      detail: undefined,
      createdAt: undefined,
    },
    errorState: false,
  },
};

export const asyncActions = {
  GET_LOGS_LIST: createAsyncThunk(
    'GET_LOGS_LIST',
    async (queryParams: string) => {
      const logsListing = await administrativePanelLogsApi.getLogs(queryParams);

      return logsListing.data;
    },
  ),
};

export const LogsListingSlice = createSlice({
  name: 'LogsListingSlice',
  initialState,
  reducers: {
    TOGGLE_FILTER: (state) => {
      state.showFilter = !state.showFilter;
    },
    FILTER_SET_QUERY_STRING: (state, action) => {
      state.logsListing.filterBy = action.payload;
      state.logsListing.shouldRefresh = true;
      state.logsListing.pageIndex = initialState.logsListing.pageIndex;
    },
    LOGS_FILTER_SET_TYPE: (state, action) => {
      state.logsListing.filter.type = action.payload;
    },
    LOGS_FILTER_SET_USERNAME: (state, action) => {
      state.logsListing.filter.userName = action.payload;
    },
    LOGS_FILTER_SET_COMPANY: (state, action) => {
      state.logsListing.filter.company = action.payload;
    },
    LOGS_FILTER_SET_DETAIL: (state, action) => {
      state.logsListing.filter.detail = action.payload;
    },
    LOGS_FILTER_SET_CREATED_AT: (state, action) => {
      state.logsListing.filter.createdAt = action.payload;
    },
    FILTER_CLEAR_FIELDS: (state) => {
      if (state.logsListing.filterBy) {
        state.logsListing.pageIndex = initialState.logsListing.pageIndex;
      }
      state.logsListing.filterBy = initialState.logsListing.filterBy;
      state.logsListing.shouldRefresh = true;
      state.logsListing.filter = initialState.logsListing.filter;
    },
    LOGS_BACK_TO_ORIGINAL_STATE: () => {
      return {
        ...initialState,
      };
    },
    LOGS_BACK_TO_ORIGINAL_STATE_PERSISTING_FILTER: (state) => {
      return {
        ...initialState,
        showFilter: state.showFilter,
        logsListing: state.logsListing,
      };
    },
    LOGS_CHANGE_SORT: (state, action) => {
      state.logsListing.shouldRefresh = true;
      state.logsListing.sortBy = action.payload;
    },
    LOGS_DISABLE_DEFAULT_SORT_BEHAVIOUR: (state) => {
      state.logsListing.logsFirstQueryBehavior = false;
    },
    LOGS_CHANGE_PAGE: (state, action) => {
      state.logsListing.pageIndex = action.payload;
    },
  },
  extraReducers: (builder) => [
    builder.addCase(asyncActions.GET_LOGS_LIST.pending, (state) => {
      state.logsListing.tableLoading = true;
    }),
    builder.addCase(asyncActions.GET_LOGS_LIST.rejected, (state) => {
      state.logsListing.tableLoading = false;
      state.logsListing.errorState = true;
    }),
    builder.addCase(asyncActions.GET_LOGS_LIST.fulfilled, (state, action) => {
      const newLogsList = action.payload.content.map((item) => {
        const userWithFullName = {
          ...item.user,
          fullName: `${item.user.firstName} ${item.user.lastName}`,
        };
        return {
          ...item,
          user: userWithFullName,
        };
      });

      state.logsListing.logsList = newLogsList;
      state.logsListing.totalNumberOfElements = action.payload.totalElements;
      state.logsListing.shouldRefresh = false;
      state.logsListing.tableLoading = false;
    }),
  ],
});
