import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Table,
  Menu,
  Tooltip,
  Popover,
  Button,
  Input,
  Form,
} from 'antd';
import { TableRowSelection } from 'antd/lib/table/interface';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as EditPencil } from '../../../assets/popover-icons/edit.svg';
import { ReactComponent as TrashCan } from '../../../assets/popover-icons/trash-can.svg';
import { ReactComponent as Info } from '../../../assets/avatar-dropdown/logs.svg';
import { ReactComponent as Move } from '../../../assets/popover-icons/move.svg';
import { ReactComponent as Download } from '../../../assets/popover-icons/download.svg';

import './RepositoryTable.scss';
import { useTranslation } from 'react-i18next';
import { AppDispatch, RootState } from '../../../store/store';
import { Icon } from './Icon/Icon';
import {
  CopyOutlined,
  LikeFilled,
  LikeOutlined,
  LinkOutlined,
  RightCircleOutlined,
  StarFilled,
  StarOutlined,
} from '@ant-design/icons';
import { IDefaultSortBehaviour } from '../../../components/PagingTable/PagingTable';
import { showModal } from '../../../helpers/showModal/showModal';
import { useNavigate } from 'react-router-dom';
import { RepositorySlice } from '../Repository.Slice';

interface IRepositoryTable {
  dataSource: any[];
  tableLoading: boolean;
  isSearching: boolean;
  onChangeSort: Function;
  rowClickAction: Function;
  emptyText: string;
  disableOptionsColumn?: boolean;
  getIdFromRow?: (rowData: any) => number | string;
  selectRowsToGivenTab: Function;
  selectIdFromRowsSelected: Function;
  onMoveItem: (
    destinationUrlOrId: string,
    itemId: number,
    itemType: string,
  ) => void;
  onCopyItem: (
    destinationUrlOrId: string,
    itemId: number,
    itemType: string,
  ) => void;
  onOpenLateralInfo: Function;
  onEditArtifact: Function;
  onEditRelease: Function;
  defaultFileId?: number | undefined;
  setDefaultFile: Function;
  getFileDownloadUrl: Function;
  downloadFile: Function;
  deleteItem: Function;
  formatDateColumn: Function;
  isUserAdmin: boolean;
  defaultSortBehaviour: IDefaultSortBehaviour;
  onEvaluation: Function;
}

interface IColumnCursor {
  columnTitle: string;
  columnProperty: string | string[];
  disableSort?: boolean;
  width?: number;
  onResizeAction?: any;
}

interface ITupleOptions {
  optionId?: string;
  optionName: string;
  optionIcon: JSX.Element;
  optionOnClick: Function;
  optionDisableVerification?: Function;
  optionDisabledMessage?: string;
}

enum PopoverType {
  PopoverCopy,
  PopoverMove,
}

interface IPopoverState {
  itemId: number;
  itemType: string;
  type: PopoverType;
}

export const RepositoryTable: React.FC<IRepositoryTable> = ({
  dataSource,
  tableLoading,
  isSearching,
  onChangeSort,
  rowClickAction,
  emptyText,
  disableOptionsColumn = false,
  getIdFromRow = (rowData: any) => {
    return `${rowData.genericType}-${rowData.id}`;
  },
  selectRowsToGivenTab,
  selectIdFromRowsSelected,
  onOpenLateralInfo,
  onEditArtifact,
  onEditRelease,
  onMoveItem,
  onCopyItem,
  defaultFileId,
  setDefaultFile,
  getFileDownloadUrl,
  downloadFile,
  deleteItem,
  formatDateColumn,
  isUserAdmin,
  defaultSortBehaviour,
  onEvaluation,
}: IRepositoryTable) => {
  const { t } = useTranslation();

  const dateColumnsProperties = ['updatedAt'];

  const isAtRoot = useSelector((state: RootState) => state.repository.isAtRoot);

  const [popoverState, setPopoverState] = useState<IPopoverState | undefined>(
    undefined,
  );

  const dispatch = useDispatch<AppDispatch>();

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const navigate = useNavigate();

  useEffect(() => {
    setSelectedRowKeys([]);
    selectRowsToGivenTab([]);
    selectIdFromRowsSelected([]);
  }, [dataSource]);

  const [columnsWidth, setColumnsWidth] = useState({
    nameColumnWidth: 3,
    lastModifiedColumnWidth: 2.5,
    sizeColumnWidth: 1.5,
    pathColumnWidth: 3,
  });

  const resizeHandlerSpeed = 0.028;
  const resizeColumns = (
    columnName:
      | 'nameColumnWidth'
      | 'lastModifiedColumnWidth'
      | 'sizeColumnWidth',
    movement: number,
  ) => {
    setColumnsWidth({
      ...columnsWidth,
      [columnName]: (columnsWidth[columnName] += movement * resizeHandlerSpeed),
    });
  };

  const repositoryColumns: IColumnCursor[] = [
    {
      columnTitle: t('repository-artifact-label'),
      columnProperty: 'name',
      width: columnsWidth.nameColumnWidth,
      onResizeAction: (event: MouseEvent) => {
        resizeColumns('nameColumnWidth', event.movementX);
      },
    },
    {
      columnTitle: t('repository-last-modified'),
      columnProperty: 'updatedAt',
      width: columnsWidth.lastModifiedColumnWidth,
      onResizeAction: (event: MouseEvent) => {
        resizeColumns('lastModifiedColumnWidth', event.movementX);
      },
    },
  ];

  if (!isAtRoot) {
    repositoryColumns.push({
      columnTitle: t('repository-artifact-size-label'),
      columnProperty: 'contentLengthString',
      disableSort: true,
      width: columnsWidth.sizeColumnWidth,
      onResizeAction: (event: MouseEvent) => {
        resizeColumns('sizeColumnWidth', event.movementX);
      },
    });
  }

  if (isSearching) {
    repositoryColumns.push({
      columnTitle: t('repository-artifact-location'),
      columnProperty: 'path',
      disableSort: true,
      width: columnsWidth.pathColumnWidth,
      onResizeAction: (event: MouseEvent) => {
        resizeColumns('sizeColumnWidth', event.movementX);
      },
    });
  }

  const sortHandler = (
    field: string | string[],
    order: string | undefined | null,
  ) => {
    onChangeSort(field, order);
  };

  const rowSelectionProperties: TableRowSelection<any> = {
    selectedRowKeys,
    preserveSelectedRowKeys: true,
    type: 'checkbox',
    onChange: (newSelectedRowKeys, selectedRows) => {
      setSelectedRowKeys(newSelectedRowKeys);
      selectRowsToGivenTab(selectedRows);
      selectIdFromRowsSelected(selectedRows.map((y) => getIdFromRow(y)));
    },
    columnWidth: '0.5rem',
  };

  const goToEvaluations = (evaluatedItem: any) => {
    navigate('/evaluations', {
      state: {
        itemId: evaluatedItem.id,
        itemTitle: evaluatedItem.name,
        itemType: evaluatedItem.genericType,
      },
    });
  };

  const mockedRootOptions = [
    {
      optionName: t('repository-list-edit-button'),
      optionIcon: <EditPencil />,
      optionOnClick: (_id: number, item: any) => {
        onEditArtifact(item.id);
      },
    },
    {
      optionName: t('repository-list-move-button'),
      optionIcon: <Move />,
      optionOnClick: (id: number, item: any) => {
        setPopoverState({
          itemId: item.id,
          itemType: item.genericType,
          type: PopoverType.PopoverMove,
        });
      },
    },
    {
      optionName: t('repository-list-delete-button'),
      optionIcon: <TrashCan />,
      optionOnClick: (_id: number, item: any) => {
        deleteItem(item.id);
      },
    },
    {
      optionName: t('repository-list-information-button'),
      optionIcon: <Info />,
      optionOnClick: (_id: number, item: any) => {
        onOpenLateralInfo(item);
      },
    },
    {
      optionName: t('repository-list-evaluation-button'),
      optionIcon: <LikeFilled style={{ color: '#41b4d2' }} />,
      optionOnClick: (_id: number, record: any) => {
        const hasEvaluations =
          record.genericType === 'ARTIFACT'
            ? record.artifactReviewDTO
            : record.fileReviewDTO;

        const hasEvaluationsList =
          record.genericType === 'ARTIFACT'
            ? record.listArtifactReviewsDTOs.length > 0
            : record.listFileReviewsDTOs.length > 0;

        if (!isUserAdmin && hasEvaluations) {
          dispatch(RepositorySlice.actions.SET_EDIT_EVALUATION_MODE());
        }

        if (isUserAdmin && !hasEvaluationsList) {
          showModal(
            'info',
            `${t('repository-evaluation-title-label')} ${record.name}`,
            `${t('repository-evaluation-not-evaluated-yet-label')}`,
            () => {},
            () => {},
            t('repository-evaluation-modal-close-button'),
            '',
            undefined,
          );
          return;
        }

        if (isUserAdmin && hasEvaluationsList) {
          return goToEvaluations(record);
        }
        onEvaluation(record);
      },
    },
  ];

  const mockedChildrenOptions = [
    {
      optionName: t('repository-list-set-main-file-button'),
      optionIcon: <StarOutlined style={{ color: '#41B4D2' }} />,
      optionOnClick: (_id: number, record: any) => {
        if (record.genericType === 'FILE') {
          setDefaultFile(record.id);
        }
      },
    },
    {
      optionName: t('repository-list-edit-button'),
      optionIcon: <EditPencil />,
      optionOnClick: (_id: number, record: any) => {
        onEditRelease(record.id);
      },
    },
    {
      optionName: t('repository-list-copy-link-button'),
      optionIcon: <LinkOutlined style={{ color: '#41B4D2' }} />,
      optionOnClick: (_id: number, record: any) => {
        if (record.genericType !== 'FILE') {
          return;
        }

        if (isSearching) {
          getFileDownloadUrl(record.id, record.parentArtifactId);
          return;
        }

        getFileDownloadUrl(record.id);
      },
    },
    {
      optionName: t('repository-list-download-button'),
      optionIcon: <Download />,
      optionOnClick: (_id: number, record: any) => {
        if (record.genericType !== 'FILE') {
          return;
        }

        if (isSearching) {
          downloadFile(record.id, record.name, record.rootArtifactId);
          return;
        }

        downloadFile(record.id, record.name);
      },
    },
    {
      optionName: t('repository-list-copy-button'),
      optionIcon: <CopyOutlined style={{ color: '#41b4d2' }} />,
      optionOnClick: (id: number, item: any) => {
        setPopoverState({
          itemId: id,
          itemType: item.genericType,
          type: PopoverType.PopoverCopy,
        });
      },
    },
    {
      optionName: t('repository-list-move-button'),
      optionIcon: <Move />,
      optionOnClick: (id: number, item: any) => {
        setPopoverState({
          itemId: id,
          itemType: item.genericType,
          type: PopoverType.PopoverMove,
        });
      },
    },
    {
      optionName: t('repository-list-delete-button'),
      optionIcon: <TrashCan />,
      optionOnClick: (_id: number, record: any) => {
        deleteItem(record.id);
      },
    },
    {
      optionName: t('repository-list-information-button'),
      optionIcon: <Info />,
      optionOnClick: (_id: number, record: any) => {
        onOpenLateralInfo(record);
      },
    },
    {
      optionName: t('repository-list-evaluation-button'),
      optionIcon: <LikeFilled style={{ color: '#41b4d2' }} />,
      optionOnClick: (_id: number, record: any) => {
        const hasEvaluations =
          record.genericType === 'ARTIFACT'
            ? record.artifactReviewDTO
            : record.fileReviewDTO;

        const hasEvaluationsList =
          record.genericType === 'ARTIFACT'
            ? record.listArtifactReviewsDTOs.length > 0
            : record.listFileReviewsDTOs.length > 0;

        if (
          !record.canEvaluate &&
          !isUserAdmin &&
          record.genericType === 'FILE'
        ) {
          showModal(
            'info',
            `${t('evaluation-modal-title')} ${record.name}`,
            `${t('repository-evaluation-not-allowed-evaluation-label')}`,
            () => {},
            () => {},
            t('repository-evaluation-modal-close-button'),
            '',
            undefined,
          );
          return;
        }

        if (!isUserAdmin && hasEvaluations) {
          dispatch(RepositorySlice.actions.SET_EDIT_EVALUATION_MODE());
        }

        if (isUserAdmin && !hasEvaluationsList) {
          const hasEvaluationsLabel =
            record.genericType === 'ARTIFACT'
              ? t('repository-evaluation-not-evaluated-yet-label')
              : t('repository-evaluation-file-not-evaluated-yet-label');

          showModal(
            'info',
            `${t('repository-evaluation-title-label')} ${record.name}`,
            hasEvaluationsLabel,
            () => {},
            () => {},
            t('repository-evaluation-modal-close-button'),
            '',
            undefined,
          );
          return;
        }

        if (isUserAdmin && hasEvaluationsList) {
          return goToEvaluations(record);
        }

        onEvaluation(record);
      },
    },
  ];

  const generateOptions = (
    menuOptions: ITupleOptions[],
    itemId: number,
    rowItem: any,
  ) => {
    if (!isAtRoot || isSearching) {
      menuOptions.filter((item) => item.optionName !== 'copiar link');
    }

    const menuOptionItens = menuOptions
      .filter((x) => {
        if (
          x.optionName === t('repository-list-edit-button') ||
          x.optionName === t('repository-list-copy-button') ||
          x.optionName === t('repository-list-move-button') ||
          x.optionName === t('repository-list-delete-button')
        ) {
          return rowItem.permissions.includes('WRITE');
        }

        return true;
      })
      .map((x) => {
        const itemWithIconAndName = (
          <Tooltip placement="left" title={x.optionName}>
            <div>{x.optionIcon}</div>
          </Tooltip>
        );

        const disableItem = x.optionDisableVerification
          ? x.optionDisableVerification(itemId, rowItem)
          : undefined;

        const disabledItemWithTooltip = (
          <Tooltip placement="left" title={x.optionDisabledMessage}>
            <div>{x.optionIcon}</div>
          </Tooltip>
        );

        return (
          <Menu.Item
            key={x.optionName.toLowerCase()}
            onClick={() => {
              x.optionOnClick(itemId, rowItem);
            }}
            disabled={disableItem}
          >
            {disableItem ? disabledItemWithTooltip : itemWithIconAndName}
          </Menu.Item>
        );
      });

    return (
      <Popover
        trigger="click"
        placement="topRight"
        open={
          popoverState
            ? popoverState.itemId === rowItem.id &&
              popoverState.itemType === rowItem.genericType
            : false
        }
        onOpenChange={(isOpen) => {
          if (!isOpen) {
            setPopoverState(undefined);
          }
        }}
        content={
          <Form
            name="moveUrlForm"
            layout="inline"
            autoComplete="off"
            onFinish={(values) => {
              onMoveItem(values.url, rowItem.id, rowItem.genericType);
              setPopoverState(undefined);
            }}
          >
            <Form.Item noStyle>
              <Input.Group compact>
                <Form.Item name="url" rules={[{ required: true }]} noStyle>
                  <Input
                    style={{ width: '200px' }}
                    placeholder={t('repository-move-url-placeholder')}
                  />
                </Form.Item>
                <Form.Item noStyle>
                  <Button type="primary" htmlType="submit">
                    <RightCircleOutlined />
                  </Button>
                </Form.Item>
              </Input.Group>
            </Form.Item>
          </Form>
        }
      >
        <Menu className="paging-table-options-column">{menuOptionItens}</Menu>
      </Popover>
    );
  };

  const checkIfColumnHasDefaultSortBehaviour = (columnProperty: string) => {
    if (
      defaultSortBehaviour &&
      defaultSortBehaviour.columnProperty === columnProperty
    ) {
      return defaultSortBehaviour.order;
    }

    return undefined;
  };

  const generateColumns = () => {
    let tableColumns: JSX.Element[] = [];

    tableColumns = repositoryColumns.map((x: any) => {
      if (dateColumnsProperties.includes(x.columnProperty as string)) {
        return (
          <>
            <Table.Column
              title={x.columnTitle}
              key={x.columnProperty.toString()}
              sorter
              onCell={(record: any) => {
                return {
                  onClick: () => rowClickAction(record),
                };
              }}
              className={x.className}
              ellipsis
              showSorterTooltip={false}
              render={(y: any) =>
                formatDateColumn(y[x.columnProperty as string])
              }
              width={x.width ? `${x.width}rem` : '4.5rem'}
            />
            <Table.Column
              className="resizeHandle"
              onHeaderCell={() => {
                return {
                  onMouseDown: () => {
                    window.addEventListener('mousemove', x.onResizeAction);
                    window.addEventListener('mouseup', () => {
                      window.removeEventListener('mousemove', x.onResizeAction);
                    });
                  },
                };
              }}
            />
          </>
        );
      }

      if (x.columnProperty === 'name') {
        return (
          <>
            <Table.Column
              title={x.columnTitle}
              key={x.columnProperty.toString()}
              sorter
              onCell={(record: any) => {
                return {
                  onClick: () => rowClickAction(record),
                };
              }}
              className={x.className}
              defaultSortOrder={checkIfColumnHasDefaultSortBehaviour(
                x.columnProperty as string,
              )}
              ellipsis
              showSorterTooltip={false}
              render={(record: any) => {
                return (
                  <span title={record[x.columnProperty as string]}>
                    <Icon
                      fileName={record.name}
                      genericType={record.genericType}
                    />
                    {record[x.columnProperty as string]}
                  </span>
                );
              }}
              width={x.width ? `${x.width}rem` : '4.2rem'}
            />
            <Table.Column
              className="resizeHandle"
              onHeaderCell={() => {
                return {
                  onMouseDown: () => {
                    window.addEventListener('mousemove', x.onResizeAction);
                    window.addEventListener('mouseup', () => {
                      window.removeEventListener('mousemove', x.onResizeAction);
                    });
                  },
                };
              }}
            />
          </>
        );
      }

      return (
        <>
          <Table.Column
            title={x.columnTitle}
            dataIndex={x.columnProperty}
            key={x.columnTitle}
            onCell={(record: any) => {
              return {
                onClick: () => rowClickAction(record),
              };
            }}
            className={x.className}
            sorter={x.disableSort ? !x.disableSort : true}
            ellipsis
            showSorterTooltip={false}
            width={x.width ? `${x.width}rem` : '4.2rem'}
          />
        </>
      );
    });

    if (isAtRoot) {
      const renderIconAtRoot = (record: any) => {
        let optionsList = mockedRootOptions.filter((option) => {
          const isEvaluation =
            option.optionName === t('repository-list-evaluation-button');
          const hasEvaluationList = record.listArtifactReviewsDTOs.length > 0;
          const isAlreadyEvaluation = record.artifactReviewDTO ? true : false;

          if (!isEvaluation) {
            return;
          }

          const iconColor =
            (isUserAdmin && hasEvaluationList) ||
            (!isUserAdmin && !isAlreadyEvaluation)
              ? '#D46b08'
              : '#41b4d2';

          const IconComponent =
            (isUserAdmin && hasEvaluationList) ||
            (!isUserAdmin && !isAlreadyEvaluation)
              ? LikeFilled
              : LikeOutlined;

          option.optionIcon = <IconComponent style={{ color: iconColor }} />;
        });
        return (
          <>
            {optionsList.map((option, index) => (
              <span
                key={index}
                onClick={() => option.optionOnClick(record._id, option)}
              >
                {option.optionIcon}
              </span>
            ))}
          </>
        );
      };

      tableColumns.push(
        <Table.Column
          title=""
          key="enabled"
          align="left"
          showSorterTooltip={false}
          render={(record) => {
            return renderIconAtRoot(record);
          }}
          width="3.125rem"
        />,
      );
    }

    if (!isAtRoot || isSearching) {
      const renderIcon = (record: any) => {
        let optionsList = mockedChildrenOptions.filter((option) => {
          const isFile =
            record.genericType === 'FILE' &&
            [
              t('repository-list-information-button'),
              t('repository-list-copy-button'),
              t('repository-list-move-button'),
              t('repository-list-copy-link-button'),
              t('repository-list-download-button'),
              t('repository-list-delete-button'),
              t('repository-list-evaluation-button'),
            ].includes(option.optionName);

          const isPdfOrTxtFile =
            record.genericType === 'FILE' &&
            (record.name.endsWith('.pdf') || record.name.endsWith('.txt')) &&
            option.optionName === t('repository-list-set-main-file-button') &&
            !isSearching;

          const isFolder =
            record.genericType === 'ARTIFACT' &&
            [
              t('repository-list-information-button'),
              t('repository-list-copy-button'),
              t('repository-list-move-button'),
              t('repository-list-edit-button'),
              t('repository-list-delete-button'),
              t('repository-list-evaluation-button'),
            ].includes(option.optionName);

          const isMainFileButton =
            option.optionName === t('repository-list-set-main-file-button');
          const isDefaultFile = record.id === defaultFileId;

          if (isMainFileButton) {
            option.optionIcon = isDefaultFile ? (
              <StarFilled style={{ color: '#41B4D2' }} />
            ) : (
              <StarOutlined style={{ color: '#41B4D2' }} />
            );
          }

          const isEvaluation =
            option.optionName === t('repository-list-evaluation-button');
          const hasEvaluationList =
            record.genericType === 'ARTIFACT'
              ? record.listArtifactReviewsDTOs.length > 0
              : record.listFileReviewsDTOs.length > 0;

          const isAlreadyEvaluation =
            record.genericType === 'ARTIFACT'
              ? record.artifactReviewDTO
              : record.fileReviewDTO;

          if (isEvaluation) {
            const iconColor =
              (isUserAdmin && hasEvaluationList) ||
              (!isUserAdmin && !isAlreadyEvaluation)
                ? '#D46b08'
                : '#41b4d2';

            const IconComponent =
              (isUserAdmin && hasEvaluationList) ||
              (!isUserAdmin && !isAlreadyEvaluation)
                ? LikeFilled
                : LikeOutlined;

            option.optionIcon = <IconComponent style={{ color: iconColor }} />;
          }

          return isFile || isPdfOrTxtFile || isFolder;
        });

        return (
          <Popover
            trigger="click"
            placement="top"
            open={
              popoverState
                ? popoverState.itemId === record.id &&
                  popoverState.itemType === record.genericType
                : false
            }
            onOpenChange={(isOpen) => {
              if (!isOpen) {
                setPopoverState(undefined);
              }
            }}
            content={
              <Form
                name="moveUrlForm"
                layout="inline"
                autoComplete="off"
                onFinish={(values) => {
                  if (popoverState?.type === PopoverType.PopoverMove) {
                    onMoveItem(values.url, record.id, record.genericType);
                  } else if (popoverState?.type === PopoverType.PopoverCopy) {
                    onCopyItem(values.url, record.id, record.genericType);
                  }
                  setPopoverState(undefined);
                }}
              >
                <Form.Item noStyle>
                  <Input.Group compact>
                    <Form.Item name="url" rules={[{ required: true }]} noStyle>
                      <Input
                        style={{ width: '200px' }}
                        placeholder={t('repository-move-url-placeholder')}
                      />
                    </Form.Item>
                    <Form.Item noStyle>
                      <Button type="primary" htmlType="submit">
                        <RightCircleOutlined />
                      </Button>
                    </Form.Item>
                  </Input.Group>
                </Form.Item>
              </Form>
            }
          >
            <div className="repository-list-actions">
              {optionsList
                .filter((item) => {
                  if (
                    item.optionName === t('repository-list-copy-button') ||
                    item.optionName === t('repository-list-move-button') ||
                    item.optionName === t('repository-list-edit-button') ||
                    item.optionName === t('repository-list-delete-button')
                  ) {
                    return record.permissions.includes('WRITE');
                  }

                  if (
                    item.optionName ===
                    t('repository-list-set-main-file-button')
                  ) {
                    return isUserAdmin;
                  }

                  return true;
                })
                .map((item) => {
                  return (
                    <Tooltip
                      key={item.optionName}
                      placement="left"
                      title={item.optionName}
                    >
                      <span
                        onClick={() => item.optionOnClick(record.id, record)}
                      >
                        {item.optionIcon}
                      </span>
                    </Tooltip>
                  );
                })}
            </div>
          </Popover>
        );
      };

      tableColumns.push(
        <Table.Column
          title=""
          key="enabled"
          align="left"
          showSorterTooltip={false}
          render={(record: any) => {
            return renderIcon(record);
          }}
          width="3.1rem"
        />,
      );
    }

    if (!disableOptionsColumn && !isSearching) {
      tableColumns.push(
        <Table.Column
          title=""
          key="options"
          className="optionsColumn"
          ellipsis
          align="center"
          render={(x: any) => generateOptions(mockedRootOptions, x.id, x)}
          width={`${1.1 * mockedRootOptions.length}rem`}
        />,
      );
    }

    return tableColumns;
  };

  return (
    <Row className="RepositoryTable">
      <Col span={24}>
        <Table
          onChange={(_pagination, _filters, sorter: any) => {
            const sorterField = sorter.field ? sorter.field : sorter.columnKey;
            sortHandler(sorterField, sorter.order);
          }}
          locale={{ emptyText }}
          loading={tableLoading}
          rowSelection={isAtRoot ? undefined : rowSelectionProperties}
          dataSource={dataSource}
          pagination={false}
          rowKey={(record) => getIdFromRow(record)}
        >
          {generateColumns()}
        </Table>
      </Col>
    </Row>
  );
};
