import { Row, Col } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Filter } from '../../../../components/Filter/Filter';
import {
  IDefaultSortBehaviour,
  PagingTable,
} from '../../../../components/PagingTable/PagingTable';
import { ShowFiltersButton } from '../../../../components/ShowFiltersButton/ShowFiltersButton';
import { AppDispatch, RootState } from '../../../../store/store';
import { asyncActions, LogsListingSlice } from './LogsListing.Slice';

import './LogsListing.scss';
import { scrollToTopFunction } from '../../../../helpers/useScrollToTop/useScrollToTop';

export const LogsListing: React.FC = () => {
  const logsState = useSelector((state: RootState) => state.logs);

  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    return () => {
      dispatch(
        LogsListingSlice.actions.LOGS_BACK_TO_ORIGINAL_STATE_PERSISTING_FILTER(),
      );
    };
  }, [dispatch]);

  useEffect(() => {
    if (logsState.logsListing.errorState) {
      return;
    }

    if (!logsState.logsListing.shouldRefresh) {
      return;
    }

    if (logsState.logsListing.tableLoading) {
      return;
    }

    let queryString = `?page=${logsState.logsListing.pageIndex - 1}&size=${
      logsState.logsListing.pageSize
    }`;

    if (logsState.logsListing.filterBy) {
      queryString = `${queryString}&${logsState.logsListing.filterBy}`;
    }

    if (logsState.logsListing.sortBy) {
      queryString = `${queryString}&${logsState.logsListing.sortBy}`;
    }

    if (logsState.logsListing.logsFirstQueryBehavior) {
      queryString = `${queryString}&sort=createdAt,desc`;
    }

    dispatch(asyncActions.GET_LOGS_LIST(queryString));
  }, [dispatch, logsState]);

  const [columnsWidth, setColumnsWidth] = useState({
    typeColumnWidth: 3.5,
    dataTypeColumnWidth: 2.8,
    usernameColumnWidth: 4,
    companyColumnWidth: 4.2,
    detailColumnWidth: 9,
    createdAtColumnWidth: 2,
  });

  const resizeHandlerSpeed = 0.025;
  const resizeColumns = (
    columnName:
      | 'typeColumnWidth'
      | 'dataTypeColumnWidth'
      | 'usernameColumnWidth'
      | 'companyColumnWidth'
      | 'detailColumnWidth'
      | 'createdAtColumnWidth',
    movement: number,
  ) => {
    setColumnsWidth({
      ...columnsWidth,
      [columnName]: (columnsWidth[columnName] += movement * resizeHandlerSpeed),
    });
  };

  const logsListingCursor = [
    {
      columnTitle: t('administrative-panel-logs-listing-event-type'),
      columnProperty: 'type',
      width: columnsWidth.typeColumnWidth,
      onResizeAction: (event: MouseEvent) => {
        resizeColumns('typeColumnWidth', event.movementX);
      },
    },
    {
      columnTitle: t('administrative-panel-logs-listing-event-data-type'),
      columnProperty: 'dataType',
      width: columnsWidth.dataTypeColumnWidth,
      onResizeAction: (event: MouseEvent) => {
        resizeColumns('dataTypeColumnWidth', event.movementX);
      },
    },
    {
      columnTitle: t('administrative-panel-logs-listing-user'),
      columnProperty: ['user', 'fullName'],
      width: columnsWidth.usernameColumnWidth,
      onResizeAction: (event: MouseEvent) => {
        resizeColumns('usernameColumnWidth', event.movementX);
      },
    },
    {
      columnTitle: t('administrative-panel-logs-listing-company'),
      columnProperty: ['user', 'company'],
      width: columnsWidth.companyColumnWidth,
      onResizeAction: (event: MouseEvent) => {
        resizeColumns('companyColumnWidth', event.movementX);
      },
    },
    {
      columnTitle: t('administrative-panel-logs-listing-details'),
      columnProperty: 'detail',
      disableSort: true,
      width: columnsWidth.detailColumnWidth,
      onResizeAction: (event: MouseEvent) => {
        resizeColumns('detailColumnWidth', event.movementX);
      },
    },
    {
      columnTitle: t('administrative-panel-logs-listing-date'),
      columnProperty: 'createdAt',
      width: columnsWidth.createdAtColumnWidth,
      onResizeAction: (event: MouseEvent) => {
        resizeColumns('createdAtColumnWidth', event.movementX);
      },
    },
  ];

  const sortedSelectOptions = (options: any) => {
    options.sort((a: any, b: any) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });

    return options;
  };

  const selectTypeOptions = [
    {
      id: 'ACCOUNT_ACTIVATED',
      name: t('administrative-panel-logs-filter-account-activated'),
    },
    {
      id: 'ASSOCIATE_DOCUMENT',
      name: t('administrative-panel-logs-filter-associate-documentation'),
    },
    {
      id: 'AUTHENTICATED',
      name: t('administrative-panel-logs-filter-authenticated'),
    },
    {
      id: 'AUTOMATICALLY_APPROVED',
      name: t('administrative-panel-logs-filter-automatically-approved'),
    },
    {
      id: 'COPY',
      name: t('administrative-panel-logs-filter-copy'),
    },
    {
      id: 'CREATE',
      name: t('administrative-panel-logs-filter-create'),
    },
    {
      id: 'DELETE',
      name: t('administrative-panel-logs-filter-delete'),
    },
    {
      id: 'DISABLE',
      name: t('administrative-panel-logs-filter-disabled'),
    },
    {
      id: 'DISASSOCIATE_DOCUMENT',
      name: t('administrative-panel-logs-filter-disassociate-documentation'),
    },
    {
      id: 'DOWNLOAD',
      name: t('administrative-panel-logs-filter-download'),
    },
    {
      id: 'EMAIL_SENT',
      name: t('administrative-panel-logs-filter-email-sent'),
    },
    {
      id: 'ENABLE',
      name: t('administrative-panel-logs-filter-enabled'),
    },
    {
      id: 'MARK_FILE',
      name: t('administrative-panel-logs-filter-default-file'),
    },
    {
      id: 'MOVE',
      name: t('administrative-panel-logs-filter-move'),
    },
    {
      id: 'REVIEW_CREATED',
      name: t('administrative-panel-logs-filter-create-evaluation'),
    },
    {
      id: 'REVIEW_DELETED',
      name: t('administrative-panel-logs-filter-delete-evaluation'),
    },
    {
      id: 'REVIEW_EDITED',
      name: t('administrative-panel-logs-filter-update-evaluation'),
    },
    {
      id: 'UPDATE',
      name: t('administrative-panel-logs-filter-update'),
    },
    {
      id: 'UPLOAD',
      name: t('administrative-panel-logs-filter-upload'),
    },
  ];

  const logsFilterCursor = [
    {
      fieldTitle: t('administrative-panel-logs-listing-event-type'),
      fieldType: 'select',
      fieldProperty: 'type',
      fieldValue: logsState.logsListing.filter.type,
      selectOptions: sortedSelectOptions(selectTypeOptions),
    },
    {
      fieldTitle: t('administrative-panel-logs-listing-user'),
      fieldType: 'input',
      fieldProperty: 'userName',
      fieldValue: logsState.logsListing.filter.userName,
      placeholder: t('administrative-panel-logs-listing-user-placeholder'),
    },
    {
      fieldTitle: t('administrative-panel-logs-listing-company'),
      fieldType: 'input',
      fieldProperty: 'company',
      fieldValue: logsState.logsListing.filter.company,
    },
    {
      fieldTitle: t('administrative-panel-logs-listing-details'),
      fieldType: 'input',
      fieldProperty: 'detail',
      fieldValue: logsState.logsListing.filter.detail,
    },
    {
      fieldTitle: t('administrative-panel-logs-listing-date'),
      fieldType: 'datePicker',
      fieldProperty: 'createdAt',
      fieldValue: logsState.logsListing.filter.createdAt,
    },
  ];

  const submitFilter = (values: any) => {
    const queryParams = Object.entries(values).reduce(
      (acc, [key, value]: [any, any]) => {
        switch (key) {
          case 'type':
            dispatch(LogsListingSlice.actions.LOGS_FILTER_SET_TYPE(value));
            break;
          case 'userName':
            dispatch(LogsListingSlice.actions.LOGS_FILTER_SET_USERNAME(value));
            break;
          case 'company':
            dispatch(LogsListingSlice.actions.LOGS_FILTER_SET_COMPANY(value));
            break;
          case 'detail':
            dispatch(LogsListingSlice.actions.LOGS_FILTER_SET_DETAIL(value));
            break;
          case 'createdAt':
            dispatch(
              LogsListingSlice.actions.LOGS_FILTER_SET_CREATED_AT(value),
            );
            break;
          default:
            break;
        }

        if (value !== '' && value !== null && value !== undefined) {
          if (key === 'createdAt') {
            acc += `&${key}=${value.format('YYYY-MM-DD')}`;
            return acc;
          }

          acc += `&${key}=${String(value).trim()}`;
        }

        return acc;
      },
      '',
    );

    dispatch(LogsListingSlice.actions.FILTER_SET_QUERY_STRING(queryParams));
  };

  const onChangePage = (pageIndex: number) => {
    scrollToTopFunction('filter-button');
    dispatch(LogsListingSlice.actions.LOGS_CHANGE_PAGE(pageIndex));
  };

  const defaultSortBehaviour: IDefaultSortBehaviour = {
    columnProperty: 'createdAt',
    order: 'descend',
    disableDefaultSortBehaviour: () => {
      if (logsState.logsListing.logsFirstQueryBehavior) {
        dispatch(
          LogsListingSlice.actions.LOGS_DISABLE_DEFAULT_SORT_BEHAVIOUR(),
        );
      }
    },
  };

  const dispatchSortBy = (sortBy: string) => {
    dispatch(
      LogsListingSlice.actions.LOGS_CHANGE_SORT(sortBy ? `sort=${sortBy}` : ''),
    );
  };

  const onChangeSort = (
    field: string | string[],
    order: string | undefined | null,
  ) => {
    let sortBy = '';

    if (!order || !field) {
      return dispatchSortBy(sortBy);
    }

    if (defaultSortBehaviour) {
      defaultSortBehaviour.disableDefaultSortBehaviour();
    }

    const fieldToQueryParam = Array.isArray(field) ? field[0] : field;
    const userField = Array.isArray(field) ? field[1] : undefined;

    switch (fieldToQueryParam) {
      case 'type':
        sortBy =
          order === 'ascend'
            ? `${fieldToQueryParam},ignoreCase`
            : `${fieldToQueryParam},desc,ignoreCase`;
        return dispatchSortBy(sortBy);
      case 'dataType':
        sortBy =
          order === 'ascend'
            ? `data_type,ignoreCase`
            : `data_type,desc,ignoreCase`;
        return dispatchSortBy(sortBy);

      case 'user':
        if (userField === 'fullName') {
          sortBy =
            order === 'ascend'
              ? `userName,ignoreCase`
              : `userName,desc,ignoreCase`;
        } else {
          sortBy =
            order === 'ascend'
              ? `company,ignoreCase`
              : `company,desc,ignoreCase`;
        }

        return dispatchSortBy(sortBy);

      default:
        sortBy =
          order === 'ascend'
            ? `${fieldToQueryParam},ignoreCase`
            : `${fieldToQueryParam},desc,ignoreCase`;
        return dispatchSortBy(sortBy);
    }
  };

  return (
    <Row className="LogsListing">
      <Row className="buttons-filters" justify="end">
        <ShowFiltersButton
          onToggle={() => dispatch(LogsListingSlice.actions.TOGGLE_FILTER())}
          openedFilter={!logsState.showFilter}
        />
      </Row>
      <Row className="filter-collapse-area">
        <Col span={24}>
          <Filter
            clearFilterFields={() => {
              dispatch(LogsListingSlice.actions.FILTER_CLEAR_FIELDS());
            }}
            openedFilter={logsState.showFilter}
            fields={logsFilterCursor}
            submitFilterFields={submitFilter}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <PagingTable
            emptyText={t('administrative-panel-empty-table')}
            dataSource={logsState.logsListing.logsList}
            tableLoading={logsState.logsListing.tableLoading}
            columns={logsListingCursor}
            options={[]}
            pageSize={logsState.logsListing.pageSize}
            pageIndex={logsState.logsListing.pageIndex}
            numberOfItems={logsState.logsListing.totalNumberOfElements}
            onChangePage={onChangePage}
            onChangeSort={onChangeSort}
            rowClickAction={() => {}}
            selectRowsToGivenTab={() => {}}
            selectIdFromRowsSelected={() => {}}
            defaultSortBehaviour={defaultSortBehaviour}
            disableOptionsColumn
            disableSelectionColumn
            disableRowCursorPointer
          />
        </Col>
      </Row>
    </Row>
  );
};
