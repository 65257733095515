import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/store';
import {
  asyncActions,
  NotificationTrackerSlice,
} from './NotificationTracker.Slice';

export const NotificationTracker = () => {
  const dispatch = useDispatch<AppDispatch>();

  const user = useSelector((state: RootState) => state.login.user);

  const isNotificationListOpen = useSelector(
    (state: RootState) => state.notificationsList.isOpen,
  );

  const shouldUpdate = useSelector(
    (state: RootState) => state.notificationTracker.shouldUpdate,
  );

  const [pollingInterval, setPollingInterval] = useState<any>();

  const resetPollingInterval = useCallback(() => {
    if (!pollingInterval) {
      return;
    }

    clearInterval(pollingInterval);
    setPollingInterval(undefined);
  }, [pollingInterval, setPollingInterval]);

  useEffect(() => {
    if (!isNotificationListOpen) {
      return;
    }

    dispatch(asyncActions.GET_UNVIEWED_NOTIFICATIONS_COUNT());
  }, [isNotificationListOpen]);

  useEffect(() => {
    if (!shouldUpdate) {
      return;
    }

    dispatch(asyncActions.GET_UNVIEWED_NOTIFICATIONS_COUNT());
    dispatch(NotificationTrackerSlice.actions.SET_SHOULD_UPDATE(false));
  }, [shouldUpdate]);

  useEffect(() => {
    if (!user || user.role !== 'A') {
      resetPollingInterval();
      return;
    }

    if (pollingInterval) {
      return;
    }

    dispatch(asyncActions.GET_UNVIEWED_NOTIFICATIONS_COUNT());
    setPollingInterval(
      setInterval(() => {
        dispatch(asyncActions.GET_UNVIEWED_NOTIFICATIONS_COUNT());
      }, 15000),
    );
  }, [
    user,
    pollingInterval,
    setPollingInterval,
    resetPollingInterval,
    dispatch,
  ]);

  return <></>;
};
