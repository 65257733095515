import React, { useState, useEffect } from 'react';
import { Row, Col } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ReactComponent as IngenicoLogoDark } from '../../../assets/ingenico_dark_logo.svg';
import './Footer.scss';
import { AppDispatch, RootState, store } from '../../../store/store';
import { useNavigate } from 'react-router-dom';
import { showModal } from '../../../helpers/showModal/showModal';
import {
  checkChangesInCompanyRegisterOrEdit,
  checkChangesInUserRegisterOrEdit,
  checkChangesInNewsRegisterOrEdit,
} from '../../../helpers/newRegisterChangeChecker/newRegisterChangeChecker';
import { asyncActions as navbarAsyncActions } from '../Navbar/Navbar.Slice';
import { myProfileChangesChecker } from '../../../helpers/myProfileChangesChecker/myProfileChangesChecker';
import { PageMode } from '../../PagesList/CreateReadEditPages/CreateReadEditPage.Slice';

export const Footer = () => {
  const { loggedIn } = useSelector((state: RootState) => state.login);
  const pagesState = useSelector(
    (state: RootState) => state.createReadEditPageSlice.pages,
  );
  const pageResponseState = useSelector(
    (state: RootState) => state.createReadEditPageSlice.pageObjectResponse,
  );
  const pageEditorState = useSelector(
    (state: RootState) => state.createReadEditPageSlice.editorOutputOnEditing,
  );
  const createReadEditPageState = useSelector(
    (state: RootState) => state.createReadEditPageSlice,
  );
  const usersState = useSelector((state: RootState) => state.users);
  const companiesState = useSelector(
    (state: RootState) => state.CompanyAdministrativePanel.companies,
  );
  const dispatch = useDispatch<AppDispatch>();

  const [showPagesOptions, setShowPagesOptions] = useState<boolean>(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (loggedIn === true) {
      setShowPagesOptions(true);
    } else {
      setShowPagesOptions(false);
    }
  }, [loggedIn]);

  const getPageFormFieldsValuesOnCreate = () => {
    if (
      !window.location.href.includes('pages') ||
      createReadEditPageState.pageMode !== PageMode.Create
    ) {
      return false;
    }

    const hasPageContentInState =
      createReadEditPageState.pageSlug || pagesState.pages.some((page) => page.title || page.description || page.contents);

    if (hasPageContentInState) {
      return true;
    }

    const pageTitleElement = document.getElementById('page-form-title') as any;
    if (pageTitleElement && pageTitleElement.value) {
      return true;
    }

    const pageDescriptionElement = document.getElementById(
      'page-form-description',
    ) as any;
    if (pageDescriptionElement && pageDescriptionElement.value) {
      return true;
    }

    return false;
  };

  const getPageFormFieldsValuesOnEdit = () => {
    if (
      !window.location.href.includes('pages') ||
      createReadEditPageState.pageMode !== PageMode.Edit
    ) {
      return false;
    }

    const pageTitleElement = document.getElementById('page-form-title') as any;
    const pageDescriptionElement = document.getElementById(
      'page-form-description',
    ) as any;

    const allPages = pagesState.pages.map((page) => {
      if (page.language !== pagesState.languageDropdown) {
        return page;
      }

      const newPage = { ...page, title: pageTitleElement?.value, description: pageDescriptionElement?.value };
      if (pageEditorState) {
        newPage.contents = pageEditorState;
      }

      return newPage;
    });

    let showModalValidation = false;

    pageResponseState?.pageContents.map((pageResponse) => {
      const page = allPages.find(
        (element) => element.language === pageResponse.language,
      );
      if (!page) {
        return;
      }

      if (
        pageResponse.title !== page.title ||
        pageResponse.subtitle !== page.description ||
        pageResponse.mainContent !== page.contents
      ) {
        showModalValidation = true;
      }
    });

    allPages.map((page) => {
      const pageResponse = pageResponseState?.pageContents.find(
        (element) => element.language === page.language,
      );
      if (pageResponse) {
        return;
      }

      if (page.title || page.description || page.contents) {
        showModalValidation = true;
      }
    });

    return showModalValidation;
  };

  const getUserFormFieldsValueOnCreateOrEditMode = () => {
    if (
      !(
        window.location.href.includes('tab=users') &&
        window.location.href.includes('add_or_edit')
      )
    ) {
      return false;
    }

    return checkChangesInUserRegisterOrEdit(
      usersState.showEditMode ? 'edit' : 'create',
    );
  };

  const getCompanyFormFieldsValueOnCreateOrEditMode = () => {
    if (
      !(
        window.location.href.includes('tab=companies') &&
        window.location.href.includes('add_or_edit')
      )
    ) {
      return false;
    }

    return checkChangesInCompanyRegisterOrEdit(
      companiesState.showEditMode ? 'edit' : 'create',
    );
  };

  const getNewsFormFieldsValueOnCreateOrEditMode = () => {
    if (
      !(
        window.location.href.includes('tab=news') &&
        window.location.href.includes('add_or_edit')
      )
    ) {
      return false;
    }

    return checkChangesInNewsRegisterOrEdit(
      companiesState.showEditMode ? 'edit' : 'create',
    );
  };

  const getMyProfileChanges = () => {
    if (!window.location.href.includes('my-profile')) {
      return false;
    }
    return myProfileChangesChecker();
  };

  const getPopUpChanges = () => {
    return (
      window.location.href.includes('tab=pop-up') &&
      store.getState().popUp.hasChanged
    );
  };

  const showDocumentationDiscardChangesModal = (path: string) => {
    if (
      getPageFormFieldsValuesOnCreate() ||
      getPageFormFieldsValuesOnEdit() ||
      getUserFormFieldsValueOnCreateOrEditMode() ||
      getCompanyFormFieldsValueOnCreateOrEditMode() ||
      getNewsFormFieldsValueOnCreateOrEditMode() ||
      store.getState().permissionsByCompanies.listing.showCancelOrSaveChanges ||
      store.getState().permissionsByRepositories.listing
        .showCancelOrSaveChanges ||
      getMyProfileChanges() ||
      getPopUpChanges()
    ) {
      return showModal(
        'warning',
        t('documentation-confirmation-cancel-editing-title'),
        t('documentation-confirmation-cancel-editing-content'),
        () => navigate(`/${path}`),
      );
    } else return navigate(`/${path}`);
  };

  return (
    <Row className="Footer">
      <Col className="footer-item" offset={1} span={2}>
        <IngenicoLogoDark style={{ width: '100%' }} />
      </Col>
      <Col className="footer-item" span={1}>
        <div className="footer-divider" />
      </Col>
      <Col className="footer-item" span={3}>
        <ul>
          <li
            id="footer-buttons-pages"
            className="footer-buttons"
            onClick={() =>
              showDocumentationDiscardChangesModal('created-pages')
            }
          >
            {t('header-footer-documentations')}
          </li>
          <li
            id="footer-buttons-payment-terminals"
            className="footer-buttons"
            onClick={() => {
              const redirectPath = loggedIn ? 'repositories' : 'login?repositoryRedirect';
              showDocumentationDiscardChangesModal(redirectPath);
            }}
          >
            {t('header-footer-file-repository')}
          </li>
          <li
            id="footer-buttons-about"
            className="footer-buttons"
            onClick={() => showDocumentationDiscardChangesModal('about')}
          >
            {t('header-footer-about')}
          </li>
        </ul>
      </Col>
      <Col className="footer-info" offset={9} span={8}>
        <div>
          <p> BRGDSupport.Development@ingenico.com </p>
          <p>Copyright © 2020 Ingenico. All rights reserved.</p>
        </div>
      </Col>
    </Row>
  );
};
