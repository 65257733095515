import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import JSZip from 'jszip';
import { repositoryApi } from '../../services/repository.api';

export enum DownloadStep {
  IDLE,
  TRANSFERRING,
  COMPRESSING,
  DOWNLOADING,
  FINISHED,
  ERROR,
}

export enum FileState {
  IDLE,
  TRANSFERRING,
  DOWNLOADED,
  COMPRESSED,
  ERROR,
}

interface IFileState {
  file: any;
  state: FileState;
  transferProgress: number;
  compressionProgress: number;
  data: Blob | undefined;
}

export interface IRepositoryDownloadMultipleModalSlice {
  currentStep: DownloadStep;
  fileList: IFileState[];
  currentFileIndex: number;
  stepProgress: number;
  transferProgress: number;
  compressionProgress: number;
  totalProgress: number;
  zipBlob: Blob | undefined;
  isTransferring: boolean;
}

const initialState: IRepositoryDownloadMultipleModalSlice = {
  currentStep: DownloadStep.IDLE,
  fileList: [],
  currentFileIndex: 0,
  stepProgress: 0,
  transferProgress: 0,
  compressionProgress: 0,
  totalProgress: 0,
  zipBlob: undefined,
  isTransferring: false,
};

export const asyncActions = {
  TRANSFER_FILE_MODAL: createAsyncThunk(
    'TRANSFER_FILE_MODAL',
    async (payload: any, { dispatch }) => {
      const transferFileResponse = await repositoryApi.transferFile(
        payload.rootId,
        payload.id,
        (progressEvent) => {
          if (progressEvent.total === 0) {
            return;
          }

          const percentCompleted = Math.floor(
            (progressEvent.loaded * 100) / progressEvent.total,
          );
          dispatch(
            RepositoryDownloadMultipleModalSlice.actions.SET_FILE_TRANSFER_PROGRESS(
              { fileId: payload.id, transferProgress: percentCompleted },
            ),
          );
        },
        payload.abortSignal,
      );

      return {
        fileId: payload.id,
        data: transferFileResponse.data,
      };
    },
  ),
  ZIP_FILES: createAsyncThunk('ZIP_FILES', async (files: any, { dispatch }) => {
    const zip = new JSZip();

    files.forEach((file: any) => {
      zip.file(file.file.name, file.data);
    });

    const blob = await zip.generateAsync(
      { type: 'blob', streamFiles: true },
      (metadata) => {
        if (!metadata.currentFile) {
          return;
        }

        dispatch(
          RepositoryDownloadMultipleModalSlice.actions.SET_FILE_COMPRESSION_PROGRESS(
            {
              fileName: metadata.currentFile,
              compressionProgress: Math.floor(metadata.percent),
            },
          ),
        );
      },
    );

    return { blob: blob };
  }),
};

export const RepositoryDownloadMultipleModalSlice = createSlice({
  name: 'RepositoryDownloadMultipleModalSlice',
  initialState,
  reducers: {
    RESTORE_INITIAL_STATE: () => {
      return initialState;
    },
    SET_CURRENT_STEP: (state, action) => {
      state.currentFileIndex = 0;
      state.currentStep = action.payload;
    },
    SET_CURRENT_FILE_INDEX: (state, action) => {
      state.currentFileIndex = action.payload;
    },
    SET_TRANSFER_PROGRESS: (state, action) => {
      if (state.currentStep !== DownloadStep.TRANSFERRING) {
        return;
      }

      state.transferProgress = action.payload;
      state.stepProgress = action.payload;
    },
    SET_COMPRESSION_PROGRESS: (state, action) => {
      if (state.currentStep !== DownloadStep.COMPRESSING) {
        return;
      }

      state.compressionProgress = action.payload;
      state.stepProgress = action.payload;
    },
    SET_FILE_LIST: (state, action) => {
      state.fileList = action.payload;
    },
    SET_FILE_TRANSFER_PROGRESS: (state, action) => {
      state.fileList = state.fileList.map((file) => {
        if (
          file.file.id !== action.payload.fileId ||
          file.state === FileState.DOWNLOADED
        ) {
          return file;
        }

        return { ...file, transferProgress: action.payload.transferProgress };
      });
    },
    SET_FILE_COMPRESSION_PROGRESS: (state, action) => {
      state.fileList = state.fileList.map((file) => {
        if (file.file.name !== action.payload.fileName) {
          if (file.compressionProgress > 0) {
            return {
              ...file,
              state: FileState.COMPRESSED,
              compressionProgress: 100,
            };
          }

          return file;
        }

        state.currentFileIndex = state.fileList.findIndex(
          (element) => element.file.id === file.file.id,
        );

        return {
          ...file,
          compressionProgress: action.payload.compressionProgress,
        };
      });
    },
    SET_TOTAL_PROGRESS: (state) => {
      state.totalProgress = Math.floor(
        (state.transferProgress + state.compressionProgress) / 2,
      );
    },
    DOWNLOAD_ZIP: (state, action) => {
      if (!state.zipBlob || state.currentStep !== DownloadStep.DOWNLOADING) {
        return;
      }

      const url = window.URL.createObjectURL(state.zipBlob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${action.payload}.zip`);
      document.body.appendChild(link);
      link.click();

      state.currentStep = DownloadStep.FINISHED;
    },
  },
  extraReducers: (builder) => [
    builder.addCase(asyncActions.ZIP_FILES.fulfilled, (state, action) => {
      state.compressionProgress = 100;
      state.zipBlob = action.payload.blob;
      state.currentStep = DownloadStep.DOWNLOADING;
    }),
    builder.addCase(asyncActions.TRANSFER_FILE_MODAL.pending, (state) => {
      state.isTransferring = true;
    }),
    builder.addCase(asyncActions.TRANSFER_FILE_MODAL.rejected, (state) => {
      state.isTransferring = false;
    }),
    builder.addCase(
      asyncActions.TRANSFER_FILE_MODAL.fulfilled,
      (state, action) => {
        state.fileList = state.fileList.map((file) => {
          if (file.file.id !== action.payload.fileId) {
            return file;
          }

          return {
            ...file,
            transferProgress: 100,
            state: FileState.DOWNLOADED,
            data: action.payload.data,
          };
        });
        state.currentFileIndex = state.currentFileIndex + 1;
        state.isTransferring = false;
      },
    ),
  ],
});
